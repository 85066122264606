import React, { useRef, useState } from 'react';
import { GoogleMap as Map, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';

function GoogleMap({ style, onLoad, onUnmount, marker, multiple, markers, type, ...rest }) {
  const ref = useRef(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const [activeMarker, setActiveMarker] = useState(null);

  const handleMarkerClick = markerId => {
    setActiveMarker(markerId);
  };

  const handleInfoWindowClose = () => {
    setActiveMarker(null);
  };

  return isLoaded ? (
    <Map {...rest} ref={ref} mapContainerStyle={style} zoom={15} onLoad={onLoad} onUnmount={onUnmount}>
      {multiple && markers?.length > 0 ? (
        type === 'see_all_stores' ? (
          markers.map(({ _id, title, position, totalTerminals }) => (
            <Marker key={_id} position={position} onClick={() => handleMarkerClick(_id)}>
              {activeMarker === _id && (
                <InfoWindow onCloseClick={handleInfoWindowClose}>
                  <div>
                    <div>Title: {title}</div>
                    <div>Total Devices: {totalTerminals}</div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))
        ) : type === 'see_all_devices' || type === 'see_devices_by_stores' ? (
          markers.map(({ _id, title, serial_number, device_id, store_title, position }) => (
            <Marker key={_id} position={position} onClick={() => handleMarkerClick(_id)}>
              {activeMarker === _id && (
                <InfoWindow onCloseClick={handleInfoWindowClose}>
                  <div>
                    <div>Device Title: {title}</div>
                    <div>Serial Number: {serial_number}</div>
                    <div>Device ID: {device_id}</div>
                    <div>Store Title: {store_title}</div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))
        ) : null
      ) : (
        <Marker position={marker} />
      )}
    </Map>
  ) : (
    'Loading Map ...'
  );
}

export default GoogleMap;
