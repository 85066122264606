import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { getDateObject, mergeNames } from 'helpers/common';
import Table from 'components/molecules/Table';
import BusinessActions from 'components/organisms/BusinessActions';

export default function Business({ businesses_data }) {
  const { business_rows } = useMemo(
    () => ({
      business_rows: businesses_data?.map(_ => [
        format(getDateObject(_.created_at), 'yyyy-MM-dd'),
        _.title ?? '------------',
        `${mergeNames(_?.owner)}, ${_?.owner?.email}`,
        _.address?.formatted_address ?? '------------',
        _.status,
        <BusinessActions key={_._id} _={_} deleted={_?.is_deleted} />,
        _?.is_deleted ? 'deleted' : 'false',
      ]),
    }),
    [businesses_data],
  );

  const columnNames = [`Created at`, `Title`, `Owner`, `Address`, `Status`, ``, `del`];

  return <Table loading={false} rowsData={business_rows} columnNames={columnNames} noPadding />;
}
