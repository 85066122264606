import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from 'context/authContext';
import { StyledToastContainer } from 'styles/App.styles';
import GlobalStyles from 'styles/GlobalStyles.styles';
import { LoadingContextProvider } from 'context/loadingContext';
import { SideNavContextProvider } from 'context/sideNavContext';
import { FiltersContextProvider } from 'context/filtersContext';
import { MQTTContextProvider } from 'context/mqttContext';
import Router from './Router';
import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <LoadingContextProvider>
      <SideNavContextProvider>
        <FiltersContextProvider>
          <GlobalStyles />
          <BrowserRouter>
            <AuthContextProvider>
              <MQTTContextProvider>
                <Router />
              </MQTTContextProvider>
            </AuthContextProvider>
          </BrowserRouter>
          <StyledToastContainer />
        </FiltersContextProvider>
      </SideNavContextProvider>
    </LoadingContextProvider>
  );
}

export default App;
