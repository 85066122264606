/* eslint-disable react/style-prop-object */
/* eslint-disable no-unused-vars */
import styled from 'styled-components/macro';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import GridCol from 'components/atoms/GridCol';
import Field from 'components/molecules/Field';
import Loaders from 'components/atoms/Loaders';

const SideBySideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  gap: 20px; // This adds space between your two views
`;
const MobileView = styled.div`
  flex: 1; // This allows the div to grow and take up half the space
  max-width: 50%; // Limits the width to half of the container
  background-color: #d6cccc; // Just an example color
  padding: 20px;
`;
const FrontendView = styled.div`
  flex: 1; // This allows the div to grow and take up half the space
  max-width: 50%; // Limits the width to half of the container
  background-color: #e8e8e8; // Just an example color, slightly different for contrast
  padding: 20px;
`;

function Responses({ message }) {
  return (
    <Loaders loading={!message}>
      <div>
        <h3>Response</h3>
        <div>
          <pre>{JSON.stringify(message ?? {}, null, 2)}</pre>
        </div>
      </div>
    </Loaders>
  );
}
function Requests({ connRef, setMessage }) {
  const serials = [
    { label: 'Motola Dev', value: '0821595192' },
    { label: 'Furqan Asad', value: '0821595097' },
    { label: 'Hussnain', value: '0822775758' },
    { label: 'My Device', value: '0821595098' },
    { label: 'Dummy 2', value: '0821595089' },
    { label: 'Dummy Device', value: '0821595099' },
    { label: 'Usman', value: '0821595096' },
    { label: 'PSP Dev Navjot', value: '0821595180' },
  ];
  const [state, setState] = useState({
    '0821595192': true,
    '0821595097': false,
    '0822775758': false,
    '0821595098': false,
    '0821595089': false,
    '0821595099': false,
    '0821595096': false,
    '0821595180': false,
  });

  const generateRequest = useMemo(() => {
    const request = {
      transactions: [
        {
          order_id: Math.random().toString(36).substring(7),
          amount: Math.floor(Math.random() * 100).toFixed(2),
          currency: 'CAD',
          timestamp: new Date().getTime(),
        },
      ],
      serial_number: Object.keys(state).find(key => state[key]),
    };
    return request;
  }, [state]);

  const makeRequest = useCallback(request => {
    const message = new window.Paho.MQTT.Message(JSON.stringify(request));
    message.destinationName = request.serial_number;
    message.qos = 2;
    message.retained = false;
    connRef.current.send(message);
    // eslint-disable-next-line no-param-reassign
    setMessage(null);
  }, []);
  return (
    <div>
      <h3>Serial Numbers</h3>
      <Grid
        xs={12}
        sm={12}
        lg={12}
        colGap={30}
        rowGap={20}
        css={`
          padding: 15px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 500px;
        `}>
        {serials.map((serial, index) => (
          <GridCol xs={4} md={4} lg={4} key={index}>
            <Field
              sm
              name={serial.value}
              type="radio"
              label={`${serial.value} - (${serial.label})`}
              value={state[serial.value]}
              onChange={() => {
                const newState = { ...state };
                Object.keys(newState).forEach(key => {
                  newState[key] = false;
                });
                newState[serial.value] = true;
                setState(newState);
              }}
            />
          </GridCol>
        ))}
      </Grid>

      <div>Request: </div>
      <pre>{JSON.stringify(generateRequest, null, 2)}</pre>
      <Button
        type="primary"
        onClick={() => {
          makeRequest(generateRequest);
        }}>
        Send Request
      </Button>
    </div>
  );
}

export default function App() {
  const [message, setMessage] = useState({}); // This will store all messages received from the server
  const connRef = useRef(null);
  const loadingRef = useRef(false);
  const onMessage = useCallback(_ => {
    // eslint-disable-next-line no-param-reassign
    _ = JSON.parse(_.payloadString);
    setMessage(_);
  }, []);
  const connection = () => {
    console.log('trying to connect');
    connRef.current.connect({
      userName: 'rabbitmq-dev.pspservicesco.com',
      password: 'ct0TwTQg6P21pYqU2x3xQzLnWwSFlPPJBpMBWGBUVtclwoTu',

      onSuccess: () => {
        console.log('Connected to MQTT Broker');
        connRef.current.subscribe('transactionQueue', {
          qos: 2,
          onSuccess: () => {
            console.log('Subscribed to transactionQueue');
          },
          onFailure: error => {
            console.log(`Could not subscribe to transactionQueue: ${error.errorMessage}`);
          },
        });
      },
      onFailure: error => {
        console.log(`Could not connect to MQTT Broker: ${error.errorMessage}`);
      },
    });
  };
  useEffect(() => {
    if (typeof window === 'undefined' || connRef.current) return;
    connRef.current = new window.Paho.MQTT.Client(
      'wss://rabbitmq-dev.pspservicesco.com:15676/ws',
      new Date().getTime().toString(),
    );
    connRef.current.onMessageArrived = onMessage;
    connRef.current.onConnectionLost = response => {
      console.log(`Connection lost: ${response.errorMessage}`);
      connection();
    };
    connection();
  }, []);

  return (
    <SideBySideContainer>
      <FrontendView>
        <Requests connRef={connRef} setMessage={setMessage} />
      </FrontendView>
      <MobileView>
        <Responses message={message} />
      </MobileView>
    </SideBySideContainer>
  );
}
