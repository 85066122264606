import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Button from 'components/atoms/Button';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import userService from 'services/userService';
import Grid from 'components/atoms/Grid';
import { useContextHook } from 'use-context-hook';
import Select from 'components/atoms/Select';
import userGroupService from 'services/userGroupService';

function UserCreationForm({ onClose, edit, user, storeId, type }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const onSubmit = async values => {
    try {
      setLoading(true);
      let response;

      if (edit) {
        const data = { ...values, group_id: values.group_id.map(grp => grp.value) };

        response = await userService.updateUser(user._id, data);
      } else {
        const payload = {
          ...values,
          store_id: storeId,
          type,
        };

        if (values?.group_id?.length > 0) {
          payload.group_id = values?.group_id?.map(grp => grp.value);
        }

        response = await userService.createUser(payload);
      }

      if (response.success) {
        onClose();
        refetch();
        Toast({
          type: 'success',
          message: response.message,
        });
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  const handleUserGroupSearch = async () => {
    try {
      let options = null;

      const response = await userGroupService.getUsersGroups({
        page: 1,
        pageSize: 100,
        searchText: '',
        startDate: '',
        endDate: '',
        store_id: storeId,
      });

      options = response?.groups?.map(_ => ({
        value: _?._id,
        label: `Name: ${_.name}`,
      }));

      return options;
    } catch {
      return [];
    }
  };

  useEffect(() => {
    if (user) {
      const _ = user.group_id.map(__ => ({ value: __?._id, label: `Name: ${__.name}` }));

      const { ...rest } = user;

      form.setFieldsValue({
        ...rest,
        group_id: _,
      });
    }
  }, [user]);

  return (
    <Form
      form={form}
      css={`
        margin-top: 20px;
      `}
      onSubmit={onSubmit}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label="First Name"
          name="first_name"
          placeholder="First Name"
          rules={[
            { required: true, message: 'Please enter first name' },
            {
              pattern: /^[a-zA-Z\s]+$/,
              message: 'First name should be Alphabets',
            },
            {
              pattern: /^[a-zA-Z\s]{3,}$/,
              message: 'Owner first name must be minimum of 3 characters.',
            },
            {
              pattern: /^[a-zA-Z\s]{3,40}$/,
              message: 'Owner first name should be a maximum of 40 characters.',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label="Last Name"
          name="last_name"
          placeholder="Last Name"
          rules={[
            { required: true, message: 'Please enter last name' },
            {
              pattern: /^[a-zA-Z\s]+$/,
              message: 'Last name should be Alphabets',
            },
            {
              pattern: /^[a-zA-Z\s]{3,}$/,
              message: 'Owner last name must be minimum of 3 characters. ',
            },
            {
              pattern: /^[a-zA-Z\s]{3,40}$/,
              message: 'Owner last name should be a maximum of 40 characters.',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          disabled={edit}
          sm
          type="email"
          label="Email"
          name="email"
          placeholder="Email"
          rules={[
            { required: true, message: 'Please enter email address' },
            { email: true, message: 'Please enter a valid email' },
            { max: 40, message: 'Email should be at max 40 characters!' },
          ]}>
          <Field />
        </Form.Item>
        {type !== 'owner' && type !== 'manager' && (
          <Form.Item name="group_id" label="Select Groups">
            <Select
              sm
              open
              async
              isMulti
              closeMenuOnSelect={false}
              defaultOptions
              filterOption={false}
              loadOptions={handleUserGroupSearch}
            />
          </Form.Item>
        )}
      </Grid>

      <Button loading={loading} type="primary" htmlType="submit">
        {edit ? 'Update' : 'Save'}
      </Button>
    </Form>
  );
}

export default UserCreationForm;
