import React, { useEffect, useState } from 'react';
import Toast from 'components/molecules/Toast';
import storeService from 'services/storeService';
import Switch from 'components/atoms/Switch';
import Button from 'components/atoms/Button';
import {
  BinRangesWrapper,
  BinRangesBody,
  BinRangesFooter,
  BinRangesTable,
  BinRangesTableHeader,
  BinRangesTableHeaderRow,
  BinRangesTableHeaderCell,
  BinRangesTableBody,
  BinRangesTableBodyRow,
  BinRangesTableBodyCell,
} from './binranges.styles';

export default function BinRanges({ onClose = () => {}, binRanges }) {
  const [state, setState] = useState(binRanges.pins);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    try {
      let minActiveCount = 0;
      state.forEach(st => {
        if (st.active) minActiveCount += 1;
      });

      if (minActiveCount < 1) {
        Toast({
          type: 'error',
          message: 'Atleast one card should be active',
        });
      } else {
        setLoading(true);
        await storeService.updateBinRanges({ binRanges: state, storeId: binRanges.store_id });
        Toast({
          type: 'success',
          message: 'Bin Ranges updated successfully',
        });
        onClose();
        setLoading(false);
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    setState(binRanges.pins);
  }, [binRanges.store_id]);
  return (
    <BinRangesWrapper>
      <BinRangesBody>
        <BinRangesTable>
          <BinRangesTableHeader>
            <BinRangesTableHeaderRow>
              <BinRangesTableHeaderCell>Ranges</BinRangesTableHeaderCell>
              <BinRangesTableHeaderCell>Card Type</BinRangesTableHeaderCell>
              <BinRangesTableHeaderCell>Active</BinRangesTableHeaderCell>
              <BinRangesTableHeaderCell />
            </BinRangesTableHeaderRow>
          </BinRangesTableHeader>
          <BinRangesTableBody>
            {state?.map(({ card, ranges, active }, i) => (
              <BinRangesTableBodyRow key={i}>
                <BinRangesTableBodyCell>{ranges?.join(' , ')}</BinRangesTableBodyCell>
                <BinRangesTableBodyCell>{card}</BinRangesTableBodyCell>
                <BinRangesTableBodyCell>
                  <Switch
                    name={`active-${i}`}
                    $noStyles
                    noMargin
                    value={active}
                    onChange={() => {
                      setState(_ =>
                        _.map((__, index) => {
                          if (index === i) {
                            return {
                              ...__,
                              active: !__.active,
                            };
                          }
                          return __;
                        }),
                      );
                    }}
                  />
                </BinRangesTableBodyCell>
              </BinRangesTableBodyRow>
            ))}
          </BinRangesTableBody>
        </BinRangesTable>
      </BinRangesBody>
      <BinRangesFooter>
        <Button
          loading={loading}
          type="primary"
          onClick={handleSave}
          css={`
            margin-top: 5px;
          `}>
          Save
        </Button>
      </BinRangesFooter>
    </BinRangesWrapper>
  );
}
