import Button from 'components/atoms/Button';
import Select from 'components/atoms/Select';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import React, { useEffect } from 'react';
import merchantToolService from 'services/merchantToolService';
import 'styled-components/macro';
import Loaders from 'components/atoms/Loaders';
import { Table, TableHead, TableRow, TableHeader, TableCell, TBody } from './addBusinessFees.styles';

export default function AddBusinessFees({ onClose, business }) {
  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();
  const { fees_data = [], fees_loading = false } = merchantToolService.GetBusinessFees(business.mid);
  const handleSubmit = async values => {
    setLoading(true);
    try {
      await merchantToolService.uploadFees({
        fees: Object.keys(values)
          .map(key => ({
            Mid: business.mid,
            FeeType: key.toUpperCase().includes('_TYPE') ? null : key.toUpperCase(),
            PerTran: values[key.toUpperCase()],
            Type: values[`${key.toUpperCase()}_TYPE`]?.value,
          }))
          .filter(_ => _.FeeType),
      });
      Toast({
        type: 'success',
        message: 'Fees updated successfully',
      });
      onClose();
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!fees_loading) {
      console.log(
        [
          'Cyclic Maintenance Fee (with limit)',
          'E-Commerce Gateway',
          '24-Hour Terminal Replacement',
          '48-Hour Terminal Replacement',
          'Capped Rates',
          'PCI DSS Management',
          'PCI Phone-In Service',
          'Interac (%)',
          'Interac ($)',
          'Visa (%)',
          'Visa ($)',
          'MasterCard (%)',
          'MasterCard ($)',
          'Interac Tap (%)',
          'Interac Tap ($)',
          'Visa Tap (%)',
          'Visa Tap ($)',
          'MasterCard Tap (%)',
          'MasterCard Tap ($)',
          'Paper Program',
          'Statement Fee',
          'Wireless Fee Plan 1 (1MB) Fee',
          'Wireless Fee Plan 2 (3MB) Fee',
          'Wireless Fee Plan 3 (5MB) Fee',
          'Wireless Fee Plan 4 (10MB) Fee',
          'Wireless Fee Plan 5 (50 MB) Fee',
          'Monthly Fee per MID',
          'Application Fee',
          'Account Set-Up Fee',
          'Early Termination Fee',
          'Per Item Fees',
          'Retrieval Request Fee',
          'Voice Authorization Fee',
          'Host Level Change Fee',
          'Communication Fee',
          'Batch Close Fee',
          'Overage Data/per 1MB Fee',
          'Modem Fee',
          'PSP Monthly Minimum Fee',
          'AMEX (%)',
          'AMEX ($)',
          'MDR VISA',
          'MDR MASTERCARD',
          'MDR AMEX',
          'Mrc Province GST Fee',
          'Mrc Province HST Fee',
          'Mrc Province QST Fee',
          'Mrc Province PST Fee',
          'Statement Language',
          'Contactless Operation Charge Fee',
        ].reduce(
          (acc, feeName) => ({
            ...acc,
            [feeName.toUpperCase()]: fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())?.PerTran
              ? fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())?.PerTran
              : 0,
            [`${feeName.toUpperCase()}_TYPE`]: fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())
              ?.Type
              ? {
                  value: fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())?.Type,
                  label: fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())?.Type,
                }
              : {
                  value: 'FIXED',
                  label: 'FIXED',
                },
          }),
          {},
        ),
      );
      form.setFieldsValue(
        [
          'Cyclic Maintenance Fee (with limit)',
          'E-Commerce Gateway',
          '24-Hour Terminal Replacement',
          '48-Hour Terminal Replacement',
          'Capped Rates',
          'PCI DSS Management',
          'PCI Phone-In Service',
          'Interac (%)',
          'Interac ($)',
          'Visa (%)',
          'Visa ($)',
          'MasterCard (%)',
          'MasterCard ($)',
          'Interac Tap (%)',
          'Interac Tap ($)',
          'Visa Tap (%)',
          'Visa Tap ($)',
          'MasterCard Tap (%)',
          'MasterCard Tap ($)',
          'Paper Program',
          'Statement Fee',
          'Wireless Fee Plan 1 (1MB) Fee',
          'Wireless Fee Plan 2 (3MB) Fee',
          'Wireless Fee Plan 3 (5MB) Fee',
          'Wireless Fee Plan 4 (10MB) Fee',
          'Wireless Fee Plan 5 (50 MB) Fee',
          'Monthly Fee per MID',
          'Application Fee',
          'Account Set-Up Fee',
          'Early Termination Fee',
          'Per Item Fees',
          'Retrieval Request Fee',
          'Voice Authorization Fee',
          'Host Level Change Fee',
          'Communication Fee',
          'Batch Close Fee',
          'Overage Data/per 1MB Fee',
          'Modem Fee',
          'PSP Monthly Minimum Fee',
          'AMEX (%)',
          'AMEX ($)',
          'MDR VISA',
          'MDR MASTERCARD',
          'MDR AMEX',
          'Mrc Province GST Fee',
          'Mrc Province HST Fee',
          'Mrc Province QST Fee',
          'Mrc Province PST Fee',
          'Statement Language',
          'Contactless Operation Charge Fee',
        ].reduce(
          (acc, feeName) => ({
            ...acc,
            [feeName.toUpperCase()]: fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())?.PerTran
              ? fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())?.PerTran
              : 0,
            [`${feeName.toUpperCase()}_TYPE`]: fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())
              ?.Type
              ? {
                  value: fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())?.Type,
                  label: fees_data?.find(_ => _.FeeType.toUpperCase() === feeName.toUpperCase())?.Type,
                }
              : {
                  value: 'FIXED',
                  label: 'FIXED',
                },
          }),
          {},
        ),
      );
    }
  }, [fees_loading]);

  return (
    <>
      <Loaders loading={fees_loading} />
      <Form form={form} onSubmit={handleSubmit}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>Fee Name</TableHeader>
              <TableHeader>Amount</TableHeader>
              <TableHeader>Fee Type</TableHeader>
            </TableRow>
          </TableHead>
          <TBody>
            {[
              'Cyclic Maintenance Fee (with limit)',
              'E-Commerce Gateway',
              '24-Hour Terminal Replacement',
              '48-Hour Terminal Replacement',
              'Capped Rates',
              'PCI DSS Management',
              'PCI Phone-In Service',
              'Interac (%)',
              'Interac ($)',
              'Visa (%)',
              'Visa ($)',
              'MasterCard (%)',
              'MasterCard ($)',
              'Interac Tap (%)',
              'Interac Tap ($)',
              'Visa Tap (%)',
              'Visa Tap ($)',
              'MasterCard Tap (%)',
              'MasterCard Tap ($)',
              'Paper Program',
              'Statement Fee',
              'Wireless Fee Plan 1 (1MB) Fee',
              'Wireless Fee Plan 2 (3MB) Fee',
              'Wireless Fee Plan 3 (5MB) Fee',
              'Wireless Fee Plan 4 (10MB) Fee',
              'Wireless Fee Plan 5 (50 MB) Fee',
              'Monthly Fee per MID',
              'Application Fee',
              'Account Set-Up Fee',
              'Early Termination Fee',
              'Per Item Fees',
              'Retrieval Request Fee',
              'Voice Authorization Fee',
              'Host Level Change Fee',
              'Communication Fee',
              'Batch Close Fee',
              'Overage Data/per 1MB Fee',
              'Modem Fee',
              'PSP Monthly Minimum Fee',
              'AMEX (%)',
              'AMEX ($)',
              'MDR VISA',
              'MDR MASTERCARD',
              'MDR AMEX',
              'Mrc Province GST Fee',
              'Mrc Province HST Fee',
              'Mrc Province QST Fee',
              'Mrc Province PST Fee',
              'Statement Language',
              'Contactless Operation Charge Fee',
            ].map((feeName, index) => (
              <TableRow key={index}>
                <TableCell>{feeName.toUpperCase()}</TableCell>
                <TableCell>
                  <Form.Item $noStyle type="number" name={feeName.toUpperCase()}>
                    <Field $noStyle id="numberField" placeholder={`Enter ${feeName.toUpperCase()}`} />
                  </Form.Item>
                </TableCell>
                <TableCell>
                  <Form.Item
                    $noStyle
                    type="select"
                    name={`${feeName.toUpperCase()}_TYPE`}
                    options={[
                      { value: 'FIXED', label: 'FIXED' },
                      { value: 'PERCENTAGE', label: 'PERCENTAGE' },
                    ]}
                    rules={[{ required: true, message: 'Fee type is required' }]}>
                    <Select $noStyle sm />
                  </Form.Item>
                </TableCell>
              </TableRow>
            ))}
          </TBody>
        </Table>

        <div style={{ marginTop: '20px' }}>
          <Button sm width="211px" loader={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}
