import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { getDateObject } from 'helpers/common';
import Table from 'components/molecules/Table';
import DevicesActions from '../DevicesActions';

function Device({ devices_data }) {
  const { device_rows } = useMemo(
    () => ({
      device_rows: devices_data?.map(_ => [
        format(getDateObject(_.created_at), 'yyyy-MM-dd'),
        _.title ?? '------------',
        _.status,
        _?.store_id?.title,
        _?.store_id?.status,
        _.device_id ?? '------------',
        _?.mac_address ?? '------------',
        _?.serial_number ?? '------------',
        <DevicesActions
          _={{ ..._, store_id: _?.store_id?._id ?? _?.store_id }}
          room={`admin_${_?.store_id?._id ?? _?.store_id}`}
          key={_._id}
        />,
        _?.is_deleted ? 'deleted' : 'false',
      ]),
    }),
    [devices_data],
  );

  const columnNames = [
    `Created at`,
    `Title`,
    `Status`,
    `Store Title`,
    `Store Status`,
    `Device id`,
    `Mac Address`,
    `Serial Number`,
    ``,
    `del`,
  ];

  return <Table loading={false} rowsData={device_rows} columnNames={columnNames} noPadding />;
}

export default Device;
