import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { getDateObject } from 'helpers/common';
import Table from 'components/molecules/Table';
import StoreActions from '../StoreActions';

function StoresTable({ stores_data }) {
  const { store_rows } = useMemo(
    () => ({
      store_rows: stores_data?.map(_ => [
        format(getDateObject(_.created_at), 'yyyy-MM-dd'),
        _.title ?? '------------',
        _.owner ?? '------------',
        _.address?.formatted_address ?? '------------',
        _.status,
        <StoreActions _={_} deleted={_?.is_deleted} key={_._id} />,
        _?.is_deleted ? 'deleted' : 'false',
      ]),
    }),
    [stores_data],
  );

  const columnNames = [`Created at`, `Title`, `Owner`, `Address`, `Status`, ``, `del`];

  return <Table loading={false} rowsData={store_rows} columnNames={columnNames} noPadding />;
}

export default StoresTable;
