import Grid from 'components/atoms/Grid';
import DetailsCard from 'components/molecules/DetailsCard';
import InfoCard from 'components/molecules/InfoCard';
import { format } from 'date-fns';
import React from 'react';

export default function DeviceDetails({ device = {} }) {
  const {
    created_at,
    updated_at,
    device_id,
    tid,
    tagString,
    activation_code: { otp: activation_code },
  } = device;
  return (
    <DetailsCard gray>
      <Grid xs={1} sm={3} gap={20}>
        <InfoCard
          title="Created At"
          value={created_at && format(new Date(created_at), 'MMM do yyyy hh:mm a')}
          $unStyled
        />
        <InfoCard
          title="Updated At"
          value={updated_at && format(new Date(updated_at), 'MMM do yyyy hh:mm a')}
          $unStyled
        />

        {activation_code && <InfoCard title="Activation Code" value={activation_code} $unStyled />}
        <InfoCard title="Device Id" value={device_id} $unStyled />
        <InfoCard title="TID" value={tid} $unStyled />
        <InfoCard title="Tag String" value={tagString} $unStyled />
      </Grid>
    </DetailsCard>
  );
}
