/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef } from 'react';

import { createContextHook } from 'use-context-hook';

export const MqttContext = createContextHook();

export function MQTTContextProvider(props) {
  const connRef = useRef(null);

  const onMessage = useCallback(_ => {
    const msg = JSON.parse(_.payloadString);
    console.log({ msg, event: _.destinationName.split('/').pop() });
    if (msg && msg.connectedTerminals) {
      window.dispatchEvent(new CustomEvent('terminal_update', { detail: msg }));
    } else {
      const event = _.destinationName.split('/').pop();
      window.dispatchEvent(new CustomEvent(event, { detail: msg }));
    }
  }, []);

  const LeaveRoom = async () => {
    connRef.current.unsubscribe('connected_terminals', {
      onSuccess: () => {
        // console.log('Unsubscribed from connected_terminals');
      },
      onFailure: error => {
        console.log(`Could not unsubscribe from connected_terminals: ${error.errorMessage}`);
      },
    });
    connRef.current.unsubscribe('server_update', {
      onSuccess: () => {
        // console.log('Unsubscribed from server_update');
      },
      onFailure: error => {
        console.log(`Could not unsubscribe from server_update: ${error.errorMessage}`);
      },
    });
    connRef.current.unsubscribe('clerk_update', {
      onSuccess: () => {
        // console.log('Unsubscribed from clerk_update');
      },
      onFailure: error => {
        console.log(`Could not unsubscribe from clerk_update: ${error.errorMessage}`);
      },
    });
    connRef.current.unsubscribe('manager_update', {
      onSuccess: () => {
        // console.log('Unsubscribed from manager_update');
      },
      onFailure: error => {
        console.log(`Could not unsubscribe from manager_update: ${error.errorMessage}`);
      },
    });
    connRef.current.unsubscribe('owner_update', {
      onSuccess: () => {
        // console.log('Unsubscribed from owner_update');
      },
      onFailure: error => {
        console.log(`Could not unsubscribe from owner_update: ${error.errorMessage}`);
      },
    });
    connRef.current.unsubscribe('merchant_tools_bull_progress', {
      onSuccess: () => {
        // console.log('Unsubscribed from merchant_tools_bull_progress');
      },
      onFailure: error => {
        console.log(`Could not unsubscribe from merchant_tools_bull_progress: ${error.errorMessage}`);
      },
    });
  };

  const JoinRoom = async () => {
    connRef.current.subscribe('connected_terminals', {
      qos: 2,
      onSuccess: () => {
        // console.log('Subscribed to connected_terminals');
      },
      onFailure: error => {
        console.log(`Could not subscribe to connected_terminals: ${error.errorMessage}`);
      },
    });
    connRef.current.subscribe('server_update', {
      qos: 2,
      onSuccess: () => {
        // console.log('Subscribed to server_update');
      },
      onFailure: error => {
        console.log(`Could not subscribe to server_update: ${error.errorMessage}`);
      },
    });
    connRef.current.subscribe('clerk_update', {
      qos: 2,
      onSuccess: () => {
        // console.log('Subscribed to clerk_update');
      },
      onFailure: error => {
        console.log(`Could not subscribe to clerk_update: ${error.errorMessage}`);
      },
    });
    connRef.current.subscribe('manager_update', {
      qos: 2,
      onSuccess: () => {
        // console.log('Subscribed to manager_update');
      },
      onFailure: error => {
        console.log(`Could not subscribe to manager_update: ${error.errorMessage}`);
      },
    });
    connRef.current.subscribe('owner_update', {
      qos: 2,
      onSuccess: () => {
        // console.log('Subscribed to owner_update');
      },
      onFailure: error => {
        console.log(`Could not subscribe to owner_update: ${error.errorMessage}`);
      },
    });
    connRef.current.subscribe('merchant_tools_bull_progress', {
      qos: 2,
      onSuccess: () => {
        // console.log('Subscribed to merchant_tools_bull_progress');
      },
      onFailure: error => {
        console.log(`Could not subscribe to merchant_tools_bull_progress: ${error.errorMessage}`);
      },
    });
  };

  const connection = () => {
    connRef.current.connect({
      userName: process.env.REACT_APP_MQTT_USERNAME,
      password: process.env.REACT_APP_MQTT_PASSWORD,
      onSuccess: () => {
        console.log('Connected to MQTT Broker');
        JoinRoom();
      },
      onFailure: error => {
        console.log(`Could not connect to MQTT Broker: ${error.errorMessage}`);
      },
    });
  };

  useEffect(() => {
    if (typeof window === 'undefined' || connRef.current) return;
    connRef.current = new window.Paho.MQTT.Client(process.env.REACT_APP_MQTT_URL, new Date().getTime().toString());
    connRef.current.onMessageArrived = onMessage;
    connRef.current.onConnectionLost = response => {
      console.log(`Connection lost: ${response.errorMessage}`);
      connection();
    };
    connection();
  }, []);

  const _con = React.useMemo(
    () => ({
      JoinRoom,
      LeaveRoom,
    }),
    [JoinRoom, LeaveRoom],
  );
  return <MqttContext.Provider value={_con}>{props.children}</MqttContext.Provider>;
}
