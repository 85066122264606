import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const deviceGroupsService = {
  _url: `${process.env.REACT_APP_DEVICE_API_URL}`,

  GetDeviceGroups(searchQuery, refetch) {
    const [deviceGroups, setDeviceGroups] = useState({
      deviceGroups: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getDeviceGroups(searchQuery))
        .then(res => {
          setDeviceGroups(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getDeleted,
      searchQuery?.storeId,
      refetch,
    ]);
    return {
      device_groups_loading: status === STATUS.LOADING,
      device_groups_error: status === STATUS.ERROR ? status : '',
      device_groups_data: deviceGroups,
    };
  },

  GetDeviceGroupDetails(id) {
    const [groupDetails, setgroupDetails] = useState(null);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.deviceGroupDetail(id))
        .then(res => {
          setgroupDetails(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, []);
    return {
      group_details_loading: status === STATUS.LOADING,
      group_details_error: status === STATUS.ERROR ? status : '',
      group_details_data: groupDetails?.data,
    };
  },

  async getDeviceGroups({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    storeId = '',
    getDeleted = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-device-groups?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&storeId=${storeId}&getDeleted=${getDeleted}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        groups: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createDevicesGroup(payload) {
    let res = await Fetch.post(`${this._url}/create-device-group`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updateDeviceGroup(id, payload) {
    let res = await Fetch.put(`${this._url}/update-device-group/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteDeviceGroup(id) {
    let res = await Fetch.delete(`${this._url}/delete-device-group/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deviceGroupDetail(id) {
    let res = await Fetch.get(`${this._url}/get-group-details/${id}`);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default deviceGroupsService;
