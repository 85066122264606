import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Grid from 'components/atoms/Grid';
import Button from 'components/atoms/Button';
import Toast from 'components/molecules/Toast';
import Select from 'components/atoms/Select';
import { capitalizeFirstLetter } from 'helpers/common';
import storeService from 'services/storeService';
import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';

import Switch from 'components/atoms/Switch';
import deviceService from 'services/deviceService';

const reportType = [
  {
    label: 'Totals',
    value: 'total',
  },
  {
    label: 'Detailed',
    value: 'detailed',
  },
  {
    label: 'Summary',
    value: 'summary',
  },
];
const receiveType = [
  {
    label: 'Print',
    value: 'print',
  },
  {
    label: 'Email',
    value: 'email',
  },
];

function BatchReport({ onClose, detail }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [isMerged, setIsMerged] = useState(false);

  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const onSubmit = async values => {
    try {
      setLoading(true);
      const data = {
        isMerged,
        isSeparate: !isMerged,
        selectedTerminal: '',
        report_type: values.report_type.value,
        report_receive_type: values.report_receive_type.value,
      };

      if (isMerged) {
        data.isMerged = isMerged;
        data.isSeparate = !isMerged;
        data.selectedTerminal = values?.merge_terminal?.value;
      }

      await storeService.updateBatchReport(detail._id, data);

      onClose();
      refetch();
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (detail.report_receive_type) {
      form.setFieldsValue({
        report_receive_type: {
          label: capitalizeFirstLetter(detail.report_receive_type),
          value: detail.report_receive_type,
        },
        report_type: { label: capitalizeFirstLetter(detail.report_type), value: detail.report_type },
      });
    }
    if (detail.is_report_merged && detail?.reportDeviceDetail?.serial_number) {
      setIsMerged(true);
      form.setFieldsValue({
        merge_terminal: {
          label: (
            <span>
              {detail?.reportDeviceDetail?.title.toUpperCase()} -- {detail.reportDeviceDetail?.serial_number}
            </span>
          ),
          value: `${detail.reportDeviceDetail?._id},${detail.reportDeviceDetail?.serial_number}`,
        },
      });
    }
  }, []);

  const reportPlaceHandler = () => setIsMerged(!isMerged);
  const handleTerminalSearch = async text => {
    try {
      let options = null;

      const response = await deviceService.getDevices({
        page: 1,
        pageSize: 1000,
        searchText: text,
        getDeleted: false,
        storeId: detail._id,
        filterStatus: 'Active',
      });
      options = response?.device?.map(_ => ({
        value: `${_?._id},${_?.serial_number}`,
        label: (
          <span>
            {_?.title?.toUpperCase()} -- {_?.serial_number}
          </span>
        ),
      }));

      return options;
    } catch {
      return [];
    }
  };

  return (
    <Form
      form={form}
      css={`
        margin-top: 30px;
      `}
      onSubmit={onSubmit}>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          sm
          type="text"
          label="Receive Type"
          name="report_receive_type"
          placeholder="Receive Type"
          rules={[{ required: true, message: 'Receive Type is required' }]}>
          <Select sm open closeMenuOnSelect options={receiveType} />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label="Report Type"
          name="report_type"
          placeholder="Report Type"
          rules={[{ required: true, message: 'Report Type is required' }]}>
          <Select sm open closeMenuOnSelect options={reportType} />
        </Form.Item>
        <Form.Item label="Merged Report" name="merge_report" value={isMerged} onChange={reportPlaceHandler}>
          <Switch />
        </Form.Item>
        {isMerged && (
          <Form.Item
            sm
            type="text"
            label="Select Terminal for Report Printing"
            name="merge_terminal"
            placeholder="Select Terminal for Report Printing"
            rules={[{ required: true, message: 'Terminal is required' }]}>
            <Select
              sm
              open
              async
              closeMenuOnSelect
              defaultOptions
              filterOption={false}
              loadOptions={handleTerminalSearch}
            />
          </Form.Item>
        )}
      </Grid>

      <Button loading={loading} type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  );
}

export default BatchReport;
