import React from 'react';
import 'styled-components/macro';
import storeService from 'services/storeService';
import { AuthContext } from 'context/authContext';
import Loaders from 'components/atoms/Loaders';
import DetailsCard from 'components/molecules/DetailsCard';
import Grid from 'components/atoms/Grid';
import InfoCard from 'components/molecules/InfoCard';
import { format } from 'date-fns';
import { useContextHook } from 'use-context-hook';

function StoreDetails({ storeId }) {
  const { fetch } = useContextHook(AuthContext, ['fetch']);

  const { store_details_loading, store_details_data } = storeService.GetStoreDetails(storeId, fetch);

  return (
    <Loaders loading={store_details_loading}>
      <DetailsCard gray>
        <Grid xs={1} sm={3} gap={20}>
          <InfoCard
            title="Created At"
            value={store_details_data && format(new Date(store_details_data?.created_at), 'MMM do yyyy hh:mm a')}
            $unStyled
          />
          <InfoCard
            title="Updated At"
            value={store_details_data && format(new Date(store_details_data?.updated_at), 'MMM do yyyy hh:mm a')}
            $unStyled
          />
          <InfoCard title="Owner Name" value={store_details_data?.owner?.split(',')[0]} $unStyled />
          <InfoCard title="Owner Email" value={store_details_data?.owner?.split(',')[1]} $unStyled />
          <InfoCard title="Title" value={store_details_data?.title} $unStyled />
          <InfoCard title="Merchant Passcode" value={store_details_data?.passcode} $unStyled />
          <InfoCard title="Status" value={store_details_data?.status} $unStyled />
          <InfoCard title="Report Print Time" value={store_details_data?.report_print_time} $unStyled />
          <InfoCard title="Postal Code" value={store_details_data?.address?.postal_code} $unStyled />
          <InfoCard title="Street Address" value={store_details_data?.address?.formatted_address} $unStyled />
          <InfoCard title="Latitude" value={store_details_data?.address?.latlng?.lat} $unStyled />
          <InfoCard title="Longitude" value={store_details_data?.address?.latlng?.lng} $unStyled />
          {store_details_data?.is_deleted && (
            <InfoCard title="Deactivation Reason" value={store_details_data?.deactivation_reason} $unStyled />
          )}
        </Grid>
      </DetailsCard>
    </Loaders>
  );
}

export default StoreDetails;
