/* eslint-disable no-plusplus */
import Resizer from 'react-image-file-resizer';

export const objectify = obj => {
  const result = {};
  Object.keys(obj).forEach(key => {
    const keys = key.split('.');
    keys.reduce((acc, cur, index) => {
      if (index === keys.length - 1) {
        acc[cur] = obj[key];
      } else if (!acc[cur]) {
        acc[cur] = {};
      }
      return acc[cur];
    }, result);
  });
  return result;
};
export const deobjectify = obj => {
  const result = {};
  // eslint-disable-next-line no-shadow
  const recurse = (obj, current) => {
    Object.keys(obj).forEach(key => {
      const newKey = current ? `${current}.${key}` : key;
      if (typeof obj[key] === 'object') {
        recurse(obj[key], newKey);
      } else {
        result[newKey] = obj[key];
      }
    });
  };
  recurse(obj);
  return result;
};
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;

  return true;
};

export const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const clearCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  return true;
};

export const capitalize = (str = '') => {
  const arr = str.toLowerCase().split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
};

export const getStatusIconClass = (status = '') => {
  switch (status.trim().toLowerCase()) {
    case 'active':
      return 'check_circle';
    case 'blocked':
      return 'lock';
    case 'pendingactivation':
      return 'settings';
    default:
      return 'cancel';
  }
};

function changeTimezone(date, ianatz) {
  // suppose the date is 12:00 UTC
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    }),
  );

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract
}

export const getDateObject = e => changeTimezone(new Date(e), 'Canada/Eastern');

export const GeoCoder = window.google ? new window.google.maps.Geocoder() : null;

export const GeoCode = async value => {
  const { results } = await GeoCoder.geocode(value);
  const { address_components, geometry, place_id, formatted_address } = results[0];
  const address = {};
  // eslint-disable-next-line no-shadow
  address_components?.forEach(({ short_name, types }) => {
    if (types.includes('administrative_area_level_1')) {
      address.state = short_name;
    } else if (types.includes('administrative_area_level_2')) {
      address.county = short_name;
    } else if (types.includes('locality')) {
      address.city = short_name;
    } else address[types[0]] = short_name;
  });

  return {
    ...address,
    place_id,
    latlng: {
      lat: geometry?.location?.lat(),
      lng: geometry?.location?.lng(),
    },
    formatted_address,
  };
};

export const generatePsscode = length => {
  let zero = '';
  for (let index = 1; index < length; index++) {
    zero += '0';
  }
  const firstVal = 1 + zero;
  const secondVal = 9 + zero;
  return Math.floor(Number(firstVal) + Math.random() * Number(secondVal));
};

export const mergeNames = _ => {
  let name = null;
  if (_.first_name) {
    name = `${_?.first_name} ${_?.last_name}`;
  }
  return name;
};

export const removeDuplicatedArrayElements = array =>
  Array.from(new Set(array.map(_ => JSON.stringify(_)))).map(str => JSON.parse(str));

export function compareJSON(originalJSON, updatedJSON) {
  const updatedLines = {};
  const oldLines = {};

  const compareObjects = (originalObj, updatedObj, path = '') => {
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in originalObj) {
      const originalValue = originalObj[key];

      // eslint-disable-next-line no-prototype-builtins
      if (updatedObj.hasOwnProperty(key)) {
        const updatedValue = updatedObj[key];
        const nestedPath = path ? `${path}.${key}` : key;

        if (typeof originalValue === 'object' && typeof updatedValue === 'object') {
          compareObjects(originalValue, updatedValue, nestedPath);
        } else if (originalValue !== updatedValue) {
          updatedLines[nestedPath] = updatedValue;
          oldLines[nestedPath] = originalValue;
        }
      } else {
        const nestedPath = path ? `${path}.${key}` : key;
        oldLines[nestedPath] = originalValue;
      }
    }
  };

  compareObjects(originalJSON, updatedJSON);

  return { updatedLines, oldLines };
}

export const compressImage = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      Infinity,
      Infinity,
      'PNG',
      70,
      0,
      uri => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          // Set the canvas dimensions to match the image plus border
          const canvasWidth = image.width + 800; // Adjust border width as desired
          const canvasHeight = image.height;
          canvas.width = canvasWidth;
          canvas.height = canvasHeight;

          // Draw the left border
          context.fillStyle = 'white'; // Set border color
          context.fillRect(0, 0, 400, canvasHeight);

          // Draw the right border
          context.fillStyle = 'white'; // Set border color
          context.fillRect(canvasWidth - 400, 0, 400, canvasHeight);

          // Draw the image in the center of the canvas
          const imageX = 400; // Adjust border width as desired
          const imageY = 0;
          context.drawImage(image, imageX, imageY);

          // Convert the modified image on the canvas to base64 (PNG format)
          const modifiedImageData = canvas.toDataURL('image/png');
          resolve(modifiedImageData);
        };

        image.src = uri;
      },
      'base64',
      600,
      600,
    );
  });

export const capitalizeFirstLetter = sentence => {
  const words = sentence.split(' ');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const capitalizedSentence = capitalizedWords.join(' ');
  return capitalizedSentence;
};

export const getDateStringFromFileName = fileName => {
  const regex = /PSP_Daily_settlement_(\d{6})\.csv/;
  const match = fileName.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return null;
};
