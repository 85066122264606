import Button from 'components/atoms/Button';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import React from 'react';
import { MapContainer, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet';
import commonService from 'services/commonService';
import { useContextHook } from 'use-context-hook';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import deviceService from 'services/deviceService';
import Loaders from 'components/atoms/Loaders';
import icon from '../../../assets/images/marker-icon.png';

const customIcon = L.icon({
  iconUrl: icon,
  iconSize: [32, 38],
});

function DeviceLocationMap({ device, onClose }) {
  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const { device_location_loading, device_location } = deviceService.GetDeviceLocation(device._id);

  const onGetLocation = async () => {
    try {
      const response = await commonService.getDeviceLocation(device._id);

      if (response.success) {
        Toast({
          type: 'success',
          message: response.message || 'Try again after sometime',
        });
        refetch();
        onClose();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message || 'we are unable to get device location at this time please try after sometime',
      });
      onClose();
    }
  };

  return (
    <Loaders loading={device_location_loading}>
      {device_location.coordinates.length > 0 ? (
        <MapContainer
          style={{ height: 300, width: '100%', marginTop: 10 }}
          center={device_location.coordinates}
          zoom={13}
          scrollWheelZoom
          zoomControl={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl position="bottomright" />
          <Marker position={device_location.coordinates} icon={customIcon}>
            <Popup>
              <>
                <div> S/N:{device.serial_number}</div>
                <div> TID:{device.tid}</div>
              </>
            </Popup>
          </Marker>
        </MapContainer>
      ) : (
        !device_location_loading && (
          <>
            <p style={{ marginBottom: 20 }}>Location not available. Please provide device location.</p>
            <Button type="primary" onClick={onGetLocation} shape="" size={100}>
              Get Location
            </Button>
          </>
        )
      )}
    </Loaders>
  );
}

export default DeviceLocationMap;
