import React, { useState, useRef, useMemo } from 'react';
import 'styled-components/macro';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import debounce from 'lodash/debounce';
import DetailsCard from 'components/molecules/DetailsCard';
import GridCol from 'components/atoms/GridCol';
import commonService from 'services/commonService';
import Loaders from 'components/atoms/Loaders';
import BusinessTable from './BusinessTable';
import StoresTable from './StoresTable';
import UsersTable from './UsersTable';
import DevicesTable from './DevicesTable';

function AllInOneSearch({ searchText }) {
  const [state, setState] = useState(searchText);
  const [searchQuery, setSearchQuery] = useState(searchText);
  const debounceRef = useRef(0);

  const onSearchCallText = useMemo(
    () =>
      debounce(value => {
        debounceRef.current += 1;
        const LocalRef = debounceRef.current;
        setTimeout(() => {
          if (LocalRef === debounceRef.current) {
            setSearchQuery(value);
          }
        }, 1);
      }, 300),
    [state],
  );

  const { all_In_one_loading, all_In_one_data } = commonService.AllInOneSearch(searchQuery);

  return (
    <>
      <Grid lg={24} xl={25} gap={14}>
        <GridCol lg={12} xl={5}>
          <Field
            type="search"
            placeholder="Search"
            noMargin
            value={state}
            onChange={({ target: { value } }) => {
              setState(value);
              onSearchCallText(value.trim());
            }}
            label="Search"
            sm
            prefix={<i className="material-icons-outlined">search</i>}
            clear={state !== ''}
            maxLength="150"
          />
        </GridCol>
      </Grid>

      <Loaders loading={all_In_one_loading}>
        <DetailsCard title="Business" css="margin:20px 0px">
          <BusinessTable businesses_data={all_In_one_data.filteredBusiness} />
        </DetailsCard>

        <DetailsCard title="Stores" css="margin:20px 0px">
          <StoresTable stores_data={all_In_one_data.filteredStores} />
        </DetailsCard>

        <DetailsCard title="Users" css="margin:20px 0px">
          <UsersTable users_data={all_In_one_data.filteredUsers} />
        </DetailsCard>

        <DetailsCard title="Devices" css="margin:20px 0px">
          <DevicesTable devices_data={all_In_one_data.filteredTerminals} />
        </DetailsCard>
      </Loaders>
    </>
  );
}

export default AllInOneSearch;
