import React, { useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import commonService from 'services/commonService';
import { CertLabel, InputWrap, TextArea, InputStyle } from './cert.styles';

function CertificateForm({ onClose = () => {} }) {
  const [loading, setLoading] = useState(false);
  const [ca, setCa] = useState('');
  const [cert, setCert] = useState('');
  const [key, setKey] = useState('');
  const [pass, setPass] = useState('');
  const [ip, setIp] = useState('');
  const [port, setPort] = useState('');

  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const onSubmit = async e => {
    e.preventDefault();
    if (!ca && !cert && !key && !pass && !ip && !port) {
      Toast({
        type: 'error',
        message: 'All Fields are required',
      });
    } else {
      try {
        setLoading(true);

        const data = {
          ca,
          cert,
          key,
          passphrase: pass,
          ip,
          port,
        };

        await commonService.createCertificates(data);

        refetch();
        onClose();
        setLoading(false);
        Toast({
          type: 'success',
          message: 'Certificate Injected successfully!',
        });
      } catch (ex) {
        setLoading(false);
        Toast({
          type: 'error',
          message: ex.message,
        });
      }
    }
  };

  return (
    <form css="padding-top:15px">
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: start;
        `}>
        <InputWrap>
          <CertLabel>
            <span className="required">*</span> CA
          </CertLabel>
          <TextArea value={ca} onChange={e => setCa(e.target.value)} placeholder="Paste CA file content here" />
        </InputWrap>
        <InputWrap>
          <CertLabel>
            <span className="required">*</span> CERT
          </CertLabel>
          <TextArea value={cert} onChange={e => setCert(e.target.value)} placeholder="Paste CERT file content here" />
        </InputWrap>
        <InputWrap>
          <CertLabel>
            <span className="required">*</span> KEY
          </CertLabel>
          <TextArea value={key} onChange={e => setKey(e.target.value)} placeholder="Paste KEY file content here" />
        </InputWrap>
        <InputWrap>
          <CertLabel>
            <span className="required">*</span> PASS
          </CertLabel>
          <InputStyle value={pass} onChange={e => setPass(e.target.value)} placeholder="Paste Passphrase here" />
        </InputWrap>
        <InputWrap>
          <CertLabel>
            <span className="required">*</span> IP Host
          </CertLabel>
          <InputStyle value={ip} onChange={e => setIp(e.target.value)} placeholder="Paste ip host here (127.0.0.1)" />
        </InputWrap>
        <InputWrap>
          <CertLabel>
            <span className="required">*</span> PORT
          </CertLabel>
          <InputStyle
            value={port}
            onChange={e => setPort(e.target.value)}
            type="number"
            placeholder="Paste port here (123445)"
          />
        </InputWrap>
      </Grid>

      <Button loading={loading} type="primary" htmlType="submit" onClick={onSubmit}>
        Save
      </Button>
    </form>
  );
}

export default CertificateForm;
