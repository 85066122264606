import React from 'react';
import Switch from 'components/atoms/Switch';
import { PermissionListHead } from '../PermissionSelector/PermissionSelector.styles';
import { CheckboxHolder } from './configuration.styles';

function VisibleLock({ title = '', state = {}, onChange = () => {}, section = '', lock = false, visible = false }) {
  return (
    <PermissionListHead>
      {title}
      <CheckboxHolder>
        {visible && <Switch noMargin label="Visible" name={`${section}._v`} value={state?._v} onChange={onChange} />}
        {lock && <Switch noMargin label="Lock" name={`${section}._l`} value={state?._l} onChange={onChange} />}
      </CheckboxHolder>
    </PermissionListHead>
  );
}

export default VisibleLock;
