import styled, { css } from 'styled-components/macro';

export const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const TooltipBtn = styled.span``;

export const StyledTooltip = styled.div`
  position: absolute;
  border-radius: 4px;
  ${({ place }) =>
    place === 'top' &&
    css`
      top: ${({ height }) => height && `calc(-${height}px - var(--tooltip-margin))`};
    `}

  ${({ place }) =>
    place === 'bottom' &&
    css`
      bottom: ${({ height }) => height && `calc(-${height}px - var(--tooltip-margin))`};
    `}

  ${({ place }) =>
    place === 'left'
      ? css`
          top: 0;
          transform: translateX(-110%);
        `
      : css`
          left: 50%;
          transform: translateX(-50%);
        `}

  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  opacity: ${({ active }) => (active ? 1 : 0)};
  z-index: ${({ active }) => (active ? 100 : -1)};
  width: ${({ width }) => (width ? `${width}px` : 'auto')} !important;
  text-align: center !important;
  font-size: var(--font-size-xs) !important;
  line-height: calc(var(--font-size-xs) + 0.3125rem) !important;
  padding: 8px 10px !important;

  /* CSS border triangles */
  &::before {
    content: '';
    position: absolute;
    ${({ place }) =>
      place === 'top' &&
      css`
        top: 100%;
      `}

    ${({ place }) =>
      place === 'bottom' &&
      css`
        bottom: 100%;
      `}
   
    left: ${({ place }) => (place === 'left' ? '104%' : '50%')};
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: var(--tooltip-arrow-size) var(--tooltip-arrow-size)
      ${({ place }) => (place === 'top' ? 0 : 'var(--tooltip-arrow-size)')} var(--tooltip-arrow-size);

    ${({ place }) =>
      place === 'top' &&
      css`
        border-color: var(--tooltip-background-color) transparent transparent transparent;
      `}

    ${({ place }) =>
      place === 'bottom' &&
      css`
        border-color: transparent transparent var(--tooltip-background-color) transparent;
      `}

    ${({ place }) =>
      place === 'left' &&
      css`
        border-color: transparent transparent transparent var(--tooltip-background-color);
      `}
  }
`;
