import MenuButton, { MenuItem } from 'components/molecules/Menu/index';
import React, { useState } from 'react';
import { AuthContext } from 'context/authContext';
import ChangeStatusForm from 'components/organisms/ChangeStatusForm';
import ModalContainer from 'components/molecules/ModalContainer';
import Toast from 'components/molecules/Toast';
import UserCreationForm from 'components/organisms/UserCreationForm';
import userService from 'services/userService';
import Loaders from 'components/atoms/Loaders';
import { useContextHook } from 'use-context-hook';
import TransferUserForm from '../TransferUserForm';
import CaptchaComponent from '../CaptchaComponent';

function ManagerActions({ _ }) {
  const [loading, setLoading] = useState(false);
  const { refetch, hasPermission } = useContextHook(AuthContext, ['refetch', 'hasPermission']);

  const onDeleteManager = async id => {
    try {
      setLoading(true);
      const { success, message } = await userService.deleteUser(id);

      if (success) {
        Toast({
          type: 'success',
          message,
        });
        refetch();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  return (
    <MenuButton icon={<span className="material-icons-outlined">more_vert</span>}>
      {hasPermission('manager.update-manager') && _.status === 'Active' && (
        <ModalContainer
          lg
          title="Update Manager"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<span className="material-icons-outlined">edit</span>}>
              Update Manager
            </MenuItem>
          )}
          content={({ onClose }) => <UserCreationForm onClose={onClose} user={_} edit storeId={_.store_id} />}
        />
      )}

      {hasPermission('manager.change-manager-status') && (
        <ModalContainer
          lg
          title="Change Manager Status"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">update</i>}>
              Change Status
            </MenuItem>
          )}
          content={({ onClose }) => <ChangeStatusForm onClose={onClose} id={_._id} manager status={_.status} />}
        />
      )}

      {hasPermission('manager.transfer-manager') && _.status === 'Active' && (
        <ModalContainer
          lg
          title="Transfer Manager"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">reply</i>}>
              Transfer Manager
            </MenuItem>
          )}
          content={({ onClose }) => (
            <TransferUserForm onClose={onClose} id={_._id} storeId={_.store_id} businessId={_.business_id} />
          )}
        />
      )}

      {hasPermission('manager.delete-manager') && (
        <ModalContainer
          lg
          title={`Are you sure you want to deactivate ${_?.type}?`}
          btnComponent={({ onClick }) => (
            <MenuItem
              className="delete-btn"
              icon={<span className="material-icons-outlined">delete_forever</span>}
              onClick={onClick}>
              Deactivate Manager
            </MenuItem>
          )}
          content={({ onClose }) => (
            <Loaders loading={loading}>
              <CaptchaComponent
                onClose={onClose}
                onDelete={() => onDeleteManager(_._id)}
                type={_?.type}
                email={_?.email}
              />
            </Loaders>
          )}
        />
      )}
    </MenuButton>
  );
}

export default ManagerActions;
