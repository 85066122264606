import GridCol from 'components/atoms/GridCol';
import React from 'react';
import Field from 'components/molecules/Field';
import Grid from 'components/atoms/Grid';
import commonService from 'services/commonService';
import Upload from '../Upload';
import 'styled-components/macro';
import { GroupFieldHeading, InputWrap } from './configuration.styles';
import { PermissionListHead } from '../PermissionSelector/PermissionSelector.styles';

function HeaderLines({ state, section, onChange = () => {} }) {
  const uploadImageToCloud = async file => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      const response = await commonService.uploadImage(formData);
      return response;
    } catch (error) {
      // console.log(error);
      return false;
    }
  };
  const deleteImageFromCloud = async img => {
    try {
      const response = await commonService.deleteImage({ fileName: img });
      return response;
    } catch (error) {
      // console.log(error);
      return false;
    }
  };

  return (
    <>
      <PermissionListHead>Receipt Header lines</PermissionListHead>
      <Grid
        xs={12}
        sm={12}
        lg={12}
        colGap={30}
        rowGap={20}
        css={`
          padding: 15px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 500px;
        `}>
        <GridCol xs={12} md={6} lg={6}>
          <Upload
            allowPreview
            cloud
            maxFileSize={3} // size in MB
            onChange={onChange}
            label="Header Line 1 (logo)"
            uploadBtnText="Upload Logo"
            name={`${section}.line_1`}
            uploadImageToCloud={uploadImageToCloud}
            deleteImageFromCloud={deleteImageFromCloud}
            // value={
            //   state?.line_1.includes('http') || state?.line_1 === ''
            //     ? state?.line_1
            //     : `${process.env.REACT_APP_COMMON_API_URL}${state?.line_1}`
            // }
            value={state?.line_1}
          />
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Field
            margin={2}
            name={`${section}.line_2`}
            type="text"
            label="Header line 2 (Store Name)"
            value={state?.line_2}
            onChange={onChange}
          />
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Field
            margin={2}
            name={`${section}.line_3`}
            type="text"
            label="Header Line 3 (store address 1)"
            value={state?.line_3}
            onChange={onChange}
          />
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Field
            margin={2}
            name={`${section}.line_4`}
            type="text"
            label="Header line 4 (store address 2)"
            value={state?.line_4}
            onChange={onChange}
          />
        </GridCol>

        <GridCol xs={12} md={6} lg={6}>
          <InputWrap>
            <GroupFieldHeading>Header line 5</GroupFieldHeading>
            <Field
              margin={2}
              name={`${section}.line_5.en`}
              type="text"
              label="English"
              value={state?.line_5.en}
              onChange={onChange}
            />
            <Field
              name={`${section}.line_5.fr`}
              type="text"
              label="French"
              value={state?.line_5.fr}
              onChange={onChange}
            />
          </InputWrap>
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <InputWrap>
            <GroupFieldHeading>Header line 6</GroupFieldHeading>
            <Field
              margin={2}
              name={`${section}.line_6.en`}
              type="text"
              label="English"
              value={state?.line_6.en}
              onChange={onChange}
            />
            <Field
              name={`${section}.line_6.fr`}
              type="text"
              label="French"
              value={state?.line_6.fr}
              onChange={onChange}
            />
          </InputWrap>
        </GridCol>
      </Grid>
    </>
  );
}

export default HeaderLines;
