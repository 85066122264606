import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const storeService = {
  _url: `${process.env.REACT_APP_STORE_API_URL}`,

  GetStores(searchQuery, refetch) {
    const [stores, setStores] = useState({
      stores: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getStores(searchQuery))
        .then(res => {
          setStores(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      searchQuery?.businessId,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.filterStatus,
      searchQuery?.getDeleted,
      refetch,
    ]);
    return {
      stores_loading: status === STATUS.LOADING,
      stores_error: status === STATUS.ERROR ? status : '',
      stores_data: stores,
    };
  },

  GetStoreDetails(id, refetch) {
    const [storeDetails, setStoreDetails] = useState(null);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getStoreDetails(id))
        .then(res => {
          setStoreDetails(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [refetch]);
    return {
      store_details_loading: status === STATUS.LOADING,
      store_details_error: status === STATUS.ERROR ? status : '',
      store_details_data: storeDetails?.store,
    };
  },

  GetBinRanges() {
    const [binRanges, setBinRanges] = useState(null);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getBinRanges())
        .then(res => {
          setBinRanges(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, []);
    return {
      bin_ranges_loading: status === STATUS.LOADING,
      bin_ranges_error: status === STATUS.ERROR ? status : '',
      bin_ranges_data: binRanges,
    };
  },

  async getStores({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterStatus = '',
    businessId = '',
    getDevices = false,
    getDeleted = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-stores?page=${page}&itemsPerPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&status=${filterStatus}&businessId=${businessId}&getDevices=${getDevices}&getDeleted=${getDeleted}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return {
        stores: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async addStore(payload) {
    let res = await Fetch.post(`${this._url}/create-store`, payload);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteStore(id, reason) {
    let res = await Fetch.post(`${this._url}/delete-store/${id}`, { reason });

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async editStore({ _id, ...rest }) {
    let res = await Fetch.put(`${this._url}/update-store/${_id}`, { _id, ...rest });

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getStoreDetails(id) {
    let res = await Fetch.get(`${this._url}/get-store-details/${id}`);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async changeStoreStatus(id, status) {
    let res = await Fetch.put(`${this._url}/change-store-status/${id}`, { status });

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateBinRanges(data) {
    let res = await Fetch.post(`${this._url}/update-bin-ranges`, data);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateBatchReport(id, data) {
    let res = await Fetch.put(`${this._url}/batch-report-setting/${id}`, data);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getBinRanges() {
    let res = await Fetch.get(`${this._url}/bin-range`);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res.data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updateGlobalBinRanges(data) {
    let res = await Fetch.put(`${this._url}/bin-range`, data);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};

export default storeService;
