import React, { useState } from 'react';
import 'styled-components/macro';
import Select from 'components/atoms/Select';
import { capitalize, mergeNames, removeDuplicatedArrayElements } from 'helpers/common';
import storeService from 'services/storeService';
import Grid from 'components/atoms/Grid';
import Button from 'components/atoms/Button';

function UnBlockStoresDevicesUsers({ businessId, getStoresDevicesUsers, onClose }) {
  const [state, setState] = useState(null);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [devicesOptions, setDevicesOptions] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);

  const handleStoresSearch = async text => {
    try {
      const [stores, devices, storeUsers] = await Promise.all([
        storeService
          .getStores({
            page: 1,
            pageSize: 5,
            searchText: text,
            startDate: '',
            endDate: '',
            businessId,
            getDevices: true,
            getDeleted: 'false',
          })
          .then(response => response.stores),
        Promise.resolve(filteredDevices.slice()),
        Promise.resolve(filteredUsers.slice()),
      ]);

      const options = stores.flatMap(({ _id, title, terminals, users }) => {
        if (terminals?.length > 0) {
          devices.push(...terminals);
        }
        if (users?.length > 0) {
          storeUsers.push(...users);
        }
        return { value: _id, label: `Title: ${capitalize(title)}` };
      });

      setFilteredDevices(removeDuplicatedArrayElements(devices));
      setFilteredUsers(removeDuplicatedArrayElements(storeUsers));

      if (options.length < 1) {
        return [];
      }
      return [{ value: 'all', label: 'All' }, ...options];
    } catch {
      return [];
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    const filtered_options = value?.filter(_ => _?.value !== 'all');

    if (value && value?.length > 0) {
      const first_all = value[0]?.value === 'all';
      const last_all = value[value.length - 1]?.value === 'all';

      setState(prev => ({
        ...prev,
        [name]: first_all && !last_all ? filtered_options : value,
      }));

      if ((first_all && last_all) || (!first_all && last_all)) {
        setState(prev => ({
          ...prev,
          [name]: [{ value: 'all', label: 'All' }],
        }));
      }
    } else {
      setState(prev => ({ ...prev, [name]: [] }));
    }
  };

  const handleDeviceSearch = storeIds => {
    if (storeIds.length > 0) {
      const devices = filteredDevices.filter(device =>
        storeIds[0] === 'all' ? device : storeIds.includes(device?.store_id),
      );
      const options = devices.map(_ => ({
        value: _?._id,
        label: `Serial Number: ${_?.serial_number}`,
      }));
      if (state?.devices?.length > 0) {
        const values = options.map(item1 => {
          const matchingItem = state?.devices.find(item2 => item2.value === item1.value);
          if (matchingItem) {
            return matchingItem;
          }
          return item1;
        });
        handleChange({ target: { name: 'devices', value: values } });
      }
      setDevicesOptions(options?.length > 0 ? [{ value: 'all', label: 'All' }, ...options] : []);
    } else {
      setDevicesOptions([]);
    }
  };

  const handleUsersSearch = storeIds => {
    if (storeIds?.length > 0) {
      const options = filteredUsers
        .filter(user => (storeIds[0] === 'all' ? user : storeIds.includes(user?.store_id)))
        .map(_ => ({
          value: _?._id,
          label: `Full Name: ${mergeNames(_)}; Email: ${_?.email}; Type: ${_?.type}`,
        }));
      if (state?.users?.length > 0) {
        const values = options.map(item1 => {
          const matchingItem = state?.users.find(item2 => item2.value === item1.value);
          if (matchingItem) {
            return matchingItem;
          }
          return item1;
        });
        handleChange({ target: { name: 'users', value: values } });
      }
      setUsersOptions(options?.length > 0 ? [{ value: 'all', label: 'All' }, ...options] : []);
    } else {
      setUsersOptions([]);
    }
  };

  const handleStoreChange = ({ target: { value, name } }) => {
    const stores = value?.filter(_ => _?.value !== 'all');
    const selected_stores = value?.map(store => store?.value);

    if (value && value?.length > 0) {
      const first_all = value[0]?.value === 'all';
      const last_all = value[value.length - 1]?.value === 'all';

      setState(prev => ({
        ...prev,
        [name]: first_all && !last_all ? stores : value,
      }));
      const filtered_stores = first_all && !last_all ? stores : selected_stores;
      handleDeviceSearch(filtered_stores);
      handleUsersSearch(filtered_stores);

      if ((first_all && last_all) || (!first_all && last_all)) {
        setState(prev => ({
          ...prev,
          [name]: [{ value: 'all', label: 'All' }],
        }));
        handleDeviceSearch(filtered_stores);
        handleUsersSearch(filtered_stores);
      }
    } else {
      setState({ [name]: [], devices: [], users: [] });
      setDevicesOptions([]);
      setUsersOptions([]);
    }
  };

  return (
    <>
      <Grid xs={1} md={2} gap={10} css="margin-top:30px">
        <Select
          sm
          open
          isMulti
          async
          defaultOptions
          name="stores"
          label="Stores"
          placeholder="Select Stores"
          loadOptions={handleStoresSearch}
          onChange={handleStoreChange}
          value={state?.stores}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        />
        <Select
          sm
          isMulti
          name="devices"
          label="Devices"
          placeholder="Select Devices"
          options={devicesOptions}
          onChange={handleChange}
          value={state?.devices}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        />
      </Grid>
      <Select
        sm
        isMulti
        name="users"
        label="Users"
        placeholder="Select Users"
        options={usersOptions}
        onChange={handleChange}
        value={state?.users}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
      <Button
        type="primary"
        onClick={() => {
          getStoresDevicesUsers(state);
          onClose();
        }}>
        Apply
      </Button>
    </>
  );
}

export default UnBlockStoresDevicesUsers;
