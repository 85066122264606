import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Grid from 'components/atoms/Grid';
import Button from 'components/atoms/Button';
import storeService from 'services/storeService';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import SubTitle from 'components/atoms/SubTitle';

import { generatePsscode } from 'helpers/common';
import { useContextHook } from 'use-context-hook';
import Select from 'components/atoms/Select';
import commonService from 'services/commonService';
import GoogleLocationSelector from '../GeolocationSelector';

function StoreForm({ onClose, store, edit, businessDetails, setStoreDetails }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const onSubmit = async values => {
    try {
      setLoading(true);
      let response;
      if (!edit) {
        const { first_name, last_name, email, phone_number, passcode, address, title, tag_template, ...rest } = values;

        const storeDetails = {
          passcode: passcode?.trim(),
          first_name: first_name?.trim(),
          last_name: last_name?.trim(),
          email: email?.trim(),
          phone_number: phone_number?.trim(),
          address: address?.trim(),
          title: title?.trim(),
          tag_template: tag_template.value,
          ...rest,
        };
        const payload = {
          ...storeDetails,
          business_id: businessDetails._id,
        };
        response = await storeService.addStore(payload);
        setStoreDetails(response.createdStore);
      }
      if (edit) {
        const { address, title, tag_template, ...rest } = values;

        const storeDetails = {
          address: address?.trim(),
          title: title?.trim(),
          tag_template: tag_template.value,
          ...rest,
        };
        const payload = {
          ...storeDetails,
        };

        response = await storeService.editStore(payload);
      }
      if (response.success) {
        onClose();
        refetch();
        Toast({
          type: 'success',
          message: response.message,
        });
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  const onAutoPasscodeGenerate = () => {
    const autoGeneratedPasscode = generatePsscode(4);

    form.setFieldsValue({
      passcode: String(autoGeneratedPasscode),
    });
  };

  useEffect(() => {
    const autoGeneratedPasscode = generatePsscode(4);

    form.setFieldsValue({
      passcode: String(autoGeneratedPasscode),
      report_print_time: '21:45:00',
    });
  }, []);

  useEffect(() => {
    if (edit) {
      const fieldsData = {
        ...store,
      };
      if (store.tag_template) {
        fieldsData.tag_template = {
          value: `${store?.tag_template?._id},${store?.tag_template?.tagString}`,
          label: `${store?.tag_template?.title}:${store?.tag_template?.tagString}`,
        };
      }

      form.setFieldsValue({
        ...fieldsData,
      });
    }
  }, [store]);

  const handleSameAsBusiness = checked => {
    if (checked) {
      const { owner, title, address } = businessDetails;
      form.setFieldsValue({
        ...owner,
        title,
        address,
        same_as_business: true,
      });
    } else {
      form.setFieldsValue({
        same_as_business: false,
        first_name: '',
        last_name: '',
        title: '',
        address: '',
        email: '',
      });
    }
  };

  const handleAddress = ({ target: { value } }) => {
    form.setFieldsValue({
      address: value,
    });
  };

  const handleTagSearch = async text => {
    try {
      let options = null;

      const response = await commonService.getTagTemplates({
        page: 1,
        pageSize: 1000,
        searchText: text,
      });
      options = response?.tagTemplates?.map(_ => ({
        value: `${_?._id},${_?.tagString}`,
        label: `${_?.title}:${_?.tagString}`,
      }));

      return options;
    } catch {
      return [];
    }
  };

  return (
    <Form
      form={form}
      css={`
        margin-top: 30px;
      `}
      onSubmit={onSubmit}>
      {!edit && (
        <Grid xs={1} lg={2} colGap={20}>
          <SubTitle>Store Details</SubTitle>{' '}
          <Form.Item
            sm
            type="checkbox"
            label="Same As Business"
            name="same_as_business"
            onChange={e => {
              handleSameAsBusiness(e.target.value);
            }}>
            <Field />
          </Form.Item>
        </Grid>
      )}
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          sm
          type="text"
          label="Store Title"
          name="title"
          placeholder="Enter Title Of Store"
          rules={[
            { required: true, message: 'Please Enter Store Title' },
            {
              pattern: /^.{3,}$/,
              message: 'Store name must be a minimum of 3 characters.',
            },
            {
              pattern: /^.{3,40}$/,
              message: 'Store name should be maximum of 40 characters.',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label="Store Address"
          name="address"
          placeholder="Address"
          // rules={[{ required: true, message: 'Please enter Address' }]}
          onChange={handleAddress}>
          <GoogleLocationSelector name="addresss" />
        </Form.Item>
        <Form.Item
          sm
          type="passcode"
          label="Passcode"
          name="passcode"
          placeholder="Passcode"
          disabled
          onAutoPasscodeGenerate={onAutoPasscodeGenerate}
          rules={[
            {
              required: true,
            },
            { pattern: /^\d{4}$/, message: 'Minimum Digits length is 4' },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label="Auto print receipt time(00:00:01 - 23:59:59)"
          name="report_print_time"
          placeholder="HH:MM:SS"
          rules={[
            {
              required: true,
              message: 'auto print time is required',
            },
            {
              pattern: /^(?:[01]?\d|2[0-3]):[0-5]\d:[0-5]\d$/,
              message: 'Invalid time format. Please use HH:mm:ss (e.g. 05:10:00)',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label="Tag Templates"
          name="tag_template"
          placeholder="Tag Templates"
          rules={[{ required: true, message: 'Tag Selection is required' }]}>
          <Select sm open async closeMenuOnSelect defaultOptions filterOption={false} loadOptions={handleTagSearch} />
        </Form.Item>
      </Grid>
      {!edit && (
        <>
          <SubTitle>Owner Details</SubTitle>
          <Grid xs={1} lg={2} colGap={20}>
            <Form.Item
              sm
              type="text"
              label="First Name"
              name="first_name"
              placeholder="First Name"
              rules={[
                { required: true, message: 'Please enter first name' },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: 'First name should be Alphabets.',
                },
                {
                  pattern: /^[a-zA-Z\s]{3,}$/,
                  message: 'Owner first name must be a minimum of 3 characters.',
                },
                {
                  pattern: /^[a-zA-Z\s]{3,40}$/,
                  message: 'Owner first name should be maximum of 40 characters.',
                },
              ]}>
              <Field />
            </Form.Item>
            <Form.Item
              sm
              type="text"
              label="Last Name"
              name="last_name"
              placeholder="Last Name"
              rules={[
                { required: true, message: 'Please enter last name' },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: 'Last name should be Alphabets.',
                },
                {
                  pattern: /^[a-zA-Z\s]{3,}$/,
                  message: 'Owner last name must be a minimum of 3 characters.',
                },
                {
                  pattern: /^[a-zA-Z\s]{3,40}$/,
                  message: 'Owner last name should be maximum of 40 characters.',
                },
              ]}>
              <Field />
            </Form.Item>
            <Form.Item
              sm
              type="email"
              label="Owner Email"
              name="email"
              placeholder="Email"
              rules={[
                { required: true, message: 'Please enter email address' },
                { email: true, message: 'Please enter a valid owner email address.' },
                { max: 40, message: 'Email should be at max 40 characters!' },
              ]}>
              <Field />
            </Form.Item>
          </Grid>
        </>
      )}

      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 5px;
        `}>
        {edit ? 'Update' : 'Save'}
      </Button>
    </Form>
  );
}

export default StoreForm;
