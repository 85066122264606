import MenuButton, { MenuItem } from 'components/molecules/Menu/index';
import React, { useState } from 'react';
import { AuthContext } from 'context/authContext';
import ChangeStatusForm from 'components/organisms/ChangeStatusForm';
import ConfigurationForm from 'components/organisms/ConfigurationForm';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import DeviceDetails from 'components/organisms/DeviceDetails';
import DeviceForm from 'components/organisms/DeviceForm';
import ModalContainer from 'components/molecules/ModalContainer';
import { MqttContext } from 'context/mqttContext';
import Toast from 'components/molecules/Toast';
import deviceService from 'services/deviceService';
import Loaders from 'components/atoms/Loaders';
import { useContextHook } from 'use-context-hook';
import configurationService from 'services/configurationService';
import CaptchaComponent from '../CaptchaComponent';
import FunctionalLoaders from '../FunctionalLoaders';
import TagStringUpdate from '../TagStringForm';
import DeviceLocationMap from '../DeviceLocationMap';

function DevicesActions({ _, room }) {
  const [loading, setLoading] = useState(false);
  const [modelsState, setModelsState] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [configuration, setConfiguration] = useState({});
  const [isError, setIsError] = useState(false);

  const { JoinRoom, LeaveRoom } = useContextHook(MqttContext, ['JoinRoom', 'LeaveRoom']);
  const { refetch, hasPermission } = useContextHook(AuthContext, ['refetch', 'hasPermission']);

  const handleConfigFetch = async id => {
    setIsFetching(true);
    try {
      const response = await configurationService.getConfiguration(id);
      setConfiguration(response?.data?.configuration);
      setIsFetching(false);
    } catch (error) {
      setIsError(true);
      Toast({
        type: 'error',
        message: 'Somthing went wrong!',
      });
    }
  };

  const onDeleteDevice = async id => {
    try {
      setLoading(true);
      const { success, message } = await deviceService.deleteDevice(id);

      if (success) {
        Toast({
          type: 'success',
          message,
        });
        refetch();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  const onRevokeAPI = async id => {
    try {
      setLoading(true);

      const { success, message } = await deviceService.revokeApiKey(id);

      if (success) {
        Toast({
          type: 'success',
          message,
        });
        refetch();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  const onForceDeactivate = async id => {
    try {
      setLoading(true);

      const { success, message } = await deviceService.forceDeactivateDevice(id);

      if (success) {
        Toast({
          type: 'success',
          message,
        });
        refetch();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  const checkRoom = open => {
    if (!open) {
      if (!Object.values(modelsState).includes(true)) {
        JoinRoom(room);
      }
    }
  };

  return (
    <MenuButton
      icon={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span
          className="material-icons-outlined"
          onClick={() => {
            LeaveRoom(room);
          }}>
          more_vert
        </span>
      }
      onMenuChange={({ open }) => {
        checkRoom(open);
      }}>
      <ModalContainer
        lg
        stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
        onModalClose={() => {
          JoinRoom(room);
        }}
        title="Device Details"
        content={({ onClose }) => <DeviceDetails device={_} onClose={onClose} />}
        btnComponent={({ onClick }) => (
          <MenuItem
            icon={<i className="material-icons-outlined">description</i>}
            className="detail-btn"
            onClick={() => {
              LeaveRoom(room);
              onClick();
            }}>
            Device Details
          </MenuItem>
        )}
      />

      {hasPermission('device.edit') && _.status === 'Active' && (
        <ModalContainer
          lg
          title="Update Device"
          stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
          onModalClose={() => {
            JoinRoom(room);
          }}
          btnComponent={({ onClick }) => (
            <MenuItem
              icon={<span className="material-icons-outlined">edit</span>}
              onClick={() => {
                LeaveRoom(room);
                onClick();
              }}>
              Update Device
            </MenuItem>
          )}
          content={({ onClose }) => <DeviceForm onClose={onClose} device={_} edit storeId={_.store_id} />}
        />
      )}

      {hasPermission('device.see-configurations') && ( // _.status === 'Active' && (
        <ModalContainer
          stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
          width={1800}
          title="Device Setting"
          isClosable={false}
          onModalClose={() => {
            JoinRoom(room);
          }}
          btnComponent={({ onClick }) => (
            <MenuItem
              icon={<span className="material-icons-outlined">edit</span>}
              onClick={() => {
                handleConfigFetch(_.configId);
                LeaveRoom(room);
                onClick();
              }}>
              Update Config
            </MenuItem>
          )}
          content={({ onClose }) =>
            isFetching ? (
              <FunctionalLoaders onClose={onClose} isFetching={isFetching} isError={isError} />
            ) : (
              <ConfigurationForm
                edit
                device={_}
                onClose={onClose}
                isFetching={isFetching}
                configuration={configuration}
              />
            )
          }
        />
      )}

      {hasPermission('device.revoke') &&
        (_.status === 'Active' || _.status === 'PendingActivation' || _.status === 'ForcedDeactivated') && (
          <ConfirmationModal
            stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
            onModalClose={() => {
              JoinRoom(room);
            }}
            title="Are you sure you want to revoke api key?"
            subtitle="you can't undo this action"
            confirmationModal="Sure"
            okLoading={loading}
            onOk={() => onRevokeAPI(_._id)}
            btnComponent={({ onClick }) => (
              <MenuItem
                icon={<span className="material-icons-outlined">undo</span>}
                onClick={() => {
                  LeaveRoom(room);
                  onClick();
                }}>
                Reset OTP
              </MenuItem>
            )}
          />
        )}

      {hasPermission('device.change-device-status') &&
        _.status !== 'PendingActivation' &&
        _.status !== 'ForcedDeactivated' && (
          <ModalContainer
            stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
            onModalClose={() => {
              JoinRoom(room);
            }}
            lg
            title="Change Device Status"
            btnComponent={({ onClick }) => (
              <MenuItem
                onClick={() => {
                  LeaveRoom(room);
                  onClick();
                }}
                icon={<i className="material-icons-outlined">update</i>}>
                Change Status
              </MenuItem>
            )}
            content={({ onClose }) => (
              <ChangeStatusForm onClose={onClose} id={_._id} device status={_.status} device_api_key={_?.api_key} />
            )}
          />
        )}

      {hasPermission('device.force-deactivate') &&
        (_.status === 'Active' || _.status !== 'PendingActivation') &&
        _.status !== 'ForcedDeactivated' && (
          <ConfirmationModal
            stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
            onModalClose={() => {
              JoinRoom(room);
            }}
            title="Are you sure you want to forcefully diabling this device?"
            subtitle="you can't undo this action"
            confirmationModal="Sure"
            okLoading={loading}
            onOk={() => onForceDeactivate(_._id)}
            btnComponent={({ onClick }) => (
              <MenuItem
                className="delete-btn"
                icon={<span className="material-icons-outlined">delete</span>}
                onClick={() => {
                  LeaveRoom(room);
                  onClick();
                }}>
                Disable Device
              </MenuItem>
            )}
          />
        )}

      {hasPermission('device.delete') && (
        <ModalContainer
          stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
          lg
          onModalClose={() => {
            JoinRoom(room);
          }}
          title="Are you sure you want to deactivate Device?"
          btnComponent={({ onClick }) => (
            <MenuItem
              className="delete-btn"
              icon={<span className="material-icons-outlined">delete_forever</span>}
              onClick={() => {
                LeaveRoom(room);
                onClick();
              }}>
              Deactivate Device
            </MenuItem>
          )}
          content={({ onClose }) => (
            <Loaders loading={loading}>
              <CaptchaComponent
                onClose={onClose}
                onDelete={() => onDeleteDevice(_._id)}
                type="device"
                serial_number={_?.serial_number}
              />
            </Loaders>
          )}
        />
      )}
      {hasPermission('device.update-tag-string') && (
        <ModalContainer
          stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
          onModalClose={() => {
            JoinRoom(room);
          }}
          lg
          title="Update Tag String"
          btnComponent={({ onClick }) => (
            <MenuItem
              onClick={() => {
                LeaveRoom(room);
                onClick();
              }}
              icon={<i className="material-icons-outlined">update</i>}>
              Update Tag String
            </MenuItem>
          )}
          content={({ onClose }) => <TagStringUpdate onClose={onClose} device={_} />}
        />
      )}
      {hasPermission('device.locate-device') && (
        <ModalContainer
          stateChanged={__ => setModelsState(___ => ({ ...___, ...__ }))}
          onModalClose={() => {
            JoinRoom(room);
          }}
          title="Device Location"
          btnComponent={({ onClick }) => (
            <MenuItem
              onClick={() => {
                LeaveRoom(room);
                onClick();
              }}
              icon={<i className="material-icons-outlined">map</i>}>
              Locate Device
            </MenuItem>
          )}
          content={({ onClose }) => <DeviceLocationMap onClose={onClose} device={_} />}
        />
      )}
    </MenuButton>
  );
}

export default DevicesActions;
