import GridCol from 'components/atoms/GridCol';
import React from 'react';
import Field from 'components/molecules/Field';
import Grid from 'components/atoms/Grid';
import 'styled-components/macro';
import Switch from 'components/atoms/Switch';
import { CheckboxHolder, GroupFieldHeading, InputWrap } from './configuration.styles';
import { PermissionListHead } from '../PermissionSelector/PermissionSelector.styles';

function FooterLines({ state, section, onChange = () => {} }) {
  return (
    <>
      <PermissionListHead>
        Receipt footer lines
        <CheckboxHolder>
          <Switch noMargin label="Disable/Enable" name={`${section}.value`} value={state.value} onChange={onChange} />
        </CheckboxHolder>
      </PermissionListHead>
      <Grid
        xs={12}
        sm={12}
        lg={12}
        colGap={30}
        rowGap={20}
        css={`
          padding: 15px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 500px;
        `}>
        {Object.entries(state).map(
          ([k, v], idx) =>
            k !== 'value' && (
              <GridCol xs={12} md={6} lg={6} key={k}>
                <InputWrap>
                  <GroupFieldHeading>Footer line {idx + 1}</GroupFieldHeading>
                  <Field
                    margin={2}
                    name={`${section}.${k}.en`}
                    type="text"
                    label="English"
                    value={v.en}
                    onChange={onChange}
                    disabled={!state.value}
                  />
                  <Field
                    name={`${section}.${k}.fr`}
                    type="text"
                    label="French"
                    value={v.fr}
                    onChange={onChange}
                    disabled={!state.value}
                  />
                </InputWrap>
              </GridCol>
            ),
        )}
      </Grid>
    </>
  );
}

export default FooterLines;
