import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const deviceService = {
  _url: `${process.env.REACT_APP_DEVICE_API_URL}`,

  GetDevices(searchQuery, refetch, fetchTerminals) {
    const [devices, setDevices] = useState({
      statusCount: [],
      device: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getDevices(searchQuery))
        .then(res => {
          setDevices(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      searchQuery?.storeId,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.filterStatus,
      searchQuery?.getDeleted,
      searchQuery?.group,
      searchQuery?.groupPage,
      searchQuery?.user_id,
      searchQuery?.getStoreInfo,
      searchQuery?.getBusinessInfo,
      refetch,
      fetchTerminals,
    ]);
    return {
      devices_loading: status === STATUS.LOADING,
      devices_error: status === STATUS.ERROR ? status : '',
      devices_data: devices,
    };
  },

  GetDeviceLocation(id) {
    const [location, setLocation] = useState({
      coordinates: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getLocation(id))
        .then(res => {
          setLocation(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [id]);
    return {
      device_location_loading: status === STATUS.LOADING,
      device_location_error: status === STATUS.ERROR ? status : '',
      device_location: location,
    };
  },

  async getDevices({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    storeId = '',
    filterStatus = '',
    group = '',
    groupPage = false,
    getDeleted = '',
    user_id = '',
    getStoreInfo = '',
    getBusinessInfo = '',
    getAllTerminalsCount = '',
    skip_extra = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-terminals?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&storeId=${storeId}&status=${filterStatus}&group=${group}&groupPage=${groupPage}&getDeleted=${getDeleted}&user_id=${user_id}&getStoreInfo=${getStoreInfo}&getBusinessInfo=${getBusinessInfo}&getAllTerminalsCount=${getAllTerminalsCount}&skip_extra=${skip_extra}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        statusCount: res.statusCount ?? [],
        device: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteDevice(id) {
    let res = await Fetch.delete(`${this._url}/delete-terminal/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createDevice(payload) {
    let res = await Fetch.post(`${this._url}/create-terminal`, { ...payload });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async editDevice(payload) {
    let res = await Fetch.patch(`${this._url}/update-terminal/${payload.id}`, { ...payload });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async changeDeviceStatus(id, status) {
    let res = await Fetch.put(`${this._url}/change-terminal-status/${id}`, { status });

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async revokeApiKey(id) {
    let res = await Fetch.post(`${this._url}/revoke-api/${id}`);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async forceDeactivateDevice(id) {
    let res = await Fetch.put(`${this._url}/force-deactivate-device/${id}`);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateTagString(id, payload) {
    let res = await Fetch.put(`${this._url}/tag-string/${id}`, payload);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getLocation(id) {
    let res = await Fetch.get(`${this._url}/get-location/${id}`);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      const re = res.data;
      return re;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};

export default deviceService;
