import React, { useEffect } from 'react';
import Loaders from 'components/atoms/Loaders';
import Button from 'components/atoms/Button';
import { Box, Btn } from './FunctionalLoader.styles';

function FunctionalLoaders({ isFetching, onClose, isError }) {
  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError]);
  return (
    <>
      <Box>
        <Loaders loading={isFetching} />
        <h2>Fetching, Please wait</h2>
      </Box>
      <Btn>
        <Button
          type="outline"
          md
          onClick={() => {
            onClose();
          }}
          width={80}>
          Close
        </Button>
      </Btn>
    </>
  );
}

export default FunctionalLoaders;
