import styled from 'styled-components/macro';
import BG from 'assets/images/bg.jpg';
import Link from 'components/atoms/Link';

export const TemplateHolder = styled.div`
  min-height: 100vh;
  height: 100%;
  position: relative;
  z-index: 1;
  background-image: url(${BG});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--primary-gradient);
    z-index: -1;
    opacity: 0.6;
  }
`;

export const LoginHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 30px;
  @media (min-width: 768px) {
    padding: 40px 80px;
  }
`;

export const Content = styled.div`
  padding: 1rem;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
  @media (min-width: 992px) {
    padding: 3.75rem 4rem;
  }
`;

export const ContentHolder = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  padding: 80px 30px;
  @media (min-width: 768px) {
    padding: 90px 45px;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--light-gray);
  &:hover {
    i {
      transform: translateX(-5px);
    }
  }
  i {
    color: var(--primary);
    transition: transform 0.3s linear;
  }
`;

export const SubTitle = styled.span`
  color: var(--light-gray);
  margin-bottom: 40px;
  display: block;
  @media (min-width: 768px) {
    margin-bottom: 80px;
  }
`;

export const FormHolder = styled.div`
  width: 100%;
`;
