import React, { useState } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import { AuthContext } from 'context/authContext';
import Select from 'components/atoms/Select';
import Loaders from 'components/atoms/Loaders';
import Button from 'components/atoms/Button';
import Toast from 'components/molecules/Toast';
import userService from 'services/userService';
import storeService from 'services/storeService';
import { useContextHook } from 'use-context-hook';

function TransferUserForm({ onClose, id, businessId, storeId }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const handleStoreSearch = async text => {
    try {
      let options = null;
      const response = await storeService.getStores({
        businessId,
        searchText: text,
        page: 1,
        pageSize: 10,
        filterStatus: 'Active',
        getDeleted: false,
      });
      options = response?.stores
        ?.filter(store => store._id !== storeId)
        ?.map(_ => ({
          value: _?._id,
          label: `${_?.title?.toUpperCase()}`,
        }));
      return options;
    } catch {
      return [];
    }
  };

  const onSubmit = async values => {
    try {
      setLoading(true);
      let response = null;
      const newStoreId = values.stores.value;

      response = await userService.transferUser(id, newStoreId);

      if (response.success) {
        Toast({
          type: 'success',
          message: response.message,
        });
        refetch();
        onClose();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  return (
    <Loaders loading={loading}>
      <Form form={form} onSubmit={onSubmit} css="margin-top:20px">
        <Form.Item name="stores" rules={[{ required: true, message: 'Kindly Select One Option' }]} label="Select Store">
          <Select sm open async defaultOptions filterOption={false} loadOptions={handleStoreSearch} />
        </Form.Item>
        <Button htmlType="submit" type="primary" loading={loading}>
          Submit
        </Button>
      </Form>
    </Loaders>
  );
}

export default TransferUserForm;
