/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import { BtnHolder } from 'components/molecules/ConfirmationModal/ConfirmationModal.styles';
import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import DataTabs from 'components/molecules/DataTabs';
import Field from 'components/molecules/Field';
import Grid from 'components/atoms/Grid';
import GridCol from 'components/atoms/GridCol';
import Loaders from 'components/atoms/Loaders';
import Switch from 'components/atoms/Switch';
import Toast from 'components/molecules/Toast';
import configurationService from 'services/configurationService';
import { useMediaPredicate } from 'react-media-hook';
import { useContextHook } from 'use-context-hook';
import ModalContainer from 'components/molecules/ModalContainer';

import { PermissionListGroup, PermissionListHead } from '../PermissionSelector/PermissionSelector.styles';
import { FieldWithHolder, SecondSufix, Wrapper } from './configuration.styles';
import UserCreationForm from '../UserCreationForm';
import VisibleLock from './VisibleLock';
import FooterLines from './FooterLines';
import HeaderLines from './HeaderLines';

export default function ConfigurationForm({
  edit,
  device,
  deviceGroup,
  configuration = {},
  onClose = () => {},
  getConfig = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [touchedfields, setTouchedfields] = useState({});
  const [state, setState] = useState(configuration);
  const [timeError, setTimeError] = useState('');
  const [isReadyToClick, setIsReadyToClick] = useState('');
  const MaxWidth991 = useMediaPredicate('(max-width: 991px)');
  const { refetch, hasPermission } = useContextHook(AuthContext, ['refetch', 'hasPermission']);

  useEffect(() => {
    function handleBeforeUnload(event) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }

    if (edit) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (Object.values(touchedfields).length > 0) {
      const negativeValues = Object.values(touchedfields).filter(itm => itm < 0);
      if (negativeValues.length > 0) {
        setIsReadyToClick(false);
      } else setIsReadyToClick(true);
    }
  }, [touchedfields]);

  const onDone = async () => {
    if (device?._id && !deviceGroup) {
      try {
        setLoading(true);
        const configData = {
          configuration: state,
          id: device._id,
          touchedfields,
        };

        const response = await configurationService.updateConfiguration(configData);

        if (response.success) {
          Toast({
            type: 'success',
            message: response.message,
          });
        }
      } catch (error) {
        Toast({
          type: 'error',
          message: error.message ?? 'Something Went Wrong',
        });
      }
      setLoading(false);
      onClose();
      refetch();
    } else if (deviceGroup) {
      try {
        setLoading(true);
        const configData = {
          configuration: state,
          id: device?.terminals.map(term => term._id),
          deviceGroup,
          touchedfields,
        };
        const response = await configurationService.updateConfiguration(configData);
        if (response.success) {
          Toast({
            type: 'success',
            message: response.message,
          });
          setLoading(false);
          onClose();
          refetch();
        }
      } catch (error) {
        Toast({
          type: 'error',
          message: error.message ?? 'Something Went Wrong',
        });
      }
    } else {
      getConfig(state);
      onClose();
    }
  };

  const handleTouchedFields = (name, value) => {
    setTouchedfields(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const checkNumbersAndShowError = str => Number(str) >= 0;

  const checkForCorrectTime = value => {
    const timeRegex = /^(?:[01]?\d|2[0-3]):[0-5]\d:[0-5]\d$/;
    if (timeRegex.test(value)) {
      setTimeError('');
    } else {
      setTimeError('Invalid time format. Please use HH:mm:ss (e.g. 05:10:00).');
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === 'printer.auto_print.fee') checkForCorrectTime(value);
    const keys = name.split('.'); // parse key name
    handleTouchedFields(name, value);
    const newState = { ...state }; // create a copy of the state
    let obj = newState;
    for (let i = 0; i < keys.length - 1; i++) {
      if (!(keys[i] in obj)) {
        obj[keys[i]] = {};
      }
      obj = obj[keys[i]];
    }
    obj[keys[keys.length - 1]] = value; // update the final key value in the nested object
    if (keys.includes('tip_screen') && keys.includes('value')) {
      const tipConfig = newState.transaction_settings.tip_configuration;
      Object.keys(tipConfig)
        .slice(2)
        .forEach(key => {
          tipConfig[key].value = value;
        });
    }
    if (keys.includes('transaction_settings') && keys.includes('_v') && keys.length === 2) {
      const tipConfig = newState.transaction_settings;
      Object.keys(tipConfig)
        .slice(1)
        .forEach(key => {
          tipConfig[key]._v = value;
        });
    }
    if (keys.length === 2 && keys[1] === '_v') {
      const configObject = newState[keys[0]];
      Object.keys(configObject).forEach(key => {
        if (typeof configObject[key] === 'object') {
          if ('_v' in configObject[key]) {
            configObject[key]._v = value;
          }
        }
      });
    }

    setState(newState); // set the new state
  };

  const terminal_configuration = useMemo(
    () => (
      <PermissionListGroup>
        <PermissionListHead>Terminal Configuration</PermissionListHead>
        <DataTabs
          data={[
            {
              label: 'Language and Terminal Mode',
              content: (
                <>
                  <VisibleLock
                    title="Language and Terminal Mode"
                    onChange={handleChange}
                    state={state?.terminal_configuration?.language_and_terminal_mode}
                    section="terminal_configuration.language_and_terminal_mode"
                    lock
                    visible
                  />

                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={6} lg={6}>
                      <PermissionListHead>Terminal Language</PermissionListHead>
                      <Grid
                        xs={12}
                        sm={12}
                        lg={12}
                        colGap={30}
                        rowGap={20}
                        css={`
                          padding: 15px;
                          overflow-x: hidden;
                          overflow-y: auto;
                          max-height: 500px;
                        `}>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="terminal_configuration.language_and_terminal_mode.terminal_language.english.value"
                            type="radio"
                            label="English"
                            value={
                              state?.terminal_configuration.language_and_terminal_mode.terminal_language.english.value
                            }
                            onChange={event => {
                              handleChange(event);
                              handleChange({
                                target: {
                                  name: 'terminal_configuration.language_and_terminal_mode.terminal_language.french.value',
                                  value: false,
                                },
                              });
                            }}
                          />
                        </GridCol>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="terminal_configuration.language_and_terminal_mode.terminal_language.french.value"
                            type="radio"
                            label="French"
                            value={
                              state?.terminal_configuration.language_and_terminal_mode.terminal_language.french.value
                            }
                            onChange={event => {
                              handleChange(event);
                              handleChange({
                                target: {
                                  name: 'terminal_configuration.language_and_terminal_mode.terminal_language.english.value',
                                  value: false,
                                },
                              });
                            }}
                          />
                        </GridCol>
                      </Grid>
                    </GridCol>
                    <GridCol xs={12} md={6} lg={6}>
                      <PermissionListHead>Terminal Mode</PermissionListHead>
                      <Grid
                        xs={12}
                        sm={12}
                        lg={12}
                        colGap={30}
                        rowGap={20}
                        css={`
                          padding: 15px;
                          overflow-x: hidden;
                          overflow-y: auto;
                          max-height: 500px;
                        `}>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="terminal_configuration.language_and_terminal_mode.terminal_mode.manual_entry.value"
                            type="checkbox"
                            label="Manual Entry"
                            value={
                              state?.terminal_configuration.language_and_terminal_mode.terminal_mode.manual_entry.value
                            }
                            onChange={handleChange}
                          />
                        </GridCol>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="terminal_configuration.language_and_terminal_mode.terminal_mode.demo_mode.value"
                            type="checkbox"
                            label="Demo Mode"
                            value={
                              state?.terminal_configuration.language_and_terminal_mode.terminal_mode.demo_mode.value
                            }
                            onChange={handleChange}
                          />
                        </GridCol>
                        {/* <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="terminal_configuration.language_and_terminal_mode.terminal_mode.pos_terminal.value"
                            type="checkbox"
                            label="POS Terminal"
                            value={
                              state?.terminal_configuration.language_and_terminal_mode.terminal_mode.pos_terminal?.value
                            }
                            onChange={handleChange}
                          />
                        </GridCol> */}
                      </Grid>
                    </GridCol>
                  </Grid>
                </>
              ),
            },
            {
              label: 'Clerk Server Maintenance',
              content: (
                <>
                  <VisibleLock
                    title="Clerk Server Maintenance"
                    onChange={handleChange}
                    state={state?.terminal_configuration?.clerk_server_maintenance}
                    section="terminal_configuration.clerk_server_maintenance"
                    lock
                    visible
                  />

                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={6} lg={6}>
                      {device && hasPermission('user.create-user') ? (
                        <ModalContainer
                          lg
                          title="Create Clerk"
                          btnComponent={({ onClick }) => (
                            <Button type="primary" onClick={onClick} size={30}>
                              Add Clerk
                            </Button>
                          )}
                          content={({ onClose: onCloseUserCreationForm }) => (
                            <UserCreationForm
                              onClose={onCloseUserCreationForm}
                              storeId={device.store_id}
                              type="clerk"
                            />
                          )}
                        />
                      ) : null}
                    </GridCol>
                    <GridCol xs={12} md={6} lg={6}>
                      {device && hasPermission('user.create-user') ? (
                        <ModalContainer
                          lg
                          title="Create Server"
                          btnComponent={({ onClick }) => (
                            <Button type="primary" onClick={onClick} size={30}>
                              Add Server
                            </Button>
                          )}
                          content={({ onClose: onCloseUserCreationForm }) => (
                            <UserCreationForm
                              onClose={onCloseUserCreationForm}
                              storeId={device.store_id}
                              type="server"
                            />
                          )}
                        />
                      ) : null}
                    </GridCol>
                  </Grid>
                </>
              ),
            },
            {
              label: 'Merchant Passcode',
              content: (
                <>
                  <VisibleLock
                    title="Merchant Passcode"
                    onChange={handleChange}
                    state={state?.terminal_configuration?.merchant_passcode}
                    section="terminal_configuration.merchant_passcode"
                    lock
                    visible
                  />

                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={12} lg={12}>
                      <span
                        css={`
                          display: flex;
                          justify-content: space-between;
                        `}>
                        <Switch
                          name="terminal_configuration.merchant_passcode.value"
                          label="Disable/Enable"
                          value={state?.terminal_configuration?.merchant_passcode?.value}
                          onChange={handleChange}
                        />
                      </span>
                    </GridCol>
                  </Grid>
                </>
              ),
            },
            {
              label: 'Clerk ID',
              content: (
                <>
                  <VisibleLock
                    title="Clerk ID"
                    onChange={handleChange}
                    state={state?.terminal_configuration?.clerk_id}
                    section="terminal_configuration.clerk_id"
                    lock
                    visible
                  />

                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={12} lg={12}>
                      <span
                        css={`
                          display: flex;
                          justify-content: space-between;
                        `}>
                        <Switch
                          name="terminal_configuration.clerk_id.value"
                          label="Disable/Enable"
                          value={state?.terminal_configuration?.clerk_id?.value}
                          onChange={handleChange}
                        />
                      </span>
                    </GridCol>
                  </Grid>
                </>
              ),
            },
          ]}
          orientation={MaxWidth991 ? 'horizontal' : 'vertical'}
        />
      </PermissionListGroup>
    ),
    [JSON.stringify(state?.terminal_configuration)],
  );

  const screen_time = useMemo(
    () => (
      <PermissionListGroup>
        <VisibleLock title="Screen Timer/Screen Counter" />
        <DataTabs
          data={[
            {
              label: 'Sale',
              content: (
                <>
                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={6} lg={6}>
                      <PermissionListHead>Pin Screen</PermissionListHead>
                      <Grid
                        xs={12}
                        sm={12}
                        lg={12}
                        colGap={30}
                        rowGap={20}
                        css={`
                          padding: 15px;
                          overflow-x: hidden;
                          overflow-y: auto;
                          max-height: 500px;
                        `}>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="screen_times.credit_debit_sale.pin_screen_time"
                            type="number"
                            min={0}
                            label="Timer"
                            value={state?.screen_times.credit_debit_sale?.pin_screen_time}
                            error={
                              checkNumbersAndShowError(state?.screen_times.credit_debit_sale?.pin_screen_time)
                                ? false
                                : 'Not Valid Input'
                            }
                            suffix={<SecondSufix style={{ fontSize: '12px', color: 'grey' }}>sec</SecondSufix>}
                            onChange={handleChange}
                          />
                        </GridCol>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="screen_times.credit_debit_sale.pin_screen_counter"
                            type="number"
                            min={0}
                            label="Counter"
                            value={state?.screen_times.credit_debit_sale?.pin_screen_counter}
                            error={
                              checkNumbersAndShowError(state?.screen_times.credit_debit_sale?.pin_screen_counter)
                                ? false
                                : 'Not Valid Input'
                            }
                            onChange={handleChange}
                          />
                        </GridCol>
                      </Grid>
                    </GridCol>

                    <GridCol xs={12} md={6} lg={6}>
                      <PermissionListHead>Tap Screen</PermissionListHead>
                      <Grid
                        xs={12}
                        sm={12}
                        lg={12}
                        colGap={30}
                        rowGap={20}
                        css={`
                          padding: 15px;
                          overflow-x: hidden;
                          overflow-y: auto;
                          max-height: 500px;
                        `}>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="screen_times.credit_debit_sale.tap_screen_time"
                            type="number"
                            min={0}
                            label="Timer"
                            value={state?.screen_times.credit_debit_sale?.tap_screen_time}
                            error={
                              checkNumbersAndShowError(state?.screen_times.credit_debit_sale?.tap_screen_time)
                                ? false
                                : 'Not Valid Input'
                            }
                            suffix={<SecondSufix style={{ fontSize: '12px', color: 'grey' }}>sec</SecondSufix>}
                            onChange={handleChange}
                          />
                        </GridCol>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="screen_times.credit_debit_sale.tap_screen_counter"
                            type="number"
                            min={0}
                            label="Counter"
                            value={state?.screen_times.credit_debit_sale?.tap_screen_counter}
                            error={
                              checkNumbersAndShowError(state?.screen_times.credit_debit_sale?.tap_screen_counter)
                                ? false
                                : 'Not Valid Input'
                            }
                            onChange={handleChange}
                          />
                        </GridCol>
                      </Grid>
                    </GridCol>
                  </Grid>
                  <GridCol xs={12} md={6} lg={6}>
                    <PermissionListHead>Other Screens</PermissionListHead>
                    <Grid
                      xs={12}
                      sm={12}
                      lg={12}
                      colGap={30}
                      rowGap={20}
                      css={`
                        padding: 15px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        max-height: 500px;
                      `}>
                      <GridCol xs={6} md={6} lg={6}>
                        <Field
                          sm
                          name="screen_times.credit_debit_sale.other_screen_time"
                          type="number"
                          min={0}
                          label="Timer"
                          value={state?.screen_times.credit_debit_sale?.other_screen_time}
                          error={
                            checkNumbersAndShowError(state?.screen_times.credit_debit_sale?.other_screen_time)
                              ? false
                              : 'Not Valid Input'
                          }
                          suffix={<SecondSufix style={{ fontSize: '12px', color: 'grey' }}>sec</SecondSufix>}
                          onChange={handleChange}
                        />
                      </GridCol>
                      <GridCol xs={6} md={6} lg={6}>
                        <Field
                          sm
                          name="screen_times.credit_debit_sale.other_screen_counter"
                          type="number"
                          min={0}
                          label="Counter"
                          value={state?.screen_times.credit_debit_sale?.other_screen_counter}
                          error={
                            checkNumbersAndShowError(state?.screen_times.credit_debit_sale?.other_screen_counter)
                              ? false
                              : 'Not Valid Input'
                          }
                          onChange={handleChange}
                        />
                      </GridCol>
                    </Grid>
                  </GridCol>
                </>
              ),
            },
            {
              label: 'Refund',
              content: (
                <>
                  <VisibleLock
                    title="Refund"
                    onChange={handleChange}
                    state={state?.screen_times.refund}
                    section="screen_times.refund"
                    lock
                    visible
                  />
                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={6} lg={6}>
                      <PermissionListHead>Pin Screen</PermissionListHead>
                      <Grid
                        xs={12}
                        sm={12}
                        lg={12}
                        colGap={30}
                        rowGap={20}
                        css={`
                          padding: 15px;
                          overflow-x: hidden;
                          overflow-y: auto;
                          max-height: 500px;
                        `}>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="screen_times.refund.pin_screen_time"
                            type="number"
                            min={0}
                            label="Timer"
                            value={state?.screen_times.refund?.pin_screen_time}
                            error={
                              checkNumbersAndShowError(state?.screen_times.refund?.pin_screen_time)
                                ? false
                                : 'Not Valid Input'
                            }
                            suffix={<SecondSufix style={{ fontSize: '12px', color: 'grey' }}>sec</SecondSufix>}
                            onChange={handleChange}
                          />
                        </GridCol>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="screen_times.refund.pin_screen_counter"
                            type="number"
                            min={0}
                            label="Counter"
                            value={state?.screen_times.refund?.pin_screen_counter}
                            error={
                              checkNumbersAndShowError(state?.screen_times.refund?.pin_screen_counter)
                                ? false
                                : 'Not Valid Input'
                            }
                            onChange={handleChange}
                          />
                        </GridCol>
                      </Grid>
                    </GridCol>

                    <GridCol xs={12} md={6} lg={6}>
                      <PermissionListHead>Tap Screen</PermissionListHead>
                      <Grid
                        xs={12}
                        sm={12}
                        lg={12}
                        colGap={30}
                        rowGap={20}
                        css={`
                          padding: 15px;
                          overflow-x: hidden;
                          overflow-y: auto;
                          max-height: 500px;
                        `}>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="screen_times.refund.tap_screen_time"
                            type="number"
                            min={0}
                            label="Timer"
                            value={state?.screen_times.refund?.tap_screen_time}
                            error={
                              checkNumbersAndShowError(state?.screen_times.refund?.tap_screen_time)
                                ? false
                                : 'Not Valid Input'
                            }
                            suffix={<SecondSufix style={{ fontSize: '12px', color: 'grey' }}>sec</SecondSufix>}
                            onChange={handleChange}
                          />
                        </GridCol>
                        <GridCol xs={6} md={6} lg={6}>
                          <Field
                            sm
                            name="screen_times.refund.tap_screen_counter"
                            type="number"
                            min={0}
                            label="Counter"
                            value={state?.screen_times.refund?.tap_screen_counter}
                            error={
                              checkNumbersAndShowError(state?.screen_times.refund?.tap_screen_counter)
                                ? false
                                : 'Not Valid Input'
                            }
                            onChange={handleChange}
                          />
                        </GridCol>
                      </Grid>
                    </GridCol>
                  </Grid>
                  <GridCol xs={12} md={6} lg={6}>
                    <PermissionListHead>Other Screens</PermissionListHead>
                    <Grid
                      xs={12}
                      sm={12}
                      lg={12}
                      colGap={30}
                      rowGap={20}
                      css={`
                        padding: 15px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        max-height: 500px;
                      `}>
                      <GridCol xs={6} md={6} lg={6}>
                        <Field
                          sm
                          name="screen_times.refund.other_screen_time"
                          type="number"
                          min={0}
                          label="Timer"
                          value={state?.screen_times.refund?.other_screen_time}
                          error={
                            checkNumbersAndShowError(state?.screen_times.refund?.other_screen_time)
                              ? false
                              : 'Not Valid Input'
                          }
                          suffix={<SecondSufix style={{ fontSize: '12px', color: 'grey' }}>sec</SecondSufix>}
                          onChange={handleChange}
                        />
                      </GridCol>
                      <GridCol xs={6} md={6} lg={6}>
                        <Field
                          sm
                          name="screen_times.refund.other_screen_counter"
                          type="number"
                          min={0}
                          label="Counter"
                          value={state?.screen_times.refund?.other_screen_counter}
                          error={
                            checkNumbersAndShowError(state?.screen_times.refund?.other_screen_counter)
                              ? false
                              : 'Not Valid Input'
                          }
                          onChange={handleChange}
                        />
                      </GridCol>
                    </Grid>
                  </GridCol>
                </>
              ),
            },
          ]}
          orientation={MaxWidth991 ? 'horizontal' : 'vertical'}
        />
      </PermissionListGroup>
    ),
    [JSON.stringify(state?.screen_times)],
  );

  const printer = useMemo(
    () => (
      <PermissionListGroup>
        <VisibleLock title="Printer" onChange={handleChange} state={state?.printer} section="printer" lock visible />
        <DataTabs
          data={[
            {
              label: 'printer settings',
              content: (
                <Grid
                  xs={1}
                  sm={2}
                  lg={3}
                  colGap={30}
                  rowGap={20}
                  css={`
                    padding: 15px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    max-height: 500px;
                  `}>
                  <GridCol xs={12} md={6} lg={6}>
                    <span
                      css={`
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 30px;
                      `}>
                      <Switch
                        noMargin
                        name="printer.pre_print.value"
                        type="checkbox"
                        label="Pre-Print"
                        value={state?.printer.pre_print?.value}
                        onChange={handleChange}
                      />
                      <Switch
                        noMargin
                        sm
                        name="printer.both_receipt.value"
                        type="checkbox"
                        label="Both Receipt"
                        value={state?.printer.both_receipt?.value}
                        onChange={handleChange}
                      />
                      <Switch
                        noMargin
                        sm
                        name="printer.receipt_option.value"
                        type="checkbox"
                        label="Receipts Option Screen"
                        value={state?.printer?.receipt_option?.value}
                        onChange={handleChange}
                      />
                    </span>
                  </GridCol>

                  <GridCol xs={12} md={6} lg={6}>
                    <FooterLines
                      state={state?.printer?.receipt_footer_lines}
                      section="printer.receipt_footer_lines"
                      onChange={handleChange}
                    />
                  </GridCol>
                  <GridCol xs={12} md={6} lg={6}>
                    <HeaderLines
                      state={state?.printer?.receipt_header_lines}
                      section="printer.receipt_header_lines"
                      onChange={handleChange}
                    />
                  </GridCol>
                </Grid>
              ),
            },
          ]}
          orientation={MaxWidth991 ? 'horizontal' : 'vertical'}
        />
      </PermissionListGroup>
    ),
    [JSON.stringify(state?.printer)],
  );

  const transaction_settings = useMemo(
    () => (
      <PermissionListGroup>
        <VisibleLock
          title="Transaction Settings"
          onChange={handleChange}
          state={state?.transaction_settings}
          section="transaction_settings"
          visible
        />

        <DataTabs
          data={[
            {
              label: 'Tip Configuration',
              content: (
                <>
                  <VisibleLock
                    title="Tip Configuration"
                    onChange={handleChange}
                    state={state?.transaction_settings.tip_configuration}
                    section="transaction_settings.tip_configuration"
                    lock
                    visible
                  />

                  <Grid
                    xs={1}
                    sm={2}
                    lg={3}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={6} lg={6}>
                      <span
                        css={`
                          display: flex;
                          justify-content: space-between;
                        `}>
                        <Switch
                          noMargin
                          label="Show Tip Screen"
                          name="transaction_settings.tip_configuration.tip_screen.value"
                          value={state?.transaction_settings.tip_configuration.tip_screen?.value}
                          onChange={handleChange}
                        />
                      </span>
                    </GridCol>
                    <GridCol xs={12} md={6} lg={6}>
                      <PermissionListHead>Select Tip Type</PermissionListHead>
                      <Grid
                        xs={12}
                        sm={12}
                        lg={12}
                        colGap={30}
                        rowGap={20}
                        css={`
                          padding: 15px;
                          overflow-x: hidden;
                          overflow-y: auto;
                          max-height: 500px;
                        `}>
                        <GridCol xs={6} md={6} lg={6}>
                          <FieldWithHolder>
                            <Switch
                              sm
                              name="transaction_settings.tip_configuration.percentage_tip_amount.value"
                              type="checkbox"
                              label="Percentage Tip Amount"
                              noMargin
                              value={state?.transaction_settings.tip_configuration.percentage_tip_amount?.value}
                              onChange={handleChange}
                            />
                          </FieldWithHolder>

                          <GridCol xs={12} md={12} lg={12}>
                            <Grid
                              xs={12}
                              sm={12}
                              lg={12}
                              colGap={30}
                              rowGap={20}
                              css={`
                                padding: 15px;
                                overflow-x: hidden;
                                overflow-y: auto;
                                max-height: 500px;
                              `}>
                              <GridCol xs={6} md={6} lg={6}>
                                <Field
                                  sm
                                  name="transaction_settings.tip_configuration.percentage_tip_amount.amount_1.value"
                                  type="number"
                                  min={0}
                                  label="Percentage Amount 1"
                                  suffix={<span className="material-icons-outlined">percent</span>}
                                  onChange={handleChange}
                                  value={
                                    state?.transaction_settings.tip_configuration.percentage_tip_amount.amount_1.value
                                  }
                                  error={
                                    checkNumbersAndShowError(
                                      state?.transaction_settings.tip_configuration.percentage_tip_amount.amount_1
                                        .value,
                                    )
                                      ? false
                                      : 'Not Valid Input'
                                  }
                                />
                              </GridCol>
                              <GridCol xs={6} md={6} lg={6}>
                                <Field
                                  sm
                                  name="transaction_settings.tip_configuration.percentage_tip_amount.amount_2.value"
                                  type="number"
                                  min={0}
                                  label="Percentage Amount 2"
                                  suffix={<span className="material-icons-outlined">percent</span>}
                                  onChange={handleChange}
                                  value={
                                    state?.transaction_settings.tip_configuration.percentage_tip_amount.amount_2.value
                                  }
                                  error={
                                    checkNumbersAndShowError(
                                      state?.transaction_settings.tip_configuration.percentage_tip_amount.amount_2
                                        .value,
                                    )
                                      ? false
                                      : 'Not Valid Input'
                                  }
                                />
                              </GridCol>
                              <GridCol xs={12} md={12} lg={12}>
                                <Field
                                  sm
                                  name="transaction_settings.tip_configuration.percentage_tip_amount.amount_3.value"
                                  type="number"
                                  min={0}
                                  label="Percentage Amount 3"
                                  suffix={<span className="material-icons-outlined">percent</span>}
                                  value={
                                    state?.transaction_settings.tip_configuration.percentage_tip_amount.amount_3.value
                                  }
                                  error={
                                    checkNumbersAndShowError(
                                      state?.transaction_settings.tip_configuration.percentage_tip_amount.amount_3
                                        .value,
                                    )
                                      ? false
                                      : 'Not Valid Input'
                                  }
                                  onChange={handleChange}
                                />
                              </GridCol>
                            </Grid>
                          </GridCol>
                        </GridCol>

                        <GridCol xs={6} md={6} lg={6} css="border-left:1px solid var(--light-gray);padding-left:15px">
                          <FieldWithHolder>
                            <Switch
                              sm
                              name="transaction_settings.tip_configuration.dollar_tip_amount.value"
                              type="checkbox"
                              label="Dollar Tip Amount"
                              noMargin
                              value={state?.transaction_settings.tip_configuration.dollar_tip_amount?.value}
                              onChange={handleChange}
                            />
                          </FieldWithHolder>
                          <GridCol xs={12} md={12} lg={12}>
                            <Grid
                              xs={12}
                              sm={12}
                              lg={12}
                              colGap={30}
                              rowGap={20}
                              css={`
                                padding: 15px;
                                overflow-x: hidden;
                                overflow-y: auto;
                                max-height: 500px;
                              `}>
                              <GridCol xs={6} md={6} lg={6}>
                                <Field
                                  sm
                                  name="transaction_settings.tip_configuration.dollar_tip_amount.amount_1.value"
                                  type="number"
                                  min={0}
                                  label="Dollar Amount 1"
                                  prefix={<span className="material-icons-outlined">attach_money</span>}
                                  value={
                                    state?.transaction_settings.tip_configuration.dollar_tip_amount.amount_1?.value
                                  }
                                  error={
                                    checkNumbersAndShowError(
                                      state?.transaction_settings.tip_configuration.dollar_tip_amount.amount_1.value,
                                    )
                                      ? false
                                      : 'Not Valid Input'
                                  }
                                  onChange={handleChange}
                                />
                              </GridCol>
                              <GridCol xs={6} md={6} lg={6}>
                                <Field
                                  sm
                                  name="transaction_settings.tip_configuration.dollar_tip_amount.amount_2.value"
                                  type="number"
                                  min={0}
                                  label="Dollar Amount 2"
                                  prefix={<span className="material-icons-outlined">attach_money</span>}
                                  value={
                                    state?.transaction_settings.tip_configuration.dollar_tip_amount.amount_2?.value
                                  }
                                  error={
                                    checkNumbersAndShowError(
                                      state?.transaction_settings.tip_configuration.dollar_tip_amount.amount_2.value,
                                    )
                                      ? false
                                      : 'Not Valid Input'
                                  }
                                  onChange={handleChange}
                                />
                              </GridCol>
                              <GridCol xs={12} md={12} lg={12}>
                                <Field
                                  sm
                                  name="transaction_settings.tip_configuration.dollar_tip_amount.amount_3.value"
                                  type="number"
                                  min={0}
                                  label="Dollar Amount 3"
                                  prefix={<span className="material-icons-outlined">attach_money</span>}
                                  value={
                                    state?.transaction_settings.tip_configuration.dollar_tip_amount.amount_3?.value
                                  }
                                  error={
                                    checkNumbersAndShowError(
                                      state?.transaction_settings.tip_configuration.dollar_tip_amount.amount_3.value,
                                    )
                                      ? false
                                      : 'Not Valid Input'
                                  }
                                  onChange={handleChange}
                                />
                              </GridCol>
                            </Grid>
                          </GridCol>
                        </GridCol>
                      </Grid>
                    </GridCol>
                  </Grid>
                </>
              ),
            },

            {
              label: 'Surcharge',
              content: (
                <>
                  <VisibleLock
                    title="Surcharge"
                    onChange={handleChange}
                    state={state?.transaction_settings.surcharge}
                    section="transaction_settings.surcharge"
                    lock
                    visible
                  />

                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={6} lg={6}>
                      <Switch
                        sm
                        name="transaction_settings.surcharge.debit_surcharge.value"
                        label="Debit Surcharge"
                        value={state?.transaction_settings.surcharge.debit_surcharge?.value}
                        onChange={handleChange}
                      />
                      <Field
                        sm
                        name="transaction_settings.surcharge.debit_surcharge.fee"
                        type="number"
                        min={0}
                        label="Debit Surcharge Fee"
                        value={state?.transaction_settings.surcharge.debit_surcharge.fee}
                        error={
                          checkNumbersAndShowError(state?.transaction_settings.surcharge.debit_surcharge.fee)
                            ? false
                            : 'Not Valid Input'
                        }
                        onChange={handleChange}
                      />
                      <Field
                        sm
                        name="transaction_settings.surcharge.debit_surcharge.limit"
                        type="number"
                        min={0}
                        label="Debit Surcharge Limit"
                        value={state?.transaction_settings.surcharge.debit_surcharge.limit}
                        error={
                          checkNumbersAndShowError(state?.transaction_settings.surcharge.debit_surcharge.limit)
                            ? false
                            : 'Not Valid Input'
                        }
                        onChange={handleChange}
                      />
                    </GridCol>
                    <GridCol xs={12} md={6} lg={6}>
                      <Switch
                        sm
                        name="transaction_settings.surcharge.credit_surcharge.value"
                        type="checkbox"
                        label="Credit Surcharge"
                        value={state?.transaction_settings.surcharge.credit_surcharge?.value}
                        onChange={handleChange}
                      />
                      <Field
                        sm
                        name="transaction_settings.surcharge.credit_surcharge.fee"
                        type="number"
                        min={0}
                        label="Credit Surcharge Fee"
                        value={state?.transaction_settings.surcharge.credit_surcharge.fee}
                        error={
                          checkNumbersAndShowError(state?.transaction_settings.surcharge.credit_surcharge.fee)
                            ? false
                            : 'Not Valid Input'
                        }
                        onChange={handleChange}
                      />
                    </GridCol>
                  </Grid>
                </>
              ),
            },
            {
              label: 'Invoice Number',
              content: (
                <>
                  <VisibleLock
                    title="Invoice Number"
                    onChange={handleChange}
                    state={state?.transaction_settings.invoice_number}
                    section="transaction_settings.invoice_number"
                    lock
                    visible
                  />

                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={6} md={6} lg={6}>
                      <Field
                        sm
                        name="transaction_settings.invoice_number.auto_increment.value"
                        type="radio"
                        label="Auto Increment"
                        value={state?.transaction_settings.invoice_number.auto_increment?.value}
                        onChange={event => {
                          handleChange(event);
                          handleChange({
                            target: {
                              name: 'transaction_settings.invoice_number.manual_entry.value',
                              value: false,
                            },
                          });
                        }}
                      />
                    </GridCol>
                    <GridCol xs={6} md={6} lg={6}>
                      <Field
                        sm
                        name="transaction_settings.invoice_number.manual_entry.value"
                        type="radio"
                        label="Manual Entry"
                        value={state?.transaction_settings.invoice_number.manual_entry?.value}
                        onChange={event => {
                          handleChange(event);
                          handleChange({
                            target: {
                              name: 'transaction_settings.invoice_number.auto_increment.value',
                              value: false,
                            },
                          });
                        }}
                      />
                    </GridCol>
                  </Grid>
                </>
              ),
            },
            {
              label: 'Tap Limit',
              content: (
                <>
                  <PermissionListHead>Tap Limit</PermissionListHead>
                  <Grid
                    xs={12}
                    sm={12}
                    lg={12}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={6} lg={6}>
                      <Field
                        sm
                        name="transaction_settings.tap_limit.fee"
                        type="number"
                        min={0}
                        label="Card Tap Limit (maximum)"
                        prefix={<span className="material-icons-outlined">attach_money</span>}
                        value={state?.transaction_settings.tap_limit.fee}
                        error={
                          checkNumbersAndShowError(state?.transaction_settings.tap_limit.fee)
                            ? false
                            : 'Not Valid Input'
                        }
                        onChange={handleChange}
                      />
                    </GridCol>
                    <GridCol xs={12} md={6} lg={6}>
                      <Field
                        sm
                        name="transaction_settings.tap_count.fee"
                        type="text"
                        label="Tap Count"
                        value={state?.transaction_settings.tap_count.fee}
                        error={
                          checkNumbersAndShowError(state?.transaction_settings.tap_count.fee)
                            ? false
                            : 'Not Valid Input'
                        }
                        onChange={handleChange}
                      />
                    </GridCol>
                  </Grid>
                </>
              ),
            },
          ]}
          orientation={MaxWidth991 ? 'horizontal' : 'vertical'}
        />
      </PermissionListGroup>
    ),
    [JSON.stringify(state?.transaction_settings)],
  );
  const pos_settings = useMemo(
    () => (
      <PermissionListGroup>
        <VisibleLock title="POS Settings" />

        <DataTabs
          data={[
            {
              label: 'Terminal',
              content: (
                <>
                  <VisibleLock title="Terminal" />
                  <Grid
                    xs={1}
                    sm={2}
                    lg={3}
                    colGap={30}
                    rowGap={20}
                    css={`
                      padding: 15px;
                      overflow-x: hidden;
                      overflow-y: auto;
                      max-height: 500px;
                    `}>
                    <GridCol xs={12} md={6} lg={6}>
                      <span
                        css={`
                          display: flex;
                          justify-content: space-between;
                        `}>
                        <Switch
                          noMargin
                          label="POS Terminal"
                          name="pos.pos_terminal.value"
                          value={state?.pos?.pos_terminal?.value}
                          onChange={handleChange}
                        />
                      </span>
                    </GridCol>
                  </Grid>
                </>
              ),
            },
          ]}
          orientation={MaxWidth991 ? 'horizontal' : 'vertical'}
        />
      </PermissionListGroup>
    ),
    [JSON.stringify(state?.pos)],
  );

  const data = useMemo(
    () => [
      {
        label: 'Terminal Configuration',
        content: terminal_configuration,
      },
      {
        label: 'Transaction Settings',
        content: transaction_settings,
      },
      {
        label: 'Printer',
        content: printer,
      },
      {
        label: 'Screen Time/Counter',
        content: screen_time,
      },
      {
        label: 'POS Settings',
        content: pos_settings,
      },
    ],
    [state],
  );

  const renderText = text => {
    const str = text.endsWith('_v') ? `${text}isibility` : text.endsWith('_l') ? `${text}ocked` : text;
    return str
      .split('.')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' => ');
  };

  return (
    <Wrapper>
      <Loaders loading={loading}>
        <DataTabs data={data} horizontalTabs />
        <BtnHolder>
          {edit && (
            <ConfirmationModal
              btnComponent={({ onClick }) => (
                <Button
                  type="outline"
                  md
                  width="150"
                  onClick={() => {
                    if (Object.keys(touchedfields).length > 0) {
                      onClick();
                    } else {
                      onClose();
                    }
                  }}>
                  Cancel
                </Button>
              )}
              confirmationModal="Yes"
              title="Are you sure you want to exit?"
              onOk={onClose}
            />
          )}
          <ConfirmationModal
            okLoading={loading}
            btnComponent={({ onClick }) => (
              <Button
                disabled={Object.keys(touchedfields).length < 1 || timeError}
                type="primary"
                md
                width="150"
                onClick={() => {
                  if (Object.keys(touchedfields).length > 0 && isReadyToClick) {
                    onClick();
                  } else {
                    Toast({
                      type: 'error',
                      message: 'Please Validate you inputs',
                    });
                  }
                }}>
                Confirm
              </Button>
            )}
            confirmationModal="Yes"
            title="Are you sure you want to save changes?"
            subtitle="Changed Fields:"
            onOk={onDone}>
            <ul css="list-style: inside;margin-top:15px">
              {Object.keys(touchedfields).map((item, index) => (
                <li key={index} css="margin:10px 0px">
                  {renderText(item)}
                </li>
              ))}
            </ul>
          </ConfirmationModal>
        </BtnHolder>
      </Loaders>
    </Wrapper>
  );
}
