import React, { useState } from 'react';
import 'styled-components/macro';
import Form from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import GridCol from 'components/atoms/GridCol';
import Grid from 'components/atoms/Grid';
import Button from 'components/atoms/Button';
import { capitalize } from 'helpers/common';
import Select from 'components/atoms/Select';

const each_owner = {
  first_name: '',
  last_name: '',
  identification_number: '',
  date_of_birth: '',
  'street_address.address_line_1': '',
  'street_address.city_name': '',
  'street_address.region_code': '',
  'street_address.postal_code': '',
};
const initialOwner = {
  significant_owners: { first_owner: each_owner },
};
const names_index = {
  0: 'first_owner',
  1: 'second_owner',
  2: 'third_owner',
  3: 'fourth_owner',
};
const canada_state = [
  { label: 'Alberta', value: 'AB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'New Brunswick', value: 'NB' },
  { label: 'Newfoundland and Labrador', value: 'NL' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Yukon', value: 'YT' },
];
function GenericForm({ state, updateForm, form }) {
  const [owners, setOwners] = useState(state.significant_owners || initialOwner.significant_owners);

  const handleAddOwner = () => {
    if (Object.keys(owners).length < 4) {
      setOwners({ ...owners, [names_index[Object.keys(owners).length]]: each_owner });
      updateForm();
    }
  };
  const handleRemoveOwner = index => {
    const newOwners = { ...owners };
    delete newOwners[names_index[index]];
    setOwners(newOwners);
    updateForm();
  };

  return (
    <div
      css={`
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
      `}>
      <h3
        css={`
          margin-top: 5px;
          margin-bottom: -25px;
        `}>
        Significant Owners
      </h3>
      <div
        css={`
          padding: 20px;
          border-radius: 8px;
          gap: 20px;
          row-gap: 20px;
          display: flex;
          flex-direction: column;
        `}>
        {Object.entries(owners).map(([owner, _state], index) => (
          <Grid
            css={`
              border: 1px solid #ccc;
              padding: 20px;
              border-radius: 8px;
            `}
            xs={12}
            sm={12}
            lg={12}
            colGap={30}
            rowGap={20}>
            <GridCol
              xs={12}
              md={12}
              lg={12}
              css={`
                display: flex;
                justify-content: center;
              `}>
              <h3>{capitalize(owner.replace('_', ' '))}</h3>
            </GridCol>
            {Object.entries(_state).map(([name]) => (
              <GridCol xs={12} md={6} lg={6}>
                {name === 'date_of_birth' ? (
                  <Form.Item
                    label="Date of Birth"
                    name={`significant_owners.${owner}.date_of_birth`}
                    value={state[`significant_owners.${owner}.date_of_birth`]}
                    onChange={({ target: { value, name: _ } }) => {
                      form.setFieldsValue({
                        [_]: value.toISOString().split('T')[0].replace(/-/g, '/'),
                      });
                    }}
                    prefix={<i className="material-icons-outlined">date_range</i>}
                    type="datepicker"
                    datePicker
                    sm
                    rules={[
                      {
                        required: true,
                        message: 'Please select a date',
                      },
                    ]}>
                    <Field />
                  </Form.Item>
                ) : name === 'street_address.region_code' ? (
                  <Form.Item
                    label="Region"
                    name={`significant_owners.${owner}.street_address.region_code`}
                    rules={[
                      {
                        required: true,
                        message: 'Please select a region',
                      },
                    ]}
                    options={canada_state}>
                    <Select />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={capitalize(name.split('.').pop().replace(/_/g, ' '))}
                    name={`significant_owners.${owner}.${name}`}
                    rules={[
                      {
                        required: true,
                        message: `Please input the ${name.split('.').pop().replace(/_/g, ' ')}`,
                      },
                    ]}>
                    <Field />
                  </Form.Item>
                )}
              </GridCol>
            ))}
            {index !== 0 && (
              <GridCol
                xs={12}
                md={12}
                lg={12}
                css={`
                  display: flex;
                  justify-content: center;
                `}>
                <Button type="primary" onClick={() => handleRemoveOwner(index)}>
                  Remove Owner
                </Button>
              </GridCol>
            )}
          </Grid>
        ))}
      </div>
      {Object.keys(owners).length < 4 && (
        <Button
          sm
          css={`
            margin: 20px;
            width: 200px;
          `}
          type="primary"
          onClick={handleAddOwner}>
          Add Owner
        </Button>
      )}
    </div>
  );
}
export function AuthorizedSigner({ state, form }) {
  return (
    <div
      css={`
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
      `}>
      <h3
        css={`
          margin-top: 5px;
          margin-bottom: -25px;
        `}>
        Authorized Signer
      </h3>
      <div
        css={`
          padding: 20px;
          border-radius: 8px;
          gap: 20px;
          row-gap: 20px;
          display: flex;
          flex-direction: column;
        `}>
        <Grid
          css={`
            border: 1px solid #ccc;
            padding: 20px;
            border-radius: 8px;
          `}
          xs={12}
          sm={12}
          lg={12}
          colGap={30}
          rowGap={20}>
          {Object.entries(each_owner).map(([name]) => (
            <GridCol xs={12} md={6} lg={6}>
              {name === 'date_of_birth' ? (
                <Form.Item
                  label="Date of Birth"
                  name="authorized_signer.date_of_birth"
                  value={state[`authorized_signer.date_of_birth`]}
                  onChange={({ target: { value, name: _ } }) => {
                    form.setFieldsValue({
                      [_]: value.toISOString().split('T')[0].replace(/-/g, '/'),
                    });
                  }}
                  prefix={<i className="material-icons-outlined">date_range</i>}
                  type="datepicker"
                  datePicker
                  sm
                  rules={[
                    {
                      required: true,
                      message: 'Please select a date',
                    },
                  ]}>
                  <Field />
                </Form.Item>
              ) : name === 'street_address.region_code' ? (
                <Form.Item
                  label="Region"
                  name="authorized_signer.street_address.region_code"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a region',
                    },
                  ]}
                  options={canada_state}>
                  <Select />
                </Form.Item>
              ) : (
                <Form.Item
                  label={capitalize(name.split('.').pop().replace(/_/g, ' '))}
                  name={`authorized_signer.${name}`}
                  rules={[
                    {
                      required: true,
                      message: `Please input the ${name.split('.').pop().replace(/_/g, ' ')}`,
                    },
                  ]}>
                  <Field />
                </Form.Item>
              )}
            </GridCol>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default GenericForm;
