import { useState, useMemo } from 'react';
import TableLayout from 'components/atoms/TableLayout';
import { AuthContext } from 'context/authContext';
import Table from 'components/molecules/Table';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import Button from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import { ActionBtnHolder } from 'styles/helpers.styles';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import commonService from 'services/commonService';

export default function TagList() {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 10,
    searchText: '',
    startDate: '',
    endDate: '',
  });

  const { refetch, hasPermission, fetch } = useContextHook(AuthContext, ['refetch', 'hasPermission', 'fetch']);

  const onDeleteTag = async id => {
    try {
      setLoading(true);
      const { message, success } = await commonService.deleteTag(id);
      if (success) {
        refetch();
        Toast({
          message,
          type: 'success',
        });
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
    setLoading(false);
  };

  const actionBtns = _ => (
    <ActionBtnHolder numOfBtns={4}>
      {hasPermission('tag-templates.delete') && (
        <ConfirmationModal
          title="Are you sure you want to delete this record?"
          subtitle="you can't undo this action"
          deleteModal
          okLoading={loading}
          onOk={() => onDeleteTag(_._id)}
          btnComponent={({ onClick }) => (
            <Tooltip title="Delete" type="dark">
              <Button unStyled size={20} className="delete-btn" onClick={onClick}>
                <i className="material-icons-outlined">delete_forever</i>
              </Button>
            </Tooltip>
          )}
        />
      )}
    </ActionBtnHolder>
  );

  const { tag_data_loading, tag_data } = commonService.GetTagsList(searchQuery, fetch);

  const { totalCount, tag_data_rown } = useMemo(
    () => ({
      tag_data_rown: tag_data?.tags?.map(_ => [_?.tag_name ?? '------------', _?.tag ?? '------------', actionBtns(_)]),
      totalCount: tag_data.totalItems,
    }),
    [tag_data],
  );

  const columnNames = ['Title', 'tag', ''];

  return (
    <TableLayout
      onChangeFilters={filters => {
        setSearchQuery(_ => ({
          ..._,
          ...filters,
        }));
      }}
      currentPage={searchQuery.page}
      totalCount={totalCount}
      pageSize={searchQuery.pageSize}>
      <Table width={1200} loading={tag_data_loading} rowsData={tag_data_rown} columnNames={columnNames} noPadding />
    </TableLayout>
  );
}
