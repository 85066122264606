import React, { useState } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import { useContextHook } from 'use-context-hook';
import commonService from 'services/commonService';
import Select from 'components/atoms/Select';

function TagFrom({ closeModal }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const onSubmit = async values => {
    setLoading(true);
    try {
      const payload = values.tag.map(element => ({
        tag: element.value.split('=')[0].trim(),
        title: element.value.split('=')[1].trim(),
      }));

      await commonService.createTag(payload);

      refetch();
      closeModal();
      setLoading(false);
      Toast({
        type: 'success',
        message: 'Tag Created!',
      });
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  const searchPredefinedTags = async text => {
    try {
      let options = null;

      const response = await commonService.searchPredefinedTags({
        searchText: text,
      });

      options = response?.data?.map(_ => ({
        value: `${_?.tag}=${_?.name}`,
        label: `${_?.tag} = ${_?.name}`,
      }));

      return options;
    } catch {
      return [];
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Grid xs={1} css="margin:15px 0px;align-items:center" gap={10}>
        <Form.Item
          sm
          type="text"
          label="Tag"
          name="tag"
          placeholder="Tag"
          rules={[{ required: true, message: 'Tag Selection is required' }]}>
          <Select
            sm
            open
            async
            isMulti
            closeMenuOnSelect={false}
            defaultOptions
            filterOption={false}
            loadOptions={searchPredefinedTags}
          />
        </Form.Item>
      </Grid>
      <Button type="primary" htmlType="submit" loading={loading}>
        Confirm
      </Button>
    </Form>
  );
}

export default TagFrom;
