import React, { useState } from 'react';
import MenuButton, { MenuItem } from 'components/molecules/Menu/index';
import { AuthContext } from 'context/authContext';
import ChangeStatusForm from 'components/organisms/ChangeStatusForm';
import ModalContainer from 'components/molecules/ModalContainer';
import Toast from 'components/molecules/Toast';
import userService from 'services/userService';
import Loaders from 'components/atoms/Loaders';
import { useContextHook } from 'use-context-hook';
import UserCreationForm from '../UserCreationForm';
import CaptchaComponent from '../CaptchaComponent';

function OwnerActions({ _, totalCount }) {
  const [loading, setLoading] = useState(false);
  const { refetch, hasPermission } = useContextHook(AuthContext, ['refetch', 'hasPermission']);

  const onDeleteOwner = async id => {
    try {
      setLoading(true);
      const { success, message } = await userService.deleteUser(id);

      if (success) {
        Toast({
          type: 'success',
          message,
        });
        refetch();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  return (
    <MenuButton icon={<span className="material-icons-outlined">more_vert</span>}>
      {hasPermission('owner.update-owner') && _.status === 'Active' && (
        <ModalContainer
          lg
          title="Update Owner"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<span className="material-icons-outlined">edit</span>}>
              Update Owner
            </MenuItem>
          )}
          content={({ onClose }) => <UserCreationForm onClose={onClose} user={_} edit storeId={_.store_id} />}
        />
      )}

      {hasPermission('owner.change-owner-status') && (
        <ModalContainer
          lg
          title="Change Status"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">update</i>}>
              Change Status
            </MenuItem>
          )}
          content={({ onClose }) => (
            <ChangeStatusForm onClose={onClose} id={_._id} totalOwnerCount={totalCount} owner status={_.status} />
          )}
        />
      )}

      {hasPermission('owner.delete-owner') && (
        <ModalContainer
          lg
          title={`Are you sure you want to deactivate ${_?.type}?`}
          btnComponent={({ onClick }) => (
            <MenuItem
              className="delete-btn"
              icon={<span className="material-icons-outlined">delete_forever</span>}
              onClick={onClick}>
              Deactivate Owner
            </MenuItem>
          )}
          content={({ onClose }) => (
            <Loaders loading={loading}>
              <CaptchaComponent
                onClose={onClose}
                onDelete={() => onDeleteOwner(_._id)}
                type={_?.type}
                email={_?.email}
              />
            </Loaders>
          )}
        />
      )}
    </MenuButton>
  );
}

export default OwnerActions;
