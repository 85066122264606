import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const userService = {
  _url: `${process.env.REACT_APP_USER_API_URL}`,

  GetUsers(searchQuery, refetch, refreshUsers) {
    const [users, setUsers] = useState({
      users: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getUsers(searchQuery))
        .then(res => {
          setUsers(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.filterStatus,
      searchQuery?.storeId,
      searchQuery?.filterType,
      searchQuery?.getDeleted,
      searchQuery?.group,
      searchQuery?.groupPage,
      searchQuery?.getStoreInfo,
      refetch,
      refreshUsers,
    ]);
    return {
      users_loading: status === STATUS.LOADING,
      users_error: status === STATUS.ERROR ? status : '',
      users_data: users,
    };
  },

  async getUsers({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterStatus = '',
    storeId = '',
    filterType = '',
    group = '',
    groupPage = false,
    getDeleted = '',
    getStoreInfo = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-users?page=${page}&itemsPerPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&status=${filterStatus}&storeId=${storeId}&type=${filterType}&group=${group}&groupPage=${groupPage}&getDeleted=${getDeleted}&getStoreInfo=${getStoreInfo}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return {
        users: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createUser(payload) {
    let res = await Fetch.post(`${this._url}/create-user`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteUser(id) {
    let res = await Fetch.delete(`${this._url}/delete-user/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updatePassword({ _id, ...rest }) {
    let res = await Fetch.put(`${this._url}/update-password/${_id}`, { _id, ...rest });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async changeUserStatus(id, status) {
    let res = await Fetch.put(`${this._url}/change-user-status/${id}`, { status });

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async transferUser(id, new_store_id) {
    let res = await Fetch.patch(`${this._url}/transfer-user/${id}`, { new_store_id });

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updateUser(id, payload) {
    let res = await Fetch.put(`${this._url}/update-user/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async uploadCsv(payload) {
    let res = await Fetch.put(`${this._url}/upload-csv`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};

export default userService;
