import styled from 'styled-components/macro';

export const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  border: 2px solid var(--light);
  background: var(--white);
  outline: none;
  height: 40px;
  padding: 0.3125rem 0.9375rem;
  width: 100%;
  -webkit-transition: border var(--animation-speed) ease-in-out;
  transition: border var(--animation-speed) ease-in-out;
  color: var(--secondary-text-color);
  font-size: var(--font-size-sm);
  border-radius: 6px;
  resize: vertical;
  min-height: 9.375rem;
  margin-bottom: 5px;
`;
export const InputStyle = styled.input`
  width: 100%;
  height: 150px;
  border: 2px solid var(--light);
  background: var(--white);
  outline: none;
  height: 40px;
  padding: 0.3125rem 0.9375rem;
  width: 100%;
  -webkit-transition: border var(--animation-speed) ease-in-out;
  transition: border var(--animation-speed) ease-in-out;
  color: var(--secondary-text-color);
  font-size: var(--font-size-sm);
  border-radius: 6px;
  resize: vertical;
  /* min-height: 9.375rem; */
  margin-bottom: 5px;
`;
export const CertLabel = styled.label`
  display: block;
  margin-bottom: 5px;

  .required {
    color: var(--danger);
  }
`;
export const InputWrap = styled.div`
  position: relative;
  margin-bottom: 20px;
`;
