import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Grid from 'components/atoms/Grid';
import Button from 'components/atoms/Button';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import deviceService from 'services/deviceService';
import Select from 'components/atoms/Select';
import configurationService from 'services/configurationService';
import Switch from 'components/atoms/Switch';
import ModalContainer from 'components/molecules/ModalContainer';
import defaultConfigurations from 'helpers/schema-tms-config.json';
import { capitalize } from 'helpers/common';
import storeService from 'services/storeService';
import deviceGroupsService from 'services/deviceGroupsService';
import { useContextHook } from 'use-context-hook';
import ConfigurationForm from '../ConfigurationForm';

function DeviceForm({ onClose, edit, device, storeId = '' }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [choice, setChoice] = useState(false);
  const [templateConfigurations, setTemplateConfigurations] = useState(defaultConfigurations);

  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const handleConfigTemplateSearch = async text => {
    try {
      const response = await configurationService.getConfigurationTemplates({
        page: 1,
        pageSize: 1000,
        searchText: text,
        startDate: '',
        endDate: '',
      });

      const options = response?.items?.map(_ => ({
        value: _?._id,
        label: `Title: ${capitalize(_?.title)}`,
      }));

      return options;
    } catch {
      return [];
    }
  };

  const handleStoresSearch = async text => {
    try {
      const response = await storeService.getStores({
        page: 1,
        pageSize: 5,
        searchText: text,
        startDate: '',
        endDate: '',
        getDeleted: false,
      });

      const options = response?.stores?.map(_ => ({
        value: _?._id,
        label: `Title: ${capitalize(_?.title)}`,
      }));

      return options;
    } catch {
      return [];
    }
  };

  const handleDeviceGroupsSearch = async text => {
    try {
      const response = await deviceGroupsService.getDeviceGroups({
        page: 1,
        pageSize: 100,
        searchText: text,
        startDate: '',
        endDate: '',
        storeId,
      });

      const options = response?.groups?.map(_ => ({
        value: _?._id,
        label: `Name: ${capitalize(_?.name)}`,
      }));

      return options;
    } catch {
      return [];
    }
  };

  const onSubmit = async values => {
    try {
      setLoading(true);
      let response;
      let deviceData = {};

      if (!edit) {
        deviceData = {
          ...values,
          store_id: storeId === '' ? values?.store?.value : storeId,
          logo: templateConfigurations.logo ? templateConfigurations.logo : '',
        };
        // eslint-disable-next-line no-unused-expressions
        values?.template
          ? (deviceData.template_id = values?.template?.value)
          : (deviceData.configuration = templateConfigurations.state
              ? templateConfigurations.state
              : templateConfigurations);

        delete deviceData.template;
      } else {
        deviceData = {
          ...values,
          id: device._id,
        };
      }

      delete deviceData.user;

      if (values?.group) {
        deviceData.group_id = values.group.value;
      }

      if (edit) {
        response = await deviceService.editDevice(deviceData);
      }

      if (!edit) {
        response = await deviceService.createDevice(deviceData);
      }

      if (response.success) {
        onClose();
        refetch();
        Toast({
          type: 'success',
          message: response.message,
        });
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (edit) {
      const data = {
        device_id: device.device_id,
        title: device.title,
        serial_number: device.serial_number,
      };

      if (device?.group_id?.length > 0) {
        data.group = device?.group_id?.map(_ => ({ value: _?._id, label: `Name: ${capitalize(_?.name)}` }));
      }

      form.setFieldsValue({
        ...data,
      });
    }
  }, [device]);

  return (
    <Form
      form={form}
      css={`
        margin-top: 20px;
      `}
      onTouched={() => {
        const _ = form.getFieldValue('user');
        if (Array.isArray(_) && _.length < 1) {
          form.setFieldsValue({ user: false });
        }
      }}
      onSubmit={onSubmit}>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          sm
          type="text"
          label="Device Name"
          name="title"
          placeholder="Enter Name Of Device"
          rules={[
            {
              pattern: /^.{3,}$/,
              message: 'Name must be minimum 3 characters.',
            },
            {
              pattern: /^.{3,40}$/,
              message: 'Name should be maximum 40 characters',
            },
          ]}>
          <Field />
        </Form.Item>
        {edit && (
          <Form.Item sm type="text" label="Device Id" name="device_id" placeholder="Enter Device Id" disabled>
            <Field disabled />
          </Form.Item>
        )}
        <Form.Item
          sm
          type="number"
          label="Serial Number"
          name="serial_number"
          placeholder="Enter Serial Number Of Device"
          disabled={edit}
          rules={[
            {
              pattern: /^\d{10}$/,
              message: 'Serial Number Must Be 10 Digits Long',
            },
            {
              required: true,
              message: 'Serial Number is required',
            },
          ]}>
          <Field />
        </Form.Item>
        {!edit && (
          <Form.Item
            sm
            type="text"
            label="TID"
            name="tid"
            placeholder="Enter TID Of Device"
            rules={[
              {
                required: true,
                message: 'TID is required',
              },
            ]}>
            <Field />
          </Form.Item>
        )}

        {storeId !== '' && !edit && (
          <Form.Item name="group" label="Select Group">
            <Select
              sm
              open
              async
              closeMenuOnSelect
              defaultOptions
              filterOption={false}
              loadOptions={handleDeviceGroupsSearch}
            />
          </Form.Item>
        )}
        {!edit && (
          <>
            <Switch
              label="Select Predefined Configuration"
              name="choice"
              value={choice}
              onChange={({ target: { value } }) => setChoice(value)}
            />
            {choice ? (
              <Form.Item
                name="template"
                rules={[{ required: true, message: 'Please Select Configuration Template' }]}
                label="Select Configuration Template">
                <Select sm open async defaultOptions filterOption={false} loadOptions={handleConfigTemplateSearch} />
              </Form.Item>
            ) : (
              <ModalContainer
                width={1800}
                title="Create Configuration"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    prefix={<i className="material-icons-outlined">add</i>}
                    css="margin:10px 0px">
                    <span className="text">Create Configuration</span>
                  </Button>
                )}
                content={({ onClose: closeModal }) => (
                  <ConfigurationForm
                    onClose={closeModal}
                    configuration={templateConfigurations}
                    getConfig={config => setTemplateConfigurations(config)}
                  />
                )}
              />
            )}
          </>
        )}
      </Grid>

      {storeId === '' && (
        <Form.Item name="store" rules={[{ required: true, message: 'Please Select Store' }]} label="Select Store">
          <Select
            sm
            open
            async
            closeMenuOnSelect={false}
            defaultOptions
            filterOption={false}
            loadOptions={handleStoresSearch}
          />
        </Form.Item>
      )}

      <Button loading={loading} type="primary" htmlType="submit">
        {edit ? 'Update' : 'Save'}
      </Button>
    </Form>
  );
}

export default DeviceForm;
