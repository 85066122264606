import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import { AuthContext } from 'context/authContext';
import Select from 'components/atoms/Select';
import Loaders from 'components/atoms/Loaders';
import Button from 'components/atoms/Button';
import Toast from 'components/molecules/Toast';
import businessService from 'services/businessService';
import storeService from 'services/storeService';
import deviceService from 'services/deviceService';
import userService from 'services/userService';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import ModalContainer from 'components/molecules/ModalContainer';
import { useContextHook } from 'use-context-hook';
import UnBlockStoresDevicesUsers from '../UnBlockStoresDevicesUsers';

function ChangeStatusForm({
  onClose,
  id,
  business,
  store,
  device,
  user,
  manager,
  owner,
  status,
  totalOwnerCount,
  device_api_key,
}) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const [state, setState] = useState('');
  const [storesDevicesUsers, setStoresDevicesUsers] = useState([]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      let response;

      if (owner && totalOwnerCount === 1 && state !== 'Active') {
        throw new Error('status of single owner cannot be changed');
      }

      if (device && status === 'PendingActivation' && state === 'Active') {
        throw new Error('device cannot be activated from TMS');
      }

      if (business) {
        const data = {
          status: state,
          stores: storesDevicesUsers?.stores?.length > 0 ? storesDevicesUsers?.stores?.map(_ => _.value) : [],
          terminals: storesDevicesUsers?.devices?.length > 0 ? storesDevicesUsers?.devices?.map(_ => _.value) : [],
          users: storesDevicesUsers?.users?.length > 0 ? storesDevicesUsers?.users?.map(_ => _.value) : [],
        };

        response = await businessService.changeBusinessStatus(id, data);
      }

      if (store) {
        response = await storeService.changeStoreStatus(id, state);
      }

      if (device) {
        const payload = !device_api_key && status === 'Blocked' ? 'PendingActivation' : state;
        response = await deviceService.changeDeviceStatus(id, payload);
      }

      if (user || manager || owner) {
        response = await userService.changeUserStatus(id, state);
      }

      if (response.success) {
        Toast({
          type: 'success',
          message: response.message,
        });
        refetch();
        onClose();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (status && status !== '') {
      form.setFieldsValue({ status: { value: status, label: status } });
      setState(status);
    }
  }, []);

  const options = [
    {
      value: 'Active',
      label: 'Active',
    },
    {
      value: 'Blocked',
      label: 'Blocked',
    },
  ];

  return (
    <Loaders loading={loading}>
      <ConfirmationModal
        okLoading={loading}
        btnComponent={({ onClick }) => (
          <Form
            form={form}
            onSubmit={() => {
              onClick();
            }}
            css="margin-top:20px">
            <ModalContainer
              lg
              title="Unblock Stores And Devices"
              btnComponent={({ onClick: openModal }) => (
                <Form.Item
                  name="status"
                  options={user ? [...options, { value: 'Disable', label: 'Disable' }] : options}
                  placeholder="Select Status"
                  label="Status"
                  rules={[{ required: true, message: 'Kindly Select One Option' }]}
                  onChange={({ target: { value, name } }) => {
                    form.setFieldsValue({ [name]: value });
                    setState(value?.value);

                    if (business && value?.value === 'Active' && status !== 'Active') {
                      openModal();
                    }
                  }}>
                  <Select />
                </Form.Item>
              )}
              content={({ onClose: closeModal }) => (
                <UnBlockStoresDevicesUsers
                  onClose={closeModal}
                  businessId={id}
                  getStoresDevicesUsers={_ => {
                    setStoresDevicesUsers(_);
                  }}
                />
              )}
            />

            <Button htmlType="submit" type="primary">
              Apply
            </Button>
          </Form>
        )}
        title="Are you sure you want to perform this action?"
        confirmationModal="Yes"
        subtitle={
          state === 'Blocked' &&
          (business || store) &&
          `Status of ${business ? 'Stores, ' : ''}Devices and Users associated with this ${
            business ? 'Business' : 'Store'
          } will also be changed`
        }
        onOk={onSubmit}
      />
    </Loaders>
  );
}

export default ChangeStatusForm;
