import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Grid from 'components/atoms/Grid';
import businessService from 'services/businessService';
import Button from 'components/atoms/Button';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import { useContextHook } from 'use-context-hook';
import Switch from 'components/atoms/Switch';
import Select from 'components/atoms/Select';

import GridCol from 'components/atoms/GridCol';
import { deobjectify, objectify } from 'helpers/common';
import GoogleLocationSelector from '../GeolocationSelector';
import GenericForm, { AuthorizedSigner } from './OwnerForm';

function BusinessForm({ onClose, edit, business, setBusinessDetails }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const japan_credit_bureau_indicator_options = [
    {
      label: 'JCB acceptor and JCB was offered',
      value: 'Y',
    },
    {
      label: 'JCB acceptor and JCB was not offered',
      value: 'A',
    },
    {
      label: 'Does not accept JCB and JCB was not offered',
      value: 'N',
    },
    {
      label: 'Does not accept JCB and JCB was offered',
      value: 'O',
    },
  ];
  const marketing_indicator_options = [
    {
      label: '( Y ) All Marketing (Seller has not opted out of receiving marketing)',
      value: 'Y',
    },
    {
      label: '( N ) No Marketing (Seller has opted out of receiving marketing)',
      value: 'N',
    },
  ];

  const ownership_type_options = [
    {
      label: 'Corporation Private',
      value: 'D',
    },
    {
      label: 'Corporation Publicly-Traded',
      value: 'E',
    },
    {
      label: 'LLC Private',
      value: 'M',
    },
    {
      label: 'LLC Publicly-Traded',
      value: 'N',
    },
    {
      label: 'Partnership Private',
      value: 'Q',
    },
    {
      label: 'Partnership Publicly-Traded',
      value: 'R',
    },
    {
      label: 'Sole Proprietorship',
      value: 'S',
    },
    {
      label: 'Non-Profit',
      value: 'F',
    },
    {
      label: 'Government',
      value: 'G',
    },
    {
      label: 'American Express Reserved Indicator',
      value: 'X',
    },
  ];

  const seller_status_options = [
    {
      label: 'Active',
      value: '02',
    },
    {
      label: 'Reinstated',
      value: '03',
    },
    {
      label: 'Terminated',
      value: '04',
    },
    {
      label: 'Pending Approval',
      value: '05',
    },
    {
      label: 'Suspended',
      value: '07',
    },
    {
      label: 'Cancelled/Pay',
      value: '08',
    },
    {
      label: 'Cancelled/Suspended',
      value: '09',
    },
    {
      label: 'Non-Agreement',
      value: '00',
    },
  ];
  const se_detail_status_code_options = [
    { value: 'D', label: 'Canceled Derogatory' },
    { value: 'N', label: 'Canceled Non-Derogatory' },
    { value: 'R', label: 'Reinstatement of previously canceled Seller.' },
  ];
  const canada_state = [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland and Labrador', value: 'NL' },
    { label: 'Northwest Territories', value: 'NT' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Nunavut', value: 'NU' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
    { label: 'Yukon', value: 'YT' },
  ];
  const onSubmit = async values => {
    try {
      setLoading(true);
      let response;
      const { first_name, last_name, email, phone_number, mid, title, ...rest } = { ...values, ...state };

      const businessData = objectify({
        owner: {
          first_name: first_name.trim(),
          last_name: last_name.trim(),
          email: email.toLowerCase().trim(),
          phone_number: phone_number.trim(),
        },
        mid: mid.trim(),
        title: title.trim(),
        ...rest,
        _id: business?._id,
        is_giftcard: !!rest.is_giftcard,
      });

      if (edit) {
        response = await businessService.editBusiness(businessData);
      }

      if (!edit) {
        response = await businessService.createBusiness(businessData);
        setBusinessDetails(response.business);
      }

      if (response.success) {
        onClose();
        refetch();
        Toast({
          type: 'success',
          message: response.message,
        });
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (edit) {
      const { owner, ...rest } = business;
      if (!rest.merchant_details_id) {
        rest.merchant_details_id = {};
      }
      form.setFieldsValue({
        ...owner,
        ...rest,
      });
      setState({
        ...owner,
        ...rest,
      });
      if (rest?.is_amex) {
        setTimeout(() => {
          let data = deobjectify(rest?.merchant_details_id || '');
          data = {
            ...data,
            japan_credit_bureau_indicator: {
              value: data.japan_credit_bureau_indicator,
              label: japan_credit_bureau_indicator_options.find(_ => _.value === data.japan_credit_bureau_indicator)
                .label,
            },
            marketing_indicator: {
              value: data.marketing_indicator,
              label: marketing_indicator_options.find(_ => _.value === data.marketing_indicator).label,
            },
            ownership_type_indicator: {
              value: data.ownership_type_indicator,
              label: ownership_type_options.find(_ => _.value === data.ownership_type_indicator).label,
            },
            'seller.seller_status': {
              value: data['seller.seller_status'],
              label: seller_status_options.find(_ => _.value === data['seller.seller_status']).label,
            },
            'seller.seller_street_address.region_code': {
              value: data['seller.seller_street_address.region_code'],
              label: canada_state.find(_ => _.value === data['seller.seller_street_address.region_code']).label,
            },
            se_detail_status_code: {
              value: data.se_detail_status_code,
              label: se_detail_status_code_options.find(_ => _.value === data.se_detail_status_code).label,
            },
            'authorized_signer.street_address.region_code': {
              value: data['authorized_signer.street_address.region_code'],
              label: canada_state.find(_ => _.value === data['authorized_signer.street_address.region_code']).label,
            },
          };
          const names_index = {
            0: 'first_owner',
            1: 'second_owner',
            2: 'third_owner',
            3: 'fourth_owner',
          };
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < 4; i++) {
            if (data[`significant_owners.${names_index[i]}.street_address.region_code`]) {
              data[`significant_owners.${names_index[i]}.street_address.region_code`] = {
                value: data[`significant_owners.${names_index[i]}.street_address.region_code`],
                label: canada_state.find(
                  _ => _.value === data[`significant_owners.${names_index[i]}.street_address.region_code`],
                ).label,
              };
            }
          }
          form.setFieldsValue({
            ...data,
          });
          setState(_ => ({
            ..._,
            ...data,
          }));
        }, 100);
      }
    }
  }, [business]);

  useEffect(() => {
    if (!edit && state.is_amex) {
      setState(x => ({
        ...x,
        submitter_id: Date.now() + Math.floor(Math.random() * 10),
        'seller.seller_id': Date.now() + Math.floor(Math.random() * 10),
        'seller.seller_status': {
          value: '02',
          label: 'Active',
        },
        'seller.seller_currency_code': 'CAD',
      }));
      setTimeout(() => {
        form.setFieldsValue({
          submitter_id: Date.now() + Math.floor(Math.random() * 10),
          'seller.seller_id': Date.now() + Math.floor(Math.random() * 10),
          'seller.seller_status': {
            value: '02',
            label: 'Active',
          },
          'seller.seller_currency_code': 'CAD',
        });
      }, 1);
    }
  }, [state.is_amex]);

  const handleAddress = ({ target: { value } }) => {
    form.setFieldsValue({
      address: value,
    });
  };

  return (
    <Form
      form={form}
      css={`
        margin-top: 20px;
      `}
      onSubmit={onSubmit}
      onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid xs={12} sm={12} lg={12} colGap={30} rowGap={20}>
        <GridCol xs={12} md={6} lg={6}>
          <Form.Item
            sm
            type="text"
            label="Business Name"
            name="title"
            placeholder="Enter Business Name"
            rules={[
              { required: true, message: 'Please enter business name.' },
              {
                pattern: /^.{3,}$/,
                message: 'Business name must be minimum of 3 characters.',
              },
              {
                pattern: /^.{3,40}$/,
                message: 'Business name should be maximum of 40 characters.',
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Form.Item
            sm
            type="text"
            label="Business Address"
            name="address"
            placeholder="Address"
            // rules={[{ required: true, message: 'Please enter Address' }]}
            onChange={handleAddress}>
            <GoogleLocationSelector name="address" />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Form.Item
            sm
            type="text"
            label="Owner First Name"
            name="first_name"
            placeholder="Owner First Name"
            rules={[
              { required: true, message: 'Please enter owner first name' },
              {
                pattern: /^[a-zA-Z\s]+$/,
                message: 'First name should be Alphabets.',
              },
              {
                pattern: /^[a-zA-Z\s]{3,}$/,
                message: 'Owner first name must be minimum of 3 characters.',
              },
              {
                pattern: /^[a-zA-Z\s]{3,40}$/,
                message: 'Owner first name should be maximum of 40 characters.',
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Form.Item
            sm
            type="text"
            label="Owner Last Name"
            name="last_name"
            placeholder="Owner Last Name"
            rules={[
              { required: true, message: 'Please enter owner last name' },
              {
                pattern: /^[a-zA-Z\s]+$/,
                message: 'Last name should be Alphabets.',
              },
              {
                pattern: /^[a-zA-Z\s]{3,}$/,
                message: 'Owner last name must be minimum of 3 characters.',
              },
              {
                pattern: /^[a-zA-Z\s]{3,40}$/,
                message: 'Owner last name should be maximum of 40 characters.',
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Form.Item
            sm
            type="email"
            label="Owner Email"
            name="email"
            placeholder="Email"
            rules={[
              { required: true, message: 'Please enter owner email address.' },
              { email: true, message: 'Please enter a valid owner email address.' },
              { max: 40, message: 'Email should be at max 40 characters!' },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Form.Item
            sm
            type="text"
            label="Owner Phone Number"
            name="phone_number"
            placeholder="Enter Owner Phone Number"
            rules={[
              { required: true, message: 'Please enter owner phone number' },
              {
                pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: 'Enter Complete Owner Phone Number',
              },
              {
                changeRegex: 'phone_number',
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
        {!edit && (
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Business MID"
              name="mid"
              placeholder="Enter MID"
              maxlength="10"
              rules={[
                { required: true, message: 'Please enter business MID' },
                {
                  pattern: /^(?!0{10}$)(?!-)(?!.*-.{10})(?!.{11,}$)\d{10}$/,
                  message: 'Business MID must be of length 10 numbers and not all zero"s',
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>
        )}
        <GridCol xs={12} md={6} lg={6}>
          <Form.Item sm type="checkbox" label="AMEX ?" name="is_amex" disabled={edit}>
            <Switch />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={6} lg={6}>
          <Form.Item sm type="checkbox" label="GiftCards Enabled" name="is_giftcard">
            <Switch />
          </Form.Item>
        </GridCol>
        {state.is_amex && [
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item sm type="text" label="Submitter ID" name="submitter_id">
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Japan Credit bureau indicator"
              name="japan_credit_bureau_indicator"
              options={japan_credit_bureau_indicator_options}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select />
            </Form.Item>
            ,
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Marketing Indicator"
              name="marketing_indicator"
              rules={[{ required: true, message: 'Please select Marketing Indicator' }]}
              options={marketing_indicator_options}>
              <Select />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Ownership Type"
              name="ownership_type_indicator"
              options={ownership_type_options}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item sm type="text" label="seller ID" name="seller.seller_id">
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              value={state[`seller.seller_start_date`]}
              onChange={({ target: { value, name } }) => {
                form.setFieldsValue({
                  [name]: value.toISOString().split('T')[0].replace(/-/g, '/'),
                });
              }}
              prefix={<i className="material-icons-outlined">date_range</i>}
              type="datepicker"
              datePicker
              sm
              label="Seller Start Date"
              name="seller.seller_start_date">
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              value={state['seller.seller_term_date']}
              onChange={({ target: { value, name } }) => {
                form.setFieldsValue({
                  [name]: value.toISOString().split('T')[0].replace(/-/g, '/'),
                });
              }}
              prefix={<i className="material-icons-outlined">date_range</i>}
              type="datepicker"
              datePicker
              sm
              label="Seller Term Date"
              name="seller.seller_term_date">
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller MCC"
              name="seller.seller_mcc"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[0-9]{1,4}$/,
                  message: 'MCC must be of length max 4 numbers.',
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Legal Name"
              name="seller.seller_legal_name"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller DBA Name"
              name="seller.seller_dba_name"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Business Phone Number"
              name="seller.seller_business_phone_number"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Email Address"
              name="seller.seller_email_address"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item sm type="text" options={seller_status_options} label="Seller Status" name="seller.seller_status">
              <Select />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Currency Code"
              name="seller.seller_currency_code"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Detail Status Code"
              name="se_detail_status_code"
              options={se_detail_status_code_options}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              value={state.se_status_code_change_date}
              onChange={({ target: { value, name } }) => {
                form.setFieldsValue({
                  [name]: value.toISOString().split('T')[0].replace(/-/g, '/'),
                });
              }}
              prefix={<i className="material-icons-outlined">date_range</i>}
              type="datepicker"
              datePicker
              sm
              label="Seller Status Code Change date"
              name="se_status_code_change_date">
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Street Address"
              name="seller.seller_street_address.address_line_1"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller City Name"
              name="seller.seller_street_address.city_name"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Region Code"
              name="seller.seller_street_address.region_code"
              rules={[
                {
                  required: true,
                },
              ]}
              options={canada_state}>
              <Select />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Postal Code"
              name="seller.seller_street_address.postal_code"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={6} lg={6}>
            <Form.Item
              sm
              type="text"
              label="Seller Country Code"
              name="seller.seller_street_address.country_code"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>,
          <GridCol xs={12} md={12} lg={12}>
            <GenericForm
              state={state}
              form={form}
              updateForm={() => {
                console.log({ updating_form: 'updating_form' });
              }}
            />
          </GridCol>,
          <GridCol xs={12} md={12} lg={12}>
            <AuthorizedSigner
              state={state}
              form={form}
              updateForm={() => {
                console.log({ updating_form: 'updating_form' });
              }}
            />
          </GridCol>,
        ]}
      </Grid>

      <Button loading={loading} type="primary" htmlType="submit">
        {edit ? 'Update' : 'Save'}
      </Button>
    </Form>
  );
}

export default BusinessForm;
