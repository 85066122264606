import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const userGroupService = {
  _url: `${process.env.REACT_APP_USER_API_URL}`,

  GetUsersGroups(searchQuery, refetch) {
    const [userGroups, setUsersGroups] = useState({
      userGroups: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getUsersGroups(searchQuery))
        .then(res => {
          setUsersGroups(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getDeleted,
      searchQuery?.store_id,
      refetch,
    ]);
    return {
      users_groups_loading: status === STATUS.LOADING,
      users_groups_error: status === STATUS.ERROR ? status : '',
      users_groups_data: userGroups,
    };
  },

  GetUserGroupDetails(id) {
    const [groupDetails, setgroupDetails] = useState(null);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.userGroupDetail(id))
        .then(res => {
          setgroupDetails(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, []);
    return {
      group_details_loading: status === STATUS.LOADING,
      group_details_error: status === STATUS.ERROR ? status : '',
      group_details_data: groupDetails?.data,
    };
  },

  async getUsersGroups({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterRoles,
    startDate = '',
    endDate = '',
    getAll = false,
    update_device_group = false,
    device_group_id = '',
    getDeleted = '',
    store_id = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-users-groups?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&filterRoles=${filterRoles}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&update_device_group=${update_device_group}&device_group_id=${device_group_id}&store_id=${store_id}&getDeleted=${getDeleted}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        groups: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createUserGroup(payload) {
    let res = await Fetch.post(`${this._url}/create-user-group`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updateUserGroup(id, payload) {
    let res = await Fetch.put(`${this._url}/update-user-group/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteUserGroup(id) {
    let res = await Fetch.delete(`${this._url}/delete-user-group/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async userGroupDetail(id) {
    let res = await Fetch.get(`${this._url}/get-group-details/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default userGroupService;
