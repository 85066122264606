/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const commonService = {
  _url: `${process.env.REACT_APP_COMMON_API_URL}`,

  GetLogs(searchQuery) {
    const [logs, setLogs] = useState({
      totalItems: 0,
      logs: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getLogs(searchQuery))
        .then(res => {
          setLogs(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.storeId,
    ]);
    return {
      logs_loading: status === STATUS.LOADING,
      logs_error: status === STATUS.ERROR ? status : '',
      logs_data: logs,
    };
  },

  AllInOneSearch(searchQuery) {
    const [state, setState] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.allInOneSearch(searchQuery))
        .then(res => {
          setState(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery]);
    return {
      all_In_one_loading: status === STATUS.LOADING,
      all_In_one_error: status === STATUS.ERROR ? status : '',
      all_In_one_data: state,
    };
  },

  GetCertificates(searchQuery, fetch) {
    const [certificates, setCertificates] = useState({
      totalItems: 0,
      certificates: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getCertificates(searchQuery))
        .then(res => {
          setCertificates(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.filterStatus,
      fetch,
    ]);
    return {
      certificate_loading: status === STATUS.LOADING,
      certificate_error: status === STATUS.ERROR ? status : '',
      certificate_data: certificates,
    };
  },

  GetTagTemplates(searchQuery, fetch) {
    const [tagTemplates, setTagTemplates] = useState({
      totalItems: 0,
      tagTemplates: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getTagTemplates(searchQuery))
        .then(res => {
          setTagTemplates(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery?.page, searchQuery?.pageSize, searchQuery?.searchText, fetch]);
    return {
      tag_templates_loading: status === STATUS.LOADING,
      tag_templates_error: status === STATUS.ERROR ? status : '',
      tag_templates_data: tagTemplates,
    };
  },
  GetTagsList(searchQuery, fetch) {
    const [tags, setTags] = useState({
      totalItems: 0,
      tags: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getTags(searchQuery))
        .then(res => {
          setTags(() => ({
            totalItems: res.data.totalItems,
            tags: res.data.items,
          }));

          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery?.page, searchQuery?.pageSize, searchQuery?.searchText, fetch]);
    return {
      tag_loading: status === STATUS.LOADING,
      tag_error: status === STATUS.ERROR ? status : '',
      tag_data: tags,
    };
  },

  async getLogs({ page = 1, pageSize = 10, startDate = '', endDate = '', searchText = '', storeId = '' }) {
    let res = await Fetch.get(
      `${this._url}/device-logs?page=${page}&itemsPerPage=${pageSize}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&storeId=${storeId}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        logs: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getCertificates({ page = 1, pageSize = 10, startDate = '', endDate = '', searchText = '', filterStatus = '' }) {
    let res = await Fetch.get(
      `${this._url}/get-all-certificates?page=${page}&itemsPerPage=${pageSize}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&filterStatus=${filterStatus}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        certificates: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createCertificates(body) {
    let res = await Fetch.post(`${this._url}/create-certificate`, body);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async allInOneSearch(searchText) {
    let res = await Fetch.get(`${this._url}/all-in-one-search?searchText=${searchText}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async uploadImage(body) {
    let res = await Fetch.upload(`${this._url}/upload`, body);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteImage(file) {
    let res = await Fetch.delete(`${this._url}/remove-image`, file);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createTag(payload) {
    let res = await Fetch.post(`${this._url}/tag`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteTag(id) {
    let res = await Fetch.delete(`${this._url}/tag/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateTag(id, payload) {
    let res = await Fetch.put(`${this._url}/tag/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getTags({ page = 1, pageSize = 10, searchText = '' }) {
    let res = await Fetch.get(`${this._url}/tags?page=${page}&itemsPerPage=${pageSize}&searchText=${searchText}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getTagTemplates({ page = 1, pageSize = 10, searchText = '' }) {
    let res = await Fetch.get(
      `${this._url}/tag-template?page=${page}&itemsPerPage=${pageSize}&searchText=${searchText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return {
        tagTemplates: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteTagTemplate(id) {
    let res = await Fetch.delete(`${this._url}/tag-template/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createTagTemplate(payload) {
    let res = await Fetch.post(`${this._url}/tag-template`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateTagTemplate(id, payload) {
    let res = await Fetch.put(`${this._url}/tag-template/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getDeviceLocation(id) {
    let res = await Fetch.get(`${this._url}/get-location/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async searchPredefinedTags({ searchText = '' }) {
    let res = await Fetch.get(`${this._url}/search-validate-tag/?searchText=${searchText}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};

export default commonService;
