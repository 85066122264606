import React from 'react';
import { AuthContext } from 'context/authContext';
import { Routes, Route, Navigate } from 'react-router-dom';

import Login from 'pages/login';
import Development from 'pages/dev';
import { useContextHook } from 'use-context-hook';
import Pages from './pages/index';
// import Logs from './pages/logs';

function PublicRoute({ children, redirectTo, isLoggedIn }) {
  return isLoggedIn ? <Navigate to={redirectTo} /> : children;
}

function PrivateRoute({ children, redirectTo, isLoggedIn }) {
  return isLoggedIn ? children : <Navigate to={redirectTo} />;
}

function Router() {
  const { isLoggedIn, allowedPages } = useContextHook(AuthContext, ['isLoggedIn', 'allowedPages']);
  const redirectTo = allowedPages.length > 0 ? `/psp/${allowedPages[0]}` : 'psp/dashboard';
  return (
    <Routes>
      <Route
        path="/dev"
        element={
          <PrivateRoute
            redirectTo="/"
            isLoggedIn={isLoggedIn && process.env.REACT_APP_MAIN_URL !== 'https://tms.pspservicesco.com'}>
            <Development />
          </PrivateRoute>
        }
      />
      {/* <Route path="/logs" element={<Logs />} /> */}
      <Route
        path="/psp/:view"
        element={
          <PrivateRoute redirectTo="/" isLoggedIn={isLoggedIn}>
            <Pages />
          </PrivateRoute>
        }
      />
      <Route
        path="/sign-up"
        element={
          <PublicRoute isLoggedIn={isLoggedIn} redirectTo={redirectTo}>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="*"
        element={
          <PublicRoute isLoggedIn={isLoggedIn} redirectTo={redirectTo}>
            <Login />
          </PublicRoute>
        }
      />
    </Routes>
  );
}
export default Router;
