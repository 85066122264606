import styled, { css, keyframes } from 'styled-components/macro';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { menuSelector, menuItemSelector } from '@szhsin/react-menu/style-utils';
import { darken, cssVar } from 'polished';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;

const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

export const StyledMenu = styled(Menu)`
  ${menuSelector.name} {
    box-sizing: border-box;
    list-style: none;
    user-select: none;
    margin: 0px;
    padding: 5px 0px;
    border: none;
    box-shadow: 3px 18px 44px rgba(176, 183, 195, 0.28);
    min-width: 10rem;
    z-index: 999;
    border-radius: 0.4rem;
    background: var(--white);
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuItemSelector.name} {
    display: flex;
    margin: 5px 0px;
    padding: 5px 10px;
    align-items: space-between;
    color: var(--primary-text-color);
  }

  ${menuItemSelector.name}:hover {
    background: var(--primary);
    color: var(--white);
  }
`;

export const StyledMenuButton = styled(MenuButton)`
  font-size: 20px;

  ${({ button }) =>
    button &&
    css`
      color: var(--text-color-gray);
      background: var(--secondary-btn-background);
      width: fit-content;
      height: 40px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      border-radius: 6px;
      .material-icons-outlined {
        font-size: 15px;
        line-height: 1;
        margin: 0 3px;
      }
      &:hover {
        background: ${darken(0.1, cssVar('--light'))};
      }
    `}
`;

export const StyledMenuItem = styled(MenuItem)`
  ${({ active }) =>
    active &&
    css`
      background: var(--primary);
      color: #fff !important;
    `}

  [class^='material-icons-'],
  [class*=' material-icons-'] {
    margin-right: 0.5rem;
  }

  &.delete-btn {
    color: var(--danger);
  }
  &.disble-btn {
    color: var(--light-gray);
  }
  &.enable-btn {
    color: var(--light-blue);
  }

  &.detail-btn {
    color: var(--primary);
  }
`;
