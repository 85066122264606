import React, { useState } from 'react';
import Button from 'components/atoms/Button';
import ModalContainer from 'components/molecules/ModalContainer';
import AllInOneSearch from 'components/organisms/AllInOneSearch';
import Field from 'components/molecules/Field';

function TopBarSearch() {
  const [searchText, setSearchText] = useState('');

  return (
    <>
      <Field
        type="search"
        placeholder="Search"
        noMargin
        value={searchText}
        onChange={({ target: { value } }) => {
          setSearchText(value);
        }}
        sm
        prefix={<i className="material-icons-outlined">search</i>}
        clear={searchText}
      />

      <ModalContainer
        width={1500}
        btnComponent={({ onClick }) => (
          <Button
            type="primary"
            onClick={onClick}
            iconMobile
            size={300}
            disabled={searchText === ''}
            prefix={<i className="material-icons-outlined">search</i>}>
            <span className="text">Search</span>
          </Button>
        )}
        onModalClose={() => {
          setSearchText('');
        }}
        content={({ onClose }) => <AllInOneSearch onClose={onClose} searchText={searchText} />}
      />
    </>
  );
}

export default TopBarSearch;
