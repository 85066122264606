import styled, { css } from 'styled-components/macro';

export const StyledTabList = styled.div`
  ${
    '' /* display: flex;
  white-space: nowrap; */
  }
  margin: 0 0 3px;
  position: relative;
  z-index: 1;

  button {
    margin-bottom: 5px;
  }
`;

export const TabBtn = styled.div`
  padding: 0 0;
  flex-shrink: 0;
  &:first-child {
    padding-left: 0;
  }
`;

export const StyledTab = styled.button`
  font-size: var(--font-size-sm);
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--text-color-gray);
  position: relative;
  padding: 10px 0px;
  border-radius: 0px;
  border-bottom: 1px solid transparent;
  width: 100%;
  text-align: left;

  &:after {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: ease-in-out 0.5s;
    content: '';
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%);
    height: 1px;
    width: 0;
    background: var(--secondary-text-color);
  }

  ${
    '' /* &:hover {
    background: var(--primary);
    color: var(--white);

    &:after {
      visibility: visible;
      opacity: 1;
      width: 100%;
    }
  } */
  }
  &:hover {
    color: var(--black);
    border-bottom: 1px solid black;

    &:after {
      visibility: visible;
      opacity: 1;
      width: 100%;
    }
  }
  ${({ active }) =>
    active &&
    css`
      color: var(--black);
      border-bottom: 1px solid black;

      &:after {
        visibility: visible;
        opacity: 1;
        width: 100%;
      }
    `}
`;

export const StyledTabPanels = styled.div`
  /* overflow: hidden; */
  padding: 20px 0 20px;

  @media (min-width: 768px) {
    padding: 30px 0;
  }
`;

export const StyledTabPanel = styled.div`
  position: relative;
  padding-left: 15px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  height: 0;
  ${'' /* transition: all 0.3s ease-in-out; */}

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      height: auto;
    `}
`;

export const Wrap = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 265px;
  height: 450px;
  padding: 0 8px;
  border-right: 1px solid #e6e8ec;
  position: relative;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  }
`;

export const StyledTabs = styled.div`
  display: flex;
  margin: 15px 0 15px 0;

  ${({ verticalTabs }) =>
    verticalTabs &&
    css`
      display: block;

      ${Wrap} {
        /* display: block; */
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 0 15px 0 0;
        overflow-y: hidden;
      }

      ${StyledTabList} {
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 10px;
        width: 100%;
        height: auto;
        padding: 0;
        overflow-y: hidden;
      }

      ${StyledTabPanel} {
        padding-left: 0;
      }
      ${StyledTabPanels} {
        padding: 10px 0;
      }
    `}
`;
