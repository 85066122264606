import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import { useContextHook } from 'use-context-hook';
import Select from 'components/atoms/Select';
import commonService from 'services/commonService';

function TagTemplatesForm({ closeModal, template }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const handleTagSearch = async text => {
    try {
      let options = null;

      const response = await commonService.getTags({
        page: 1,
        pageSize: 1000,
        searchText: text,
      });

      options = response?.data?.items?.map(_ => ({
        value: _?._id,
        label: _?.tag,
      }));

      return options;
    } catch {
      return [];
    }
  };

  const onSubmit = async values => {
    setLoading(true);
    try {
      if (template) {
        const templateData = { tags: values.tags.map(_ => _.value), title: values.title };
        await commonService.updateTagTemplate(template._id, templateData);
      } else {
        const templateData = { tags: values.tags.map(_ => _.value), title: values.title };
        await commonService.createTagTemplate(templateData);
      }

      refetch();
      closeModal();
      setLoading(false);
      Toast({
        type: 'success',
        message: 'Tag Template Created!',
      });
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (template) {
      form.setFieldsValue({
        title: template?.title,
        tags: template?.tags.map(_ => ({ label: _.tag, value: _._id })),
      });
    }
  }, [template]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Grid xs={1} sm={2} css="margin:15px 0px;align-items:center" gap={10}>
        <Form.Item
          sm
          type="text"
          label="Template Title"
          name="title"
          placeholder="Template Title"
          rules={[
            { required: true, message: 'Please Enter Tag Template Title' },
            {
              pattern: /^.{3,}$/,
              message: 'Title must be minimum 3 characters.',
            },
            {
              pattern: /^.{3,40}$/,
              message: 'Title must be maximum 40 characters.',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label="Template Tags"
          name="tags"
          placeholder="Template Tags"
          rules={[{ required: true, message: 'Tag Selection is required' }]}>
          <Select
            sm
            open
            async
            isMulti
            closeMenuOnSelect={false}
            defaultOptions
            filterOption={false}
            loadOptions={handleTagSearch}
          />
        </Form.Item>
      </Grid>
      <Button type="primary" htmlType="submit" loading={loading}>
        Confirm
      </Button>
    </Form>
  );
}

export default TagTemplatesForm;
