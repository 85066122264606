import styled from 'styled-components/macro';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const TableHead = styled.thead`
  background-color: #f5f5f5;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableHeader = styled.th`
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
`;

export const TableCell = styled.td`
  padding: 5px;
  border: 1px solid #ddd;
`;
export const TBody = styled.tbody`
  ${TableRow} {
    &:hover {
      background-color: #f1f1f1;
    }
    font-size: 12px;
    #numberField {
      height: 30px;
    }
  }
`;
