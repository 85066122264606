import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Button from 'components/atoms/Button';
import defaultConfigurations from 'helpers/schema-tms-config.json';
import ModalContainer from 'components/molecules/ModalContainer';
import Grid from 'components/atoms/Grid';
import Toast from 'components/molecules/Toast';
import configurationService from 'services/configurationService';
import { AuthContext } from 'context/authContext';
import { useContextHook } from 'use-context-hook';
import ConfigurationForm from '../ConfigurationForm';

function ConfigurationTemplateForm({ closeModal, template }) {
  const [form] = useForm();
  const [templateConfigurations, setTemplateConfigurations] = useState(
    template ? template?.configuration : defaultConfigurations,
  );
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const onSubmit = async values => {
    setLoading(true);

    try {
      let response;

      if (template) {
        const data = {
          title: values?.title ?? template?.title,
          configuration: templateConfigurations.state ? templateConfigurations.state : templateConfigurations,
        };

        response = await configurationService.editConfigurationTemplate(template._id, data);
      }

      if (!template) {
        const data = {
          ...values,
          config: templateConfigurations.state ? templateConfigurations.state : templateConfigurations,
        };

        response = await configurationService.createConfigurationTemplate(data);
      }

      if (response.success) {
        Toast({
          type: 'success',
          message: response.message,
        });
      }
      refetch();
      closeModal();
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (template) {
      form.setFieldsValue({
        title: template?.title,
      });
    }
  }, [template]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Grid xs={1} sm={2} css="margin:15px 0px;align-items:center" gap={10}>
        <Form.Item
          sm
          type="text"
          label="Template Title"
          name="title"
          placeholder="Configuration Template Title"
          rules={[
            { required: true, message: 'Please Enter Configuration Template Title' },
            {
              pattern: /^.{3,}$/,
              message: 'Title must be minimum 3 characters.',
            },
            {
              pattern: /^.{3,40}$/,
              message: 'Title should be maximum 40 characters',
            },
          ]}>
          <Field />
        </Form.Item>
        <ModalContainer
          width={1800}
          title={`${template ? 'Edit' : 'Create'} Configuration`}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{template ? 'Edit' : 'Create'} Configuration</span>
            </Button>
          )}
          content={({ onClose }) => (
            <ConfigurationForm
              onClose={onClose}
              configuration={templateConfigurations}
              getConfig={config => setTemplateConfigurations(config)}
            />
          )}
        />
      </Grid>
      <Button type="primary" htmlType="submit" loading={loading}>
        Confirm
      </Button>
    </Form>
  );
}

export default ConfigurationTemplateForm;
