import styled from 'styled-components/macro';

// two files input and a save button which uploads then saves the file
export const BinRangesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px 5px;
  overflow: auto;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 4px;
`;
export const BinRangesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
`;

export const BinRangesBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const BinRangesFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
`;

export const BinRangesFooterButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  max-width: 600px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  border: #6e6b6b 1px solid;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`;

export const BinRangesTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-bottom: 20px;
`;

export const BinRangesTableHeader = styled.thead`
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e5e5;
`;

export const BinRangesTableHeaderRow = styled.tr`
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  box-sizing: border-box;
  width: 100%;
`;

export const BinRangesTableHeaderCell = styled.th`
  padding: 0 20px;
  text-align: left;
  width: 100%;
`;

export const BinRangesTableBody = styled.tbody`
  background-color: #fff;
`;

export const BinRangesTableBodyRow = styled.tr`
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
`;

export const BinRangesTableBodyCell = styled.td`
  padding: 0 20px;
  text-align: left;
  width: 100%;
`;
