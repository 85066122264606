import React from 'react';
import styled, { css } from 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Button from 'components/atoms/Button';

const CaptchaContainer = styled.div`
  padding: 10px 0px;
  margin-top: 10px;
`;

const BoldText = styled.span`
  font-weight: bold;

  ${({ noCopy }) =>
    noCopy &&
    css`
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    `}
`;

function Captcha({ type, title, email, serial_number, onDelete, business, store, group }) {
  const [form] = useForm();

  return (
    <CaptchaContainer>
      To {group ? 'Delete' : 'Deactivate'} <BoldText> {type.toUpperCase()} </BoldText> type
      <BoldText noCopy={process.env.NODE_ENV !== 'development'}> {title ?? email ?? serial_number} </BoldText>
      <Form form={form} css="margin-top:20px" onSubmit={onDelete}>
        <Form.Item
          sm
          type="text"
          name="value"
          placeholder="Enter Value"
          rules={[
            { required: true, message: 'Value Is Required' },
            {
              transform: value =>
                title ? value !== title : email ? value !== email : serial_number ? value !== serial_number : true,
              message: 'Entered Values Does Not Matches',
            },
          ]}>
          <Field />
        </Form.Item>

        {(business || store) && (
          <Form.Item
            sm
            type="textarea"
            name="reason"
            rules={[
              { required: true, message: 'Reason Is Required' },
              // { pattern: /^[a-zA-Z\s]+$/, message: 'Only alphabets are allowed' },
              { pattern: /^[a-zA-Z\s]{10,}$/, message: 'Reason must be minimum of 10 characters.' },
              { pattern: /^[a-zA-Z\s]{10,500}$/, message: 'Reason should be maximum of 500 characters.' },
            ]}
            placeholder="Enter Deactivation Reason">
            <Field />
          </Form.Item>
        )}

        <Button type="danger" htmlType="submit" className="delete-btn">
          {group ? 'Delete' : 'Deactivate'}
        </Button>
      </Form>
    </CaptchaContainer>
  );
}

export default Captcha;
