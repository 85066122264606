import React, { useMemo } from 'react';
import Table from 'components/molecules/Table';
import { format } from 'date-fns';
import { getDateObject, mergeNames } from 'helpers/common';
import ManagerActions from '../ManagerActions';
import OwnerActions from '../OwnerActions';
import UsersActions from '../UsersActions';

function Users({ users_data }) {
  const { user_rows } = useMemo(
    () => ({
      user_rows: users_data?.map(_ => [
        format(getDateObject(_.created_at), 'yyyy-MM-dd'),
        mergeNames(_),
        _?.store_id?.title,
        _?.store_id?.status,
        _?.email ?? '------------',
        _?.status,
        _?.clerk_id ?? '------------',
        _?.type.toUpperCase() ?? '------------',
        _?.type === 'owner' ? (
          <OwnerActions _={_} />
        ) : _?.type === 'manager' ? (
          <ManagerActions _={_} />
        ) : (
          <UsersActions _={_} />
        ),
        _?.is_deleted ? 'deleted' : 'false',
      ]),
    }),
    [users_data],
  );

  const columnNames = [`Created at`, `Name`, `Store Title`, `Store Status`, `Email`, `Status`, `ID`, `Type`, ``, `del`];

  return <Table loading={false} rowsData={user_rows} columnNames={columnNames} noPadding />;
}

export default Users;
