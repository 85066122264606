/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import 'styled-components/macro';
import PropTypes from 'prop-types';

import { StyledTabs, Wrap, StyledTabList, TabBtn, StyledTab, StyledTabPanels, StyledTabPanel } from './DataTabs.styles';

const propTypes = {
  data: PropTypes.array,
};

function DataTabs({ data, verticalTabs, uploadBtn }) {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <StyledTabs verticalTabs={verticalTabs}>
      <Wrap uploadBtn>
        <StyledTabList>
          {data.map((tab, index) => (
            <TabBtn
              key={tab.label}
              onClick={() => {
                setActiveTab(index);
              }}>
              <StyledTab active={activeTab === index}>{tab.label}</StyledTab>
            </TabBtn>
          ))}
        </StyledTabList>
        {uploadBtn ?? uploadBtn}
      </Wrap>

      <StyledTabPanels
        css={`
          width: 100%;
        `}>
        {data?.map((tab, index) => (
          <StyledTabPanel key={tab.label} active={activeTab === index}>
            {tab?.content}
          </StyledTabPanel>
        ))}
      </StyledTabPanels>
    </StyledTabs>
  );
}

DataTabs.propTypes = propTypes;

export default DataTabs;
