import React, { useState } from 'react';
import MenuButton, { MenuItem } from 'components/molecules/Menu';
import { AuthContext } from 'context/authContext';
import BusinessDetails from 'components/organisms/BusinessDetails';
import BusinessForm from 'components/organisms/BusinessForm';
import ChangeStatusForm from 'components/organisms/ChangeStatusForm';
import ModalContainer from 'components/molecules/ModalContainer';
import Toast from 'components/molecules/Toast';
import businessService from 'services/businessService';
import Loaders from 'components/atoms/Loaders';
import { useContextHook } from 'use-context-hook';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import CaptchaComponent from '../CaptchaComponent';
import AddBusinessFees from '../AddBusinessFees';

function BusinessActions({ _, setBusinessDetails, list, deleted }) {
  const [loading, setLoading] = useState(false);

  const { hasPermission, refetch } = useContextHook(AuthContext, ['hasPermission', 'refetch']);

  const onDeleteBusiness = async (id, reason) => {
    try {
      setLoading(true);
      const { success, message } = await businessService.deleteBusiness(id, reason);

      if (success) {
        if (list) {
          setBusinessDetails(prev => (prev?._id === id ? null : prev));
        }
        Toast({
          type: 'success',
          message,
        });
        refetch();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };
  const sendMailToMerchantTool = async () => {
    try {
      setLoading(true);
      const { success, message } = await businessService.sendMerchantToolEmail(_._id);

      if (success) {
        Toast({
          type: 'success',
          message,
        });
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  return (
    <MenuButton icon={<span className="material-icons-outlined">more_vert</span>}>
      {hasPermission('business.get-business-details') && (
        <ModalContainer
          lg
          title="Business Details"
          btnComponent={({ onClick }) => (
            <MenuItem
              onClick={onClick}
              icon={<i className="material-icons-outlined">description</i>}
              className="detail-btn">
              Business Details
            </MenuItem>
          )}
          content={({ onClose }) => <BusinessDetails onClose={onClose} businessId={_._id} />}
        />
      )}

      {hasPermission('business.edit-business') && _.status === 'Active' && !deleted && (
        <ModalContainer
          lg
          title="Update Business"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">edit</i>} disabled={deleted}>
              Update Business
            </MenuItem>
          )}
          content={({ onClose }) => <BusinessForm onClose={onClose} business={_} edit />}
        />
      )}

      {hasPermission('business.change-business-status') && !deleted && (
        <ModalContainer
          lg
          title="Change Business Status"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">update</i>} disabled={deleted}>
              Change Business Status
            </MenuItem>
          )}
          content={({ onClose }) => <ChangeStatusForm onClose={onClose} id={_._id} business status={_.status} />}
        />
      )}

      <ConfirmationModal
        btnComponent={({ onClick }) => (
          <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">update</i>} disabled={deleted}>
            Send Merchant Tool Mail
          </MenuItem>
        )}
        confirmationModal="Yes"
        title="Are you sure you want to send Merchant Tool Mail?"
        onOk={sendMailToMerchantTool}
      />

      {hasPermission('business.delete-business') && !deleted && (
        <ModalContainer
          lg
          title="Are you sure you want to deactivate Business?"
          btnComponent={({ onClick }) => (
            <MenuItem
              className="delete-btn"
              icon={<span className="material-icons-outlined">delete_forever</span>}
              onClick={onClick}>
              Deactivate Business
            </MenuItem>
          )}
          content={({ onClose }) => (
            <Loaders loading={loading}>
              <CaptchaComponent
                onClose={onClose}
                onDelete={({ reason }) => onDeleteBusiness(_._id, reason)}
                type="business"
                title={_?.title}
                business
              />
            </Loaders>
          )}
        />
      )}

      <ModalContainer
        title="Add Business Fees"
        lg
        btnComponent={({ onClick }) => (
          <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">add</i>} disabled={deleted}>
            Add Business Fees
          </MenuItem>
        )}
        content={({ onClose }) => <AddBusinessFees onClose={onClose} business={_} />}
      />
    </MenuButton>
  );
}

export default BusinessActions;
