import React from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import businessService from 'services/businessService';
import Loaders from 'components/atoms/Loaders';
import DetailsCard from 'components/molecules/DetailsCard';
import Grid from 'components/atoms/Grid';
import InfoCard from 'components/molecules/InfoCard';
import { format } from 'date-fns';
import { useContextHook } from 'use-context-hook';

function BusinessDetails({ businessId }) {
  const { fetch } = useContextHook(AuthContext, ['fetch']);
  const { business_details_loading, business_details_data } = businessService.GetBusinessDetails(businessId, fetch);

  return (
    <Loaders loading={business_details_loading}>
      <DetailsCard gray>
        <Grid xs={1} sm={3} gap={20}>
          <InfoCard
            title="Created At"
            value={business_details_data && format(new Date(business_details_data?.created_at), 'MMM do yyyy hh:mm a')}
            $unStyled
          />
          <InfoCard
            title="Updated At"
            value={business_details_data && format(new Date(business_details_data?.updated_at), 'MMM do yyyy hh:mm a')}
            $unStyled
          />
          <InfoCard title="Owner First Name" value={business_details_data?.owner?.first_name ?? 'n/a'} $unStyled />
          <InfoCard title="Owner Last Name" value={business_details_data?.owner?.last_name ?? 'n/a'} $unStyled />
          <InfoCard title="Owner Email" value={business_details_data?.owner?.email ?? 'n/a'} $unStyled />
          <InfoCard title="Owner Phone Number" value={business_details_data?.owner?.phone_number ?? 'n/a'} $unStyled />
          <InfoCard title="Title" value={business_details_data?.title ?? 'n/a'} $unStyled />
          <InfoCard title="Status" value={business_details_data?.status ?? 'n/a'} $unStyled />
          <InfoCard title="MID" value={business_details_data?.mid ?? 'n/a'} $unStyled />
          <InfoCard title="Postal Code" value={business_details_data?.address?.postal_code ?? 'n/a'} $unStyled />
          <InfoCard
            title="Street Address"
            value={business_details_data?.address?.formatted_address ?? 'n/a'}
            $unStyled
          />
          <InfoCard title="Latitude" value={business_details_data?.address?.latlng?.lat ?? 'n/a'} $unStyled />
          <InfoCard title="Longitude" value={business_details_data?.address?.latlng?.lng ?? 'n/a'} $unStyled />
          {business_details_data?.is_deleted && (
            <InfoCard
              title="Deactivation Reason"
              value={business_details_data?.deactivation_reason ?? 'n/a'}
              $unStyled
            />
          )}
        </Grid>
      </DetailsCard>
    </Loaders>
  );
}

export default BusinessDetails;
