import React, { useState } from 'react';
import MenuButton, { MenuItem } from 'components/molecules/Menu/index';
import { AuthContext } from 'context/authContext';
import ModalContainer from 'components/molecules/ModalContainer';
import Toast from 'components/molecules/Toast';
import userService from 'services/userService';
import Loaders from 'components/atoms/Loaders';
import { useContextHook } from 'use-context-hook';
import { capitalize } from 'helpers/common';
import TransferUserForm from '../TransferUserForm';
import ChangeStatusForm from '../ChangeStatusForm';
import UserDetails from '../UserDetails';
import CaptchaComponent from '../CaptchaComponent';
import UserCreationForm from '../UserCreationForm';

function UserActions({ _ }) {
  const [loading, setLoading] = useState(false);
  const { refetch, hasPermission } = useContextHook(AuthContext, ['refetch', 'hasPermission']);

  const onDeleteUser = async id => {
    try {
      setLoading(true);
      const { success, message } = await userService.deleteUser(id);

      if (success) {
        Toast({
          type: 'success',
          message,
        });
        refetch();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  return (
    <MenuButton icon={<span className="material-icons-outlined">more_vert</span>}>
      {hasPermission('user.update-user') && _.status === 'Active' && (
        <ModalContainer
          lg
          title={`Update ${capitalize(_?.type)}`}
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<span className="material-icons-outlined">edit</span>}>
              Update {capitalize(_?.type)}
            </MenuItem>
          )}
          content={({ onClose }) => <UserCreationForm onClose={onClose} user={_} edit storeId={_.store_id} />}
        />
      )}

      {hasPermission('user.change-user-status') && (
        <ModalContainer
          lg
          title="Change Status"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">update</i>}>
              Change Status
            </MenuItem>
          )}
          content={({ onClose }) => <ChangeStatusForm onClose={onClose} id={_._id} user status={_.status} />}
        />
      )}

      {hasPermission('user.transfer-user') && _.status === 'Active' && (
        <ModalContainer
          lg
          title={`Transfer ${capitalize(_?.type)}`}
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">reply</i>}>
              Transfer {capitalize(_?.type)}
            </MenuItem>
          )}
          content={({ onClose }) => (
            <TransferUserForm onClose={onClose} id={_._id} storeId={_.store_id} businessId={_.business_id} />
          )}
        />
      )}

      {hasPermission('user.delete-user') && (
        <ModalContainer
          lg
          title={`Are you sure you want to deactivate ${_?.type}?`}
          btnComponent={({ onClick }) => (
            <MenuItem
              className="delete-btn"
              icon={<span className="material-icons-outlined">delete_forever</span>}
              onClick={onClick}>
              Deactivate {capitalize(_?.type)}
            </MenuItem>
          )}
          content={({ onClose }) => (
            <Loaders loading={loading}>
              <CaptchaComponent
                onClose={onClose}
                onDelete={() => onDeleteUser(_._id)}
                type={_?.type}
                email={_?.email}
              />
            </Loaders>
          )}
        />
      )}

      {hasPermission('user.user-details') && (
        <ModalContainer
          width={1500}
          title={`${capitalize(_?.type)} Details`}
          btnComponent={({ onClick }) => (
            <MenuItem
              onClick={onClick}
              icon={<i className="material-icons-outlined">description</i>}
              className="detail-btn">
              View {capitalize(_?.type)} Details
            </MenuItem>
          )}
          content={({ onClose }) => <UserDetails onClose={onClose} id={_?._id} storeId={_.store_id} />}
        />
      )}
    </MenuButton>
  );
}

export default UserActions;
