import MenuButton, { MenuItem } from 'components/molecules/Menu/index';
import React, { useState } from 'react';
import { AuthContext } from 'context/authContext';
import ChangeStatusForm from 'components/organisms/ChangeStatusForm';
import ModalContainer from 'components/molecules/ModalContainer';
import StoreDetails from 'components/organisms/StoreDetails';
import StoreForm from 'components/organisms/StoreForm';
import Toast from 'components/molecules/Toast';
import storeService from 'services/storeService';
import Loaders from 'components/atoms/Loaders';
import { useContextHook } from 'use-context-hook';
import CaptchaComponent from '../CaptchaComponent';
import BinRanges from '../BinRanges';
import BatchReport from '../BatchReport';

function StoreActions({ _, setStoreDetails, list, deleted }) {
  const [loading, setLoading] = useState(false);
  const { refetch, hasPermission } = useContextHook(AuthContext, ['refetch', 'hasPermission']);

  const onDeleteStore = async (id, reason) => {
    try {
      setLoading(true);
      const { success, message } = await storeService.deleteStore(id, reason);

      if (success) {
        if (list) {
          setStoreDetails(prev => (prev?._id === id ? null : prev));
        }
        Toast({
          type: 'success',
          message,
        });
        refetch();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };

  return (
    <MenuButton icon={<span className="material-icons-outlined">more_vert</span>}>
      {hasPermission('store.get-store-details') && (
        <ModalContainer
          lg
          title="Store Details"
          btnComponent={({ onClick }) => (
            <MenuItem
              onClick={onClick}
              icon={<i className="material-icons-outlined">description</i>}
              className="detail-btn">
              Store Details
            </MenuItem>
          )}
          content={({ onClose }) => <StoreDetails onClose={onClose} storeId={_._id} />}
        />
      )}

      {hasPermission('store.edit-store') && _.status === 'Active' && !deleted && (
        <ModalContainer
          lg
          title="Update Store"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">edit</i>} disabled={deleted}>
              Update Store
            </MenuItem>
          )}
          content={({ onClose }) => <StoreForm onClose={onClose} store={_} edit />}
        />
      )}

      {hasPermission('store.change-store-status') && !deleted && (
        <ModalContainer
          lg
          title="Change Store Status"
          btnComponent={({ onClick }) => (
            <MenuItem onClick={onClick} icon={<i className="material-icons-outlined">update</i>} disabled={deleted}>
              Change Store Status
            </MenuItem>
          )}
          content={({ onClose }) => <ChangeStatusForm onClose={onClose} id={_._id} store status={_.status} />}
        />
      )}

      {hasPermission('store.batch-report') && !deleted && (
        <ModalContainer
          lg
          title="Batch Report Settings"
          btnComponent={({ onClick }) => (
            <MenuItem icon={<span className="material-icons-outlined">report</span>} onClick={onClick}>
              Batch Report
            </MenuItem>
          )}
          content={({ onClose }) => <BatchReport onClose={onClose} detail={_} />}
        />
      )}

      {hasPermission('store.update-bins') && !deleted && (
        <ModalContainer
          lg
          btnComponent={({ onClick }) => (
            <MenuItem
              onClick={onClick}
              icon={<i className="material-icons-outlined">description</i>}
              className="detail-btn">
              Bin Ranges
            </MenuItem>
          )}
          content={({ onClose }) => (
            <BinRanges
              onClose={() => {
                refetch();
                onClose();
              }}
              binRanges={_.binRanges}
            />
          )}
        />
      )}

      {hasPermission('store.delete-store') && !deleted && (
        <ModalContainer
          lg
          title="Are you sure you want to deactivate Store?"
          btnComponent={({ onClick }) => (
            <MenuItem
              className="delete-btn"
              icon={<span className="material-icons-outlined">delete_forever</span>}
              onClick={onClick}>
              Deactivate Store
            </MenuItem>
          )}
          content={({ onClose }) => (
            <Loaders loading={loading}>
              <CaptchaComponent
                onClose={onClose}
                onDelete={({ reason }) => onDeleteStore(_._id, reason)}
                type="store"
                title={_?.title}
                store
              />
            </Loaders>
          )}
        />
      )}
    </MenuButton>
  );
}

export default StoreActions;
