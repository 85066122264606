/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import { AuthContext } from 'context/authContext';
import Select from 'components/atoms/Select';
import Button from 'components/atoms/Button';
import Toast from 'components/molecules/Toast';
import deviceService from 'services/deviceService';
import { useContextHook } from 'use-context-hook';
import commonService from 'services/commonService';
import Grid from 'components/atoms/Grid';

function TagStringUpdate({ onClose, device }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);

  const handleTagSearch = async text => {
    try {
      let options = null;

      const response = await commonService.getTags({
        page: 1,
        pageSize: 1000,
        searchText: text,
      });
      if (response.data.items.length > 0) {
        options = response?.data?.items?.map(_ => ({
          value: _?._id,
          label: _?.tag,
        }));

        return options;
      }
      return [];
    } catch {
      return [];
    }
  };

  const onSubmit = async values => {
    try {
      setLoading(true);

      const response = await deviceService.updateTagString(device._id, { tags: values.tags.map(_ => _.value) });

      if (response.success) {
        Toast({
          type: 'success',
          message: response.message,
        });
        refetch();
        onClose();
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error.message ?? 'Something Went Wrong',
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (device.tags) {
      form.setFieldsValue({
        tags: device?.tags.map(_ => ({ label: _.tag, value: _._id })),
      });
    }
  }, [device]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Grid xs={1} sm={1} css="margin:15px 0px;align-items:center" gap={10}>
        <Form.Item
          sm
          type="text"
          label="Template Tags"
          name="tags"
          placeholder="Template Tags"
          rules={[{ required: true, message: 'Tag Selection is required' }]}>
          <Select
            sm
            open
            async
            isMulti
            closeMenuOnSelect={false}
            defaultOptions
            filterOption={false}
            loadOptions={handleTagSearch}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Confirm
        </Button>
      </Grid>
    </Form>
  );
}

export default TagStringUpdate;
