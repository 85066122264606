import { useState, useMemo } from 'react';

import { createContextHook } from 'use-context-hook';

const context = {};

export const SideNavContext = createContextHook(context);

export function SideNavContextProvider(props) {
  const [sideNavToggle, setSideNavToggle] = useState(false);

  const toggleSideNav = () => {
    setSideNavToggle(!sideNavToggle);
  };
  const contextValues = useMemo(
    () => ({
      sideNavState: sideNavToggle,
      toggleSideNav,
    }),
    [sideNavToggle],
  );

  return <SideNavContext.Provider value={contextValues}>{props.children}</SideNavContext.Provider>;
}
