import Heading from 'components/atoms/Heading';
import Logo from 'components/atoms/Logo';

import { TemplateHolder, Content, ContentHolder, LoginHead, SubTitle, FormHolder } from './LoginTemplate.styles';

function LoginTemplate({ children, title, subtitle }) {
  return (
    <TemplateHolder>
      <Content>
        <ContentHolder>
          <LoginHead>
            <Logo />
          </LoginHead>
          <FormHolder>
            <Heading level={1}>{title}</Heading>
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
            {children}
          </FormHolder>
        </ContentHolder>
      </Content>
    </TemplateHolder>
  );
}

export default LoginTemplate;
