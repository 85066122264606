import React, { useState, useMemo } from 'react';
import { format } from 'date-fns';
import { getDateObject } from 'helpers/common';
import Table from 'components/molecules/Table';
import TableLayout from 'components/atoms/TableLayout';
import { AuthContext } from 'context/authContext';
import deviceService from 'services/deviceService';
import { useContextHook } from 'use-context-hook';
import DevicesActions from '../DevicesActions';

function Device({ query: { user_id, storeId, group, groupPage } }) {
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 5,
    startDate: '',
    endDate: '',
    filterStatus: '',
    searchText: '',
    getStoreInfo: 'true',
    user_id,
    storeId,
    group,
    groupPage,
  });

  const { fetch } = useContextHook(AuthContext, ['fetch']);
  const { devices_data, device_loading } = deviceService.GetDevices(searchQuery, fetch);

  const { totalCount, device_rows } = useMemo(
    () => ({
      device_rows: devices_data.device.map(_ => [
        format(getDateObject(_.created_at), 'yyyy-MM-dd'),
        _.title ?? '------------',
        _.status,
        _?.store_id?.title,
        _?.store_id?.status,
        _.device_id ?? '------------',
        _?.mac_address ?? '------------',
        _?.serial_number ?? '------------',
        <DevicesActions _={_} room={`admin_${_.store_id}`} key={_._id} />,
        _?.is_deleted ? 'deleted' : 'false',
      ]),
      totalCount: devices_data.totalItems,
    }),
    [devices_data],
  );
  const columnNames = [
    `Created at`,
    `Title`,
    `Status`,
    `Store Title`,
    `Store Status`,
    `Device id`,
    `Mac Address`,
    `Serial Number`,
    ``,
    `del`,
  ];
  return (
    <TableLayout
      onChangeFilters={filters => {
        setSearchQuery(_ => ({
          ..._,
          ...filters,
        }));
      }}
      currentPage={searchQuery.page}
      totalCount={totalCount}
      pageSize={searchQuery.pageSize}
      noNegativeMargin
      filters={false}>
      <Table loading={device_loading} rowsData={device_rows} columnNames={columnNames} noPadding />
    </TableLayout>
  );
}
export default Device;
